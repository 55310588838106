import React from 'react';

import Emma from 'assets/avatars/emma.jpeg';
import Amelia from 'assets/avatars/amelia.jpeg';
import Zoey from 'assets/avatars/zoey.jpeg';
import FacebookReview from 'subscription/components/facebookreview/Review';
import { useTranslation } from 'react-i18next';

export const FacebookReviews = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('reviewsTitle')}</h3>
      <div>
        <FacebookReview
          text={t('facebookReviewText1')}
          author="Emma Patterson"
          avatar={Emma}
        />
        <FacebookReview
          text={t('facebookReviewText2')}
          author="Amelia Smith"
          avatar={Amelia}
        />
        <FacebookReview
          text={t('facebookReviewText3')}
          author="Zoey Miller"
          avatar={Zoey}
        />
      </div>
    </>
  );
};
