import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookieConsentLogic } from './hooks/useCookieConsent';
import styles from './styles.module.scss';

export const CookieConsent = () => {
  const { t } = useTranslation();
  const { isVisible, onClose } = useCookieConsentLogic();

  return (
    <div
      className={`
        ${styles.cookieConsent} 
        ${!isVisible && styles.hidden}
      `}
    >
      <div className={styles.textWrapper}>
        {t('cookieConsentTitleLine1')}{' '}
        <a href="https://loona.app/cookie-policy" onClick={onClose}>
          <span className={`${styles.bold} ${styles.accent}`}>
            {t('cookieConsentTitleLine2')}
          </span>
        </a>
      </div>
      <button onClick={onClose}>{t('okButton')}</button>
    </div>
  );
};
