import { getGiftCardProducts } from 'bootstrap/net/giftcards';
import { RootState } from 'bootstrap/types';
import { GiftCardProducts } from 'bootstrap/data/giftproduct/GiftCardProducts';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { PurchaseActionTypes } from './types';

export const SET_PRODUCTS = 'SET_PRODUCTS';

export interface SetProducts {
  type: typeof SET_PRODUCTS;
  payload: {
    products: GiftCardProducts;
  };
}

export function setProducts(products: GiftCardProducts): PurchaseActionTypes {
  return {
    type: SET_PRODUCTS,
    payload: {
      products: products,
    },
  };
}

export const loadProducts =
  (
    currency?: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return getGiftCardProducts(currency)
      .then((products: GiftCardProducts) => {
        dispatch(setProducts(products));
        return Promise.resolve();
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
