import React from 'react';
import { SuccessTemplate } from 'onboarding/components/successtemplate/SuccessTemplate';
import { useRedeemDoneLogic } from './RedeemDone.hook';

export const RedeemDone = () => {
  const { giftCode, handleAppLink } = useRedeemDoneLogic();

  return (
    <SuccessTemplate
      title={giftCode ? 'Install Loóna app' : 'Congratulations!'}
      subTitle={"You're now a member of Loóna"}
      description={
        giftCode ? (
          <>
            All {giftCode} codes have been used, <br />
            but worry not, you can still enjoy Loóna for free for 7 days.
          </>
        ) : (
          <>
            Install the Loóna app now and
            <br />
            complete your first escape!
          </>
        )
      }
      button="Continue in the app"
      onButtonClick={handleAppLink}
    />
  );
};
