import { Answers } from 'bootstrap/subscriptioncancel/state';
import { QuestionIds } from './QuestionsIds';

export enum SubscriptionCancelFlow {
  ExtendTrial = 'ExtendTrial',
  Expensive = 'Expensive',
  Other = 'Other',
}

export const getSubscriptionCancelFlow = (
  answers: Answers,
  subscriptionStatus?: string,
): SubscriptionCancelFlow => {
  if (
    answers[QuestionIds.Reason]?.includes('mind') &&
    subscriptionStatus === 'trial'
  ) {
    return SubscriptionCancelFlow.ExtendTrial;
  }
  if (answers[QuestionIds.Reason]?.includes('expensive')) {
    return SubscriptionCancelFlow.Expensive;
  }
  return SubscriptionCancelFlow.Other;
};
