import { getPresaleOfferConfig } from 'bootstrap/data/product/Product.utils';
import { Product } from 'bootstrap/data/product/Product';
import {
  PresaleOfferConfig,
  PresaleProduct,
} from 'subscription/presale/Presale.types';

export const getPopularProductCode = (
  defaultPresaleOfferConfig: PresaleOfferConfig[],
): string | undefined => {
  const presaleOfferConfig = getPresaleOfferConfig(defaultPresaleOfferConfig);

  const popularProductCode = presaleOfferConfig.find(
    ({ isActive }) => isActive,
  )?.productCode;

  return popularProductCode;
};

export const getMaxFeeProductCode = (
  products: Map<string, Product>,
  defaultPresaleOfferConfig: PresaleOfferConfig[],
): string => {
  const presaleProducts = getPresaleOfferProducts(defaultPresaleOfferConfig)
    .map(({ productCode }) => products.get(productCode))
    .filter(Boolean);

  const productWithMaxFee = Array.from(presaleProducts).reduce(
    (prevProduct, currentProduct) => {
      if (prevProduct?.setupFee && currentProduct?.setupFee) {
        return prevProduct.setupFee > currentProduct.setupFee
          ? prevProduct
          : currentProduct;
      }
    },
  );

  return productWithMaxFee?.code || defaultPresaleOfferConfig[0].productCode;
};

export const getMinFeeProductCode = (
  products: Map<string, Product>,
  defaultPresaleOfferConfig: PresaleOfferConfig[],
): string => {
  const presaleProducts = getPresaleOfferProducts(defaultPresaleOfferConfig)
    .map(({ productCode }) => products.get(productCode))
    .filter(Boolean);

  const productWithMaxFee = Array.from(presaleProducts).reduce(
    (prevProduct, currentProduct) => {
      if (prevProduct?.setupFee && currentProduct?.setupFee) {
        return prevProduct.setupFee < currentProduct.setupFee
          ? prevProduct
          : currentProduct;
      }
    },
  );

  return productWithMaxFee?.code || defaultPresaleOfferConfig[0].productCode;
};

export const getPresaleOfferProducts = (
  defaultPresaleOfferConfig: PresaleOfferConfig[],
): PresaleProduct[] => {
  return getPresaleOfferConfig(defaultPresaleOfferConfig).map(
    ({ productCode, label = '' }) => {
      return {
        productCode,
        label,
      };
    },
  );
};
