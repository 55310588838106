import { getErrorMessage } from 'bootstrap/net/commonnet';
import { getPurchaseToken } from 'bootstrap/net/subscription';
import { getGeoInfo } from 'bootstrap/net/user';
import { Thunk } from 'bootstrap/types';
import { UserActionTypes } from './userActions.types';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';

export const SET_RESTRICT_MAILING = 'SET_RESTRICT_MAILING';
export const SET_PLAN_CODE = 'SET_PLAN_CODE';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
export const SET_COUNTRY_CURRENCY = 'SET_COUNTRY_CURRENCY';
export const SET_SUBSCRIPTION_EXPIRED_DATE = 'SET_SUBSCRIPTION_EXPIRED_DATE';
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_SUBSCRIPTION_TOKEN = 'SET_SUBSCRIPTION_TOKEN';

export function setPaymentMethod(
  paymentMethod: PaymentMethods,
): UserActionTypes {
  return {
    type: SET_PAYMENT_METHOD,
    payload: {
      paymentMethod,
    },
  };
}

export function setRestrictMailing(
  isRestrictMailing: boolean,
): UserActionTypes {
  return {
    type: SET_RESTRICT_MAILING,
    payload: {
      isRestrictMailing,
    },
  };
}

export function setPlanCode(planCode: string): UserActionTypes {
  return {
    type: SET_PLAN_CODE,
    payload: {
      planCode: planCode,
    },
  };
}

export function setSubscriptionToken(token: string): UserActionTypes {
  return {
    type: SET_SUBSCRIPTION_TOKEN,
    payload: {
      subscriptionToken: token,
    },
  };
}

export const setSubscriptionExpiredDate = (): Thunk => async (dispatch, _) => {
  return getPurchaseToken().then((purchase) => {
    dispatch({
      type: SET_SUBSCRIPTION_EXPIRED_DATE,
      payload: {
        subscriptonExpiredDate: purchase?.expire_dt,
      },
    });
  });
};

export const setSubscriptionStatus = (): Thunk => async (dispatch, _) => {
  return getPurchaseToken().then((purchase) => {
    dispatch({
      type: SET_SUBSCRIPTION_STATUS,
      payload: {
        subscriptionStatus: purchase?.status,
        subscriptionEventStatus: purchase?.event_status,
      },
    });
  });
};

export const setCountryCode =
  (countryCode?: string): Thunk =>
  async (dispatch, _) => {
    if (countryCode) {
      dispatch({
        type: SET_COUNTRY_CODE,
        payload: {
          countryCode: countryCode,
        },
      });
    } else {
      return getGeoInfo()
        .then(({ country: geoInfoCountry }) => {
          dispatch({
            type: SET_COUNTRY_CODE,
            payload: {
              countryCode: geoInfoCountry,
            },
          });
        })
        .catch((error) => {
          return Promise.reject(getErrorMessage(error));
        });
    }
  };

export const setCountryCurrency =
  (countryCurrency?: string): Thunk =>
  async (dispatch, _) => {
    if (countryCurrency) {
      dispatch({
        type: SET_COUNTRY_CURRENCY,
        payload: {
          currency: countryCurrency,
        },
      });
    } else {
      return getGeoInfo()
        .then(({ currency: geoInfoCurrency }) => {
          dispatch({
            type: SET_COUNTRY_CURRENCY,
            payload: {
              currency: geoInfoCurrency,
            },
          });
        })
        .catch((error) => {
          return Promise.reject(getErrorMessage(error));
        });
    }
  };
