import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  improvementPercent: number;
};

export const TrialOfferInfo = ({ improvementPercent }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        {t('skipTrialInfoTitle', { percent: improvementPercent })}
      </div>
      <div className={styles.infoContainer}>
        <p>{t('skipTrialInfoLine1')}</p>
        <p>{t('skipTrialInfoLine2')}</p>
        <p className={styles.accent}>
          {t('skipTrialInfoLine3', { percent: improvementPercent })}
        </p>
      </div>
    </>
  );
};
