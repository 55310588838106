import { QuestionIds } from '../../data/constants/QuestionIds';
import { Question, QuestionTypes } from '../Question';

export class Info extends Question {
  title?: string;
  description?: string;
  buttonText: string;

  constructor(
    id: QuestionIds,
    title?: string,
    description?: string,
    buttonText = 'Next',
  ) {
    super(QuestionTypes.Info, id, false);
    this.title = title;
    this.description = description;
    this.buttonText = buttonText;
  }
}
