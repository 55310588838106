import { QuestionIds } from '../constants/QuestionIds';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const GadTestQuestionsOrder: QuestionIds[] = [
  QuestionIds.GadQ1,
  QuestionIds.GadQ2,
  QuestionIds.GadQ3,
  QuestionIds.GadQ4,
  QuestionIds.GadQ5,
  QuestionIds.GadQ6,
  QuestionIds.GadQ7,

  QuestionIds.AboutYouProof1,
  QuestionIds.SleepAndActivityD1,
  QuestionIds.AboutYouGender,
  QuestionIds.AboutYouAge,
  QuestionIds.AboutYouRoutine,
  QuestionIds.AboutYouD2,
  QuestionIds.AboutYouKids,
  QuestionIds.AboutYouD3,
  QuestionIds.AboutYouActivity,
  QuestionIds.AboutYouD4,

  QuestionIds.SleepAndActivityProof2,
  QuestionIds.SleepAndActivityCoffee,
  QuestionIds.SleepAndActivityDinner,
  QuestionIds.SleepAndActivityAfterMidnight,
  QuestionIds.SleepAndActivityLessThanEightHours,
  QuestionIds.SleepAndActivityExtraMotivation,
  QuestionIds.SleepAndActivityD2,
  QuestionIds.SleepAndActivityLoader,

  QuestionIds.PersonalizationD1,
  ...PersonalizationSectionQuestionIds,
];
