import { ColorOption } from './ColorOption';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Question, QuestionTypes } from 'onboarding/question/Question';

export class ColorQuestion extends Question {
  title: string;
  description: string;
  options: ColorOption[];

  constructor(
    id: QuestionIds,
    title: string,
    description: string,
    options: ColorOption[],
  ) {
    super(QuestionTypes.Color, id, true);
    this.title = title;
    this.description = description;
    this.options = options;
  }
}
