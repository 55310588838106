import React, { SyntheticEvent } from 'react';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';

type ProductCellProps = {
  product: Product;
  isActive: boolean;
  label: string;
  currencyCode?: string;
  onSelected: (productCode: string) => void;
};

export const ProductCell = ({
  product,
  isActive,
  currencyCode,
  onSelected,
  label,
}: ProductCellProps) => {
  const handleCellClick = (e: SyntheticEvent) => {
    e.preventDefault();
    onSelected(product.code);
  };

  return (
    <div
      key={product.code}
      className={`
        ${styles.priceContainer} 
        ${isActive ? styles.active : ''}
      `}
    >
      <div className={styles.price} onClick={handleCellClick}>
        {product.getFormattedInvoicePrice(currencyCode, 0, 2)}
      </div>
      <span className={styles.label}>{label}</span>
    </div>
  );
};
