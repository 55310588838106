import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveLine, BaseLine, LineState } from './index';
import styles from './styles.module.scss';

type Props = {
  item: BaseLine;
  className: string;
};

export const LineComponent: React.FC<Props> = ({ item, className }: Props) => {
  const { t } = useTranslation();

  switch (item.state) {
    case LineState.Filled:
      return (
        <div
          className={`
            ${styles.line} 
            ${styles.lineActive} 
            ${styles[className]}
          `}
        />
      );
    case LineState.Active:
      return (
        <div className={`${styles.lineContainer} ${styles[className]}`}>
          <div className={styles.stepLabel}>
            {t((item as ActiveLine).label)}
          </div>
          <div className={styles.line}>
            <div
              className={styles.lineProgress}
              style={{ width: `${(item as ActiveLine).progress}%` }}
            />
          </div>
        </div>
      );
    case LineState.Empty:
      return <div className={`${styles.line} ${styles[className]}`} />;
  }
};
