import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { emailRegister } from 'bootstrap/user/actions';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import { useEffect, useState } from 'react';
import { RedeemScreenOpened } from 'redeem/analytics';
import { pushWithSearchQuery, validateEmail } from 'utils';
import {
  EMAIL_VALIDATION_ERROR,
  PASSWORD_VALIDATION_ERROR,
} from 'utils/utils.constants';

export const useRedeemAccountLogic = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  const [emailState, setEmailState] = useState({
    value: '',
    error: '',
  });

  const [passwordState, setPasswordState] = useState({
    value: '',
    error: '',
  });

  const [errorState, setErrorState] = useState({
    error: '',
    isVisible: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemAccount'));
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setErrorState({ ...errorState, isVisible: false });

    if (!isLoading && validateForm()) {
      setIsLoading(true);
      dispatch(emailRegister(emailState.value, passwordState.value, 'redeem'))
        .then((hasSubscription) => {
          setIsLoading(false);
          navigateNext(hasSubscription);
        })
        .catch((message) => {
          setIsLoading(false);
          if (message.email) {
            setEmailState({ ...emailState, error: EMAIL_VALIDATION_ERROR });
          } else if (message.password) {
            setPasswordState({
              ...passwordState,
              error: PASSWORD_VALIDATION_ERROR,
            });
          } else {
            setErrorState({ ...errorState, error: message, isVisible: true });
          }
        });
    }
  };

  const navigateNext = (hasSubscription: boolean) => {
    if (hasSubscription) {
      dispatch(pushWithSearchQuery('/redeem/done'));
    } else {
      dispatch(pushWithSearchQuery('/redeem/code'));
    }
  };

  const validateForm = (): boolean => {
    const isEmailValid = validateEmail(emailState.value);
    const isPasswordValid = passwordState.value !== '';
    if (!isEmailValid) {
      setEmailState({ ...emailState, error: EMAIL_VALIDATION_ERROR });
      return false;
    } else if (!isPasswordValid) {
      setPasswordState({
        ...passwordState,
        error: PASSWORD_VALIDATION_ERROR,
      });
      return false;
    } else return true;
  };

  const onSocialError = (type: 'facebook', error: string | any) => {
    setErrorState({
      ...errorState,
      error:
        typeof error.message?.email === 'string'
          ? error.message.email
          : error.message,
      isVisible: true,
    });
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = validateEmail(event.target.value);
    setEmailState({
      error: isValid ? '' : EMAIL_VALIDATION_ERROR,
      value: event.target.value,
    });
    setErrorState({
      ...errorState,
      isVisible: false,
    });
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({
      ...passwordState,
      error:
        event.target.value.length < 8
          ? 'Password should be at least 8 characters'
          : '',
      value: event.target.value,
    });
    setErrorState({
      ...errorState,
      isVisible: false,
    });
  };

  return {
    isLoading,
    isLargeScreen,
    errorState,
    passwordState,
    emailState,
    setErrorState,
    setEmailState,
    navigateNext,
    handleSubmit,
    onSocialError,
    onEmailChange,
    onPasswordChange,
  };
};
