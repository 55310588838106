import React from 'react';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { FogBackground } from 'giftcards/components/fogbackground/FogBacakground';
import { PaymentsPicker } from 'subscription/recurly/components/paymentpicker/PaymentsPicker';
import { GiftCardTypes } from '../../../bootstrap/data/giftproduct/GiftCard.types';
import { usePurchaseLogic } from './Purchase.hook';
import { Logo } from 'components/logo/Logo';
import { RECURLY_KEY } from 'utils/utils.constants';
import { DefaultCardForm } from 'subscription/recurly/components/cardform/defaultcardform/DefaultCardForm';
import PoweredByRecurly from 'assets/ic_powered_by_recurly.png';
import Divider from 'components/divider/Divider';
import { Information } from 'components/information/Information';
import { SecureServer } from 'components/secureserver/SecureServer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styles from './styles.module.scss';

export const Purchase = () => {
  const { giftCardType, product, isLargeScreen, onSuccess, onError } =
    usePurchaseLogic();

  if (!product) return null;

  const Wrapper = isLargeScreen ? LargeScreenWrapper : SmallScreenWrapper;

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
      container={{
        parameters: {
          theme: 'dark',
        },
      }}
    >
      <Wrapper giftCardType={giftCardType}>
        <div className={styles.paymentFormContainer}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <RecurlyProvider publicKey={RECURLY_KEY}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Elements>
              <Logo className={styles.logo} />
              <div className={styles.title}>Choose your payment method</div>
              <PaymentsPicker
                product={product as any}
                darkTheme={true}
                onSuccess={onSuccess}
                onError={onError}
              >
                <div className={styles.header}>
                  Payment Information
                  <SecureServer />
                </div>
                <Divider isDividerWithNoMargin darkTheme />
                <img
                  className={styles.recurlyPowered}
                  src={PoweredByRecurly}
                  alt=""
                />
                <DefaultCardForm
                  product={product as any}
                  darkTheme={true}
                  onSuccess={onSuccess}
                  onError={onError}
                />
                <Divider darkTheme />
                <Information />
              </PaymentsPicker>
            </Elements>
          </RecurlyProvider>
        </div>
      </Wrapper>
    </GoogleReCaptchaProvider>
  );
};

type WrapperProps = {
  children: JSX.Element | JSX.Element[];
  giftCardType: GiftCardTypes;
};

const LargeScreenWrapper = ({ children, giftCardType }: WrapperProps) => (
  <FogBackground giftCardType={giftCardType} className={styles.container}>
    {children}
  </FogBackground>
);

const SmallScreenWrapper = ({ children }: WrapperProps) => (
  <div className={styles.paymentContainer}>{children}</div>
);
