import React from 'react';
import { appAnalytics } from 'analytics';
import { InstallQR } from 'analytics/AllEvents';
import GooglePlay from 'assets/google_play.svg';
import { useAppSelector } from 'bootstrap/hooks';
import { getOneLink, isDevEnv } from 'utils';

export const GooglePlayButton = () => {
  const { authToken } = useAppSelector((state) => state.user);

  const handleClick = () => {
    window.location.href = getOneLink({
      mediaSource: isDevEnv() ? 'web_payments_dev' : 'web_payments',
      webDp:
        'https://play.google.com/store/apps/details?id=co.loona&hl=en&gl=US',
      campaign: 'onboarding-android',
      retargeting: 'true',
      deepLink: authToken,
      iosUrl:
        'https://play.google.com/store/apps/details?id=co.loona&hl=en&gl=US',
    });
    appAnalytics.trackEvent(new InstallQR('android', authToken || false));
  };
  return (
    <a onClick={handleClick}>
      <img src={GooglePlay} alt="Download Loóna App" />
    </a>
  );
};
