import React, { FC, useRef, useEffect } from 'react';
import styles from './styles.module.scss';

type Props = {
  id: string;
  title: string;
  icon: string | JSX.Element | JSX.Element[];
  isActive: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick: (id: string) => void;
};

export const Item: FC<Props> = ({
  id,
  title,
  icon,
  isActive,
  children,
  onClick,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (!contentRef.current) return;
      contentRef.current.style.maxHeight = isActive
        ? `${contentRef.current.scrollHeight}px`
        : '0px';
    };

    // Delay the height calculation slightly more
    const timeoutId = setTimeout(() => {
      updateHeight();
      setTimeout(updateHeight, 300); // Re-check after a slight delay
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [isActive]);

  const handleClick = () => {
    onClick(id);
  };

  return (
    <div className={styles.item} onClick={handleClick}>
      <div className={styles.titleContent}>
        <div className={styles.titleWrapper}>
          <div
            className={`
              ${styles.radio}
              ${isActive ? styles.checkedRadio : styles.unCheckedRadio}
            `}
          />
          <div className={styles.title}>{title}</div>
        </div>
        {typeof icon === 'string' ? (
          <div className={styles.icon}>
            <img src={icon} alt={`${title} icon`} />
          </div>
        ) : (
          icon
        )}
      </div>
      <div ref={contentRef} className={styles.content}>
        {children}
      </div>
    </div>
  );
};
