import React from 'react';
import { isAdhdTest, isDefaultOnboarding, isGadTest } from 'navigation';
import DefaultWelcome from './defaultwelcome/Welcome';
import GadWelcome from './gadwelcome/GadWelcome';
import RmeqWelcome from './rmeqwelcome/WelcomeRmeq';
import { AdhdWelcome } from './adhdwelcome/AdhdWelcome';

const Welcome = () => {
  if (isGadTest()) {
    return <GadWelcome />;
  }
  if (isDefaultOnboarding()) {
    return <DefaultWelcome />;
  }
  if (isAdhdTest()) {
    return <AdhdWelcome />;
  }
  return <RmeqWelcome />;
};

export default Welcome;
