import { appAnalytics } from 'analytics';
import { Install } from 'analytics/AllEvents';
import { useAppSelector } from 'bootstrap/hooks';
import { ViewPIScreen } from 'invitepurchase/analytics';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getOneLink } from 'utils';

export const useSuccessLogic = () => {
  const { oldUser, authToken } = useAppSelector((state) => state.user);
  const { inviteCodeId } = useParams<{ inviteCodeId: string }>();

  useEffect(() => {
    appAnalytics.trackEvent(new ViewPIScreen('piCongratsScreen'));
  }, []);

  const handleButtonClick = () => {
    appAnalytics.trackEvent(new Install());
    window.location.href = getOneLink({
      webDp: `${process.env.REACT_APP_FRONTEND_URL}/install`,
      mediaSource: 'premium_invites',
      deepLink: authToken,
      campaignId: inviteCodeId,
    });
  };

  return {
    oldUser,
    handleButtonClick,
  };
};
