import React from 'react';
import Delete from 'assets/ic_delete.png';
import Alert from 'components/error/Error';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { PasswordInput } from 'components/passwordinput/PasswordInput';
import { FacebookLoginButton } from 'components/facebookbutton/FacebookLoginButton';
import { GoogleSignInButton } from 'signin/components/googlebutton/GoogleSignInButton';
import { ReactComponent as Logo } from 'assets/logo_loona.svg';
import Disclaimer from 'components/disclaimer/Disclaimer';
import { useSignInLogic } from './SignIn.hook';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const SignIn = () => {
  const { t } = useTranslation();

  const {
    emailState,
    passwordState,
    errorState,
    socialsShown,
    isLoading,
    setEmailState,
    setErrorState,
    handleSubmit,
    onSocialError,
    onEmailChange,
    onPasswordChange,
    handleForgotPassword,
    socialsShownHandler,
    navigateNext,
  } = useSignInLogic();

  const socialLoginButtons = (
    <div
      className={`${styles.socialLogin} ${socialsShown ? '' : styles.hidden}`}
    >
      <GoogleSignInButton onSuccess={navigateNext} onError={onSocialError} />
      <FacebookLoginButton onSuccess={navigateNext} onError={onSocialError} />
    </div>
  );

  return (
    <>
      <Alert
        visible={errorState.isVisible}
        error={errorState.error}
        onClose={() => setErrorState({ ...errorState, isVisible: false })}
      />
      <div className={`${styles.container} ${styles.whiteTheme}`}>
        <Logo className={styles.logo} />
        <div className={styles.title}>{t('quickPurchaseSignInTitle')}</div>
        <div className={styles.description}>
          {t('quickPurchaseSignInDescription')}
        </div>
        {socialsShown && socialLoginButtons}
        <form className={styles.form} onSubmit={handleSubmit} noValidate>
          <div className={styles.inputContainer}>
            <input
              type="email"
              className={
                emailState.error !== '' && emailState.value !== ''
                  ? styles.invalid
                  : ''
              }
              value={emailState.value}
              placeholder={t('emailPlaceholder')}
              onFocus={() => {
                setEmailState({ ...emailState, isFocused: true });
              }}
              onBlur={() => {
                setEmailState({ ...emailState, isFocused: false });
              }}
              onChange={onEmailChange}
            />
            {emailState.isFocused && (
              <img
                src={Delete}
                alt="x"
                className={styles.delete}
                onClick={() => setEmailState({ ...emailState, value: '' })}
              />
            )}
          </div>
          <div className={styles.error}>{emailState.error}</div>

          <PasswordInput
            value={passwordState.value}
            isValid={!passwordState.error}
            dark={true}
            onChange={onPasswordChange}
            className={styles.password}
            onFocusChange={socialsShownHandler}
          />
          <div className={styles.error}>{passwordState.error}</div>

          <Disclaimer />

          <div style={{ flex: 2, display: socialsShown ? 'block' : 'none' }} />

          <LoadingButton
            disabled={
              emailState.value === '' ||
              emailState.error !== '' ||
              passwordState.value === '' ||
              passwordState.error !== ''
            }
            isLoading={isLoading}
            label={t('getPlanButton')}
            onClick={handleSubmit}
          />

          <button
            className={styles.forgotPassword}
            onClick={handleForgotPassword}
          >
            {t('forgotPassword')}
          </button>
        </form>
      </div>
    </>
  );
};

export default SignIn;
