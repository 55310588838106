import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { appAnalytics } from 'analytics';
import { OnboardingScreenOpened } from 'analytics/AllEvents';
import { pushWithSearchQuery } from 'utils';
import { QUESTIONS_META } from './data/meta';
import { Question } from './question/Question';
import { getQuestionComponent } from './question/helper';
import { StepperWrapper } from './StepperWrapper';
import { isFlexibleOmitViewEventsFlow } from 'navigation';
import { QuestionIds } from './data/constants/QuestionIds';
import { QuestionsOrder } from './data/QuestionsOrder';
import styles from './styles.module.scss';

type OnboardingParams = {
  questionId: QuestionIds;
};

export const Onboarding = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { questionId } = useParams<OnboardingParams>();
  const [className, setClassName] = React.useState('');

  useEffect(() => {
    setClassName('');
  }, [location, questionId]);

  useEffect(() => {
    if (QuestionIds[questionId] && !isFlexibleOmitViewEventsFlow()) {
      appAnalytics.trackEvent(
        new OnboardingScreenOpened(QUESTIONS_META[questionId].id),
      );
    }
  }, [questionId]);

  if (!QuestionIds[questionId]) {
    dispatch(pushWithSearchQuery(`/onboarding/${QuestionsOrder[0]}`));
    return null;
  }

  const question: Question = QUESTIONS_META[questionId];
  const QuestionComponent = getQuestionComponent(question);
  return (
    <div className={`${styles.container} ${styles[className]}`}>
      {question.hasStepper && (
        <StepperWrapper questionId={questionId} className={className} />
      )}
      {QuestionComponent && <QuestionComponent question={question} />}
    </div>
  );
};
