import React from 'react';
import RecurlySecured from 'assets/ic_powered_by_recurly.svg';
import SslEncrypted from 'assets/ic_ssl_encrypted.png';
import styles from './styles.module.scss';

export const RecurlyLogo = () => {
  return (
    <div className={styles.recurlySecured}>
      <img className={styles.sslEncrypted} src={SslEncrypted} alt="" />
      <img className={styles.poweredByRecurly} src={RecurlySecured} alt="" />
    </div>
  );
};
