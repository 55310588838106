import { GiftCardTypes } from '../bootstrap/data/giftproduct/GiftCard.types';

export const getGiftCardLabel = (giftCardType: GiftCardTypes): string => {
  let giftCardLabel;

  switch (giftCardType) {
    case GiftCardTypes.Yearly: {
      giftCardLabel = 'Yearly';
      break;
    }
    case GiftCardTypes.Lifetime: {
      giftCardLabel = 'Lifetime';
      break;
    }
  }
  return giftCardLabel;
};

export const isGiftFlow = (): boolean => {
  return window.location.pathname.split('/').includes('gift');
};
