import Spacer from 'components/spacer/Spacer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PersonalizationD1Image from 'assets/onboarding/personalization_d1.png';
import { ReactComponent as Profile } from 'assets/onboarding/profile.svg';
import { ReactComponent as Star } from 'assets/onboarding/rating_star.svg';
import { Label } from './components/Label';
import { usePersonalizationLogic } from './Personalization.hook';
import { CustomQuestion } from '../Question';
import { QuestionComponentProps } from '../QuestionComponent.types';
import styles from './styles.module.scss';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';

type Props = QuestionComponentProps<CustomQuestion>;

export const Personalization: React.FC<Props> = ({ question }: Props) => {
  const { t } = useTranslation();
  const { nextButtonHandler } = usePersonalizationLogic(question);

  return (
    <div className={`${styles.container} maxWidth`}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={PersonalizationD1Image} alt="" />
        <div className={styles.labelsContainer}>
          <Label
            icon={<Star />}
            title={t('PersonalizationD1Label1Title', { value: '4.5/5' })}
            subtitle={t('PersonalizationD1Label1Subtitle', { value: '13,638' })}
          />
          <Label
            icon={<Profile />}
            title={t('PersonalizationD1Label2Title', { value: '3M' })}
            subtitle={t('PersonalizationD1Label2Subtitle')}
          />
        </div>
      </div>
      <div className={styles.content}>
        <Spacer />
        <div className={styles.title}>{t('PersonalizationD1V2Title')}</div>
        <div className={styles.description}>
          {t('PersonalizationD1V2DescriptionLine1')}
          <br />
          <br />
          {t('PersonalizationD1V2DescriptionLine2')}
        </div>
        <StickyContainer>
          <button className={styles.button} onClick={nextButtonHandler}>
            {t('Continue')}
          </button>
        </StickyContainer>
      </div>
    </div>
  );
};
