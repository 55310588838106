import React from 'react';
import { Prompt } from 'react-router-dom';
import { useRouterPromptLogic } from './hooks/useRouterPromptLogic';
import PaymentPromptModal from './components/paymentprompt/PaymentPrompt';
import DefaultPromptModal from './components/defaultprompt/DefaultPrompt';

const RouterPrompt = () => {
  const {
    isModalVisible,
    isPaymentPrompt,
    handleBlockNavigation,
    handleConfirm,
    closeModal,
  } = useRouterPromptLogic();

  return (
    <>
      <Prompt message={handleBlockNavigation} />
      {isPaymentPrompt ? (
        <PaymentPromptModal
          isModalVisible={isModalVisible}
          handleConfirm={handleConfirm}
        />
      ) : (
        <DefaultPromptModal
          isModalVisible={isModalVisible}
          handleConfirm={handleConfirm}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
export default RouterPrompt;
