import React from 'react';
import BearImage from 'assets/welcome/welcome_rmeq.jpg';
import { usePreloadImages } from 'hooks/usePreloadImages';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import Spacer from 'components/spacer/Spacer';
import { useFinishLogic } from './Finish.hook';
import styles from './styles.module.scss';

const Finish = () => {
  usePreloadImages([BearImage]);
  const { title, description, onGotItHandler } = useFinishLogic();

  return (
    <div className={styles.wrapper}>
      <img
        loading="lazy"
        src={BearImage}
        srcSet={BearImage}
        className={styles.image}
        alt=""
      />

      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <Spacer />
        <StickyContainer>
          <button onClick={onGotItHandler}>Got it</button>
        </StickyContainer>
        <div className={styles.note}>
          Any questions? <a href="mailto:hello@loona.app">Contact support</a>
        </div>
      </div>
    </div>
  );
};

export default Finish;
