import { appAnalytics } from 'analytics';
import { ScreenOpened } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { buyWorkbookPlan } from 'bootstrap/user/actions/subscripionOperations';

import { useEffect, useState } from 'react';
import { pushWithSearchQuery } from 'utils';

export const useWorkbookLogic = () => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    appAnalytics.trackEvent(new ScreenOpened('upsell'));
  }, []);

  const goToNextPage = () => {
    dispatch(pushWithSearchQuery('/done'));
  };

  const onWorkbookBuyClickHandler = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        buyWorkbookPlan({
          planCode: 'co-loona-web-nc-workbook',
          currency,
        }),
      );
      goToNextPage();
      setIsLoading(false);
    } catch (error) {
      setError(getErrorMessage(error));
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    setError,
    onSkipClickHandler: goToNextPage,
    onWorkbookBuyClickHandler,
  };
};
