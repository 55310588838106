import UAParser from 'ua-parser-js';

import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Event, OptionalParams } from './Event';
import { Events } from './Events';
import { getQueryVariable } from 'utils';
import { getCurrentLanguage } from 'utils/utils';

export class ScreenOpened extends Event {
  constructor(screenName: string, params?: any) {
    super(Events.ScreenOpened, {
      screenName,
      page_language: getCurrentLanguage(),
      ...params,
    });
  }
}
export class GoBack extends Event {
  constructor(screenName: string) {
    super(Events.GoBack, { screenName });
  }
}

export class ContinueUnfoldPayment extends Event {
  constructor(source: string) {
    super(Events.ContinueUnfoldPaymentMethod, { source });
  }
}

export class ContinueWelcome extends Event {
  constructor(reply: 'reduceStress' | 'sleepBetter' | 'rmeq' | 'gad' | 'adhd') {
    super(Events.ContinueWelcome, { reply });
  }
}

export class ContinueBoardingSkipTrial extends Event {
  constructor(reply: 'skipTrial' | 'continueWithTrial') {
    super(Events.ContinueBoardingSkipTrial, { reply });
  }
}

export class SignUp extends Event {
  constructor(
    type: 'email' | 'google' | 'facebook',
    isRestrictMarketing?: boolean,
    isNewUser?: boolean,
  ) {
    const platform = new UAParser().getResult().os.name;
    const utm_term = getQueryVariable('utm_term');

    super(Events.SignUp, {
      accountType: type,
      restrictMarketingComms: isRestrictMarketing || false,
      isNewUser,
      platform,
      utm_term,
    });
  }
}

export class SignUpError extends Event {
  constructor(type: 'email' | 'google' | 'facebook', error: string) {
    super(Events.SignUpError, { accountType: type, errorType: error });
  }
}

export class RecaptchaError extends Event {
  constructor(error: string) {
    super(Events.RecaptchaError, { errorType: error });
  }
}

export class SwitchPlan extends Event {
  constructor(isExtendedTrial: boolean, productId: string) {
    super(Events.SwitchPlan, {
      productId,
      plan: isExtendedTrial ? 'extendedFreeTrial' : 'freeTrial',
    });
  }
}

export class SwitchAddon extends Event {
  constructor(isActiveState: boolean, price?: number) {
    super(Events.SwitchAddon, {
      addon: 'workbook',
      price,
      state: isActiveState ? 'enable' : 'disable',
    });
  }
}

export class SwitchPresalePlanCode extends Event {
  constructor(planCode: string, previousPlanCode: string) {
    super(Events.SwitchBoardingPrice, {
      planCode,
      previousPlanCode,
    });
  }
}

export class ViewSwitchPlanPrompt extends Event {
  constructor() {
    super(Events.ViewSwitchPlanPrompt);
  }
}

export class UnfoldPayment extends Event {
  constructor(isExtendedTrial: boolean) {
    super(Events.UnfoldPayment, {
      plan: isExtendedTrial ? 'extendedFreeTrial' : 'freeTrial',
    });
  }
}

export class ViewWhyNowPrompt extends Event {
  constructor() {
    super(Events.ViewWhyNowPrompt);
  }
}

export class Subscribe extends Event {
  constructor() {
    super(Events.Subscribe);
  }
}

export class SubscriptionSuccess extends Event {
  constructor(
    startPrice = 0,
    currency: string,
    planCode: string,
    optionalParams: OptionalParams,
  ) {
    super(
      Events.SubscriptionSuccess,
      {
        value: startPrice,
        currency: currency,
        planCode: planCode,
      },
      optionalParams,
    );
  }
}

export class SubscriptionError extends Event {
  constructor(error: string) {
    super(Events.SubscriptionError, { error });
  }
}

export class ViewCongrats extends Event {
  constructor(isNewSubscriber: boolean) {
    super(Events.ViewCongrats, {
      type: isNewSubscriber
        ? 'congratsNewSubscriber'
        : 'congratsExistedSubscriber',
    });
  }
}

export class Install extends Event {
  constructor() {
    super(Events.Install);
  }
}

export class InstallQR extends Event {
  constructor(platform: string, token: string | boolean) {
    super(Events.InstallQR, { platform, token });
  }
}

export class ExperimentStarted extends Event {
  constructor(name: string, value: number) {
    super(Events.ExperimentStarted, {
      experiment: name,
      variant: value,
    });
  }
}

export class PageView extends Event {
  constructor() {
    super(Events.PageView);
  }
}

export class ViewInstallQRScreen extends Event {
  constructor() {
    super(Events.ViewInstallQRScreen);
  }
}

export class OnboardingScreenOpened extends Event {
  constructor(questionId: QuestionIds) {
    super(Events.ScreenOpened, {
      screenName: `onboarding${questionId}`,
      page_language: getCurrentLanguage(),
    });
  }
}

export class SaveAnswer extends Event {
  constructor(questionId: QuestionIds, reply?: string | string[]) {
    // @ts-ignore
    const event: Events = Events[`ContinueBoarding${questionId}`];
    super(event, reply && { reply: reply });
  }
}

export class BlitzAlertViewed extends Event {
  constructor(questionId: QuestionIds) {
    super(Events.BlitzAlertViewed, { screenName: `onboarding${questionId}` });
  }
}

export class ViewPopup extends Event {
  constructor(screenType: string) {
    super(Events.ViewPopup, { screenType });
  }
}

export class ClickPreventGoBack extends Event {
  constructor(reply: 'yes' | 'no') {
    super(Events.ClickPreventGoBack, { reply });
  }
}

export class ViewSkipTrialPopup extends Event {
  constructor() {
    super(Events.ViewSkipTrialPopup);
  }
}
export class ProductSelected extends Event {
  constructor(
    productCode: string,
    isPreselected: boolean,
    preselectedPlanCode: string,
  ) {
    super(Events.ProductSelected, {
      reply: productCode,
      isPreselected,
      preselectedPlanCode,
    });
  }
}

export class PayPalClicked extends Event {
  constructor() {
    super(Events.PayPalClicked);
  }
}

export class ApplePayClicked extends Event {
  constructor() {
    super(Events.ApplePayClicked);
  }
}

export class CardFormInputStarted extends Event {
  constructor() {
    super(Events.CardFormInputStarted);
  }
}

export class UnfoldFaq extends Event {
  constructor(element: string) {
    super(Events.UnfoldFaq, { element });
  }
}
