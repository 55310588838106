import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import React from 'react';
import PoweredByRecurly from 'assets/ic_powered_by_recurly.png';
import { LoadingScreen } from 'components/loadingscreen/LoadingScreen';
import { SecureServer } from 'components/secureserver/SecureServer';
import { Error } from 'subscription/components/error/Error';
import { DefaultCardForm } from 'subscription/recurly/components/cardform/defaultcardform/DefaultCardForm';
import { PaymentsPicker } from 'subscription/recurly/components/paymentpicker/PaymentsPicker';
import Divider from 'components/divider/Divider';
import { usePaymentLogic } from './Payment.hook';
import { RECURLY_KEY } from 'utils/utils.constants';
import styles from './styles.module.scss';

export const Payment = () => {
  const {
    error,
    product,
    handleCardError,
    handlePayPalError,
    handleCardSuccess,
    handlePayPalSuccess,
    handleRetry,
  } = usePaymentLogic();

  return error ? (
    <Error onRetry={handleRetry} />
  ) : (
    <div className={styles.container}>
      <LoadingScreen
        darkTheme={true}
        isVisible={!product}
        title="Designing a escape..."
        description={
          <>
            Please do not click or refresh the page.
            <br />
            It may take a few seconds...
          </>
        }
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RecurlyProvider publicKey={RECURLY_KEY}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Elements>
          {product && (
            <>
              <div className={styles.title}>Free 1 Month Membership</div>
              <div className={styles.description}>
                You will not be charged now. No commitment. Cancel anytime
              </div>
              <PaymentsPicker
                product={product}
                darkTheme={true}
                onSuccess={handlePayPalSuccess}
                onError={handlePayPalError}
              >
                <div className={styles.header}>
                  Payment information
                  <SecureServer />
                </div>
                <Divider />
                <img
                  className={styles.recurlyPowered}
                  src={PoweredByRecurly}
                  alt=""
                />
              </PaymentsPicker>
              <DefaultCardForm
                product={product}
                darkTheme={true}
                onSuccess={handleCardSuccess}
                onError={handleCardError}
              />
              <div className={styles.note}>
                <div className={styles.note}>
                  After {product.trialLength} {product.trialUnit} your free
                  membership will automatically upgrade to regular{' '}
                  {product.getRenewPeriodName()} membership at{' '}
                  {product.getFormattedPrice()}{' '}
                  {product.getRenewPeriodAdverbName()} unless cancelled
                </div>
              </div>
            </>
          )}
        </Elements>
      </RecurlyProvider>
    </div>
  );
};
