import {
  OptionalQuestion,
  OptionQuestion,
} from 'cancelsubscription/components/question/options/OptionQuestion';
import { TextOption } from 'cancelsubscription/components/question/options/TextOption';
import {
  CustomQuestion,
  Question,
} from 'cancelsubscription/components/question/Question';
import { QuestionIds } from './constants/QuestionsIds';

export const QUESTIONS_META: Record<QuestionIds, Question> = {
  [QuestionIds.Welcome]: new CustomQuestion(QuestionIds.Welcome),
  [QuestionIds.Reason]: new OptionQuestion(
    QuestionIds.Reason,
    'Why did you decide to cancel your subscription?',
    [
      new TextOption('expensive', "I like the program, but it's too expensive"),
      new TextOption('mind', "I still haven't made my mind up about the trial"),
      new TextOption(
        'how_to_work',
        'I don’t understand how to work with the app',
      ),
      new TextOption(
        'no_results',
        "I'm not noticing results or unsure if Loóna works for me",
      ),
      new TextOption(
        'not_right_time',
        "It's not the right time for me, I need to take a break",
      ),
      new TextOption('other', 'Other'),
    ],
  ),
  [QuestionIds.Offer]: new OptionalQuestion(
    QuestionIds.Reason,
    QuestionIds.Offer,
    {
      expensive: new OptionQuestion(
        QuestionIds.Offer,
        'Reclaim your sleep and enjoy 75% off',
        [
          new TextOption(
            'yes_change_subscription',
            'Yes, please change my subscription price to $14.99 per quarter (75% off)',
          ),
          new TextOption('no_change_subscription', "No, I'd like to cancel"),
        ],
        false,
        (
          <div>
            We get it - new habits take time. We want you focusing on your
            health, not your wallet.
            <br /> Extend Premium today for less:
            <br /> <br />
            <span>75% off Unlimited Access</span>
            <br /> <br />
            Personalised sleep plan, wake-up routine, breathing exercises and
            library of escapes and immersive stories.
            <br /> <br />
            Fancy getting healthy for less?
          </div>
        ),
      ),
      no_results: new CustomQuestion(QuestionIds.Offer),
      how_to_work: new CustomQuestion(QuestionIds.Offer),
      not_right_time: new CustomQuestion(QuestionIds.Offer),
      mind: new CustomQuestion(QuestionIds.Extend),
      other: new CustomQuestion(QuestionIds.Offer),
    },
  ),
  [QuestionIds.HowLikely]: new OptionQuestion(
    QuestionIds.HowLikely,
    'How likely are you to try Loóna again in the future?',
    [
      new TextOption('very_likely', 'Very likely'),
      new TextOption('somewhat_likely', 'Somewhat likely'),
      new TextOption('neither_likely', 'Neither likely nor unlikely'),
      new TextOption('somewhat_unlikely', 'Somewhat unlikely'),
      new TextOption('very_unlikely', 'Very unlikely'),
    ],
  ),
  [QuestionIds.Extend]: new CustomQuestion(QuestionIds.Extend),
  [QuestionIds.FinishWinBack]: new CustomQuestion(QuestionIds.FinishWinBack),
  [QuestionIds.Loader]: new CustomQuestion(QuestionIds.Loader),
  [QuestionIds.FinishUserLost]: new CustomQuestion(QuestionIds.FinishUserLost),
};
