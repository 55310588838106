import { appAnalytics } from 'analytics';
import { RecaptchaError } from 'analytics/AllEvents';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type HandlerFunction<T = any> = (
  captchaToken: string,
  ...args: T[]
) => Promise<void> | void;

export const withRecaptcha = <T extends any[]>(
  handler: HandlerFunction<T>,
  setLoading: (loading: boolean) => void,
  setError: (error: string) => void,
) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return async (...args: T) => {
    if (!executeRecaptcha) {
      setLoading(false);
      setError('Execute reCAPTCHA not yet available');
      appAnalytics.trackEvent(new RecaptchaError('Not available'));
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha('subscription_form');
      if (!recaptchaToken) {
        setError('No token received from reCAPTCHA');
        appAnalytics.trackEvent(new RecaptchaError('No token'));
      } else {
        await handler(recaptchaToken, ...args);
      }
    } catch (error) {
      setError('Failed to execute reCAPTCHA');
      appAnalytics.trackEvent(
        new RecaptchaError('Failed to execute reCAPTCHA'),
      );
    } finally {
      setLoading(false);
    }
  };
};
