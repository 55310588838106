import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  onRetry: () => void;
  darkTheme?: boolean;
};

export const Error = ({ onRetry, darkTheme = false }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} ${darkTheme ? styles.dark : ''}`}>
      <div className={styles.image} />
      <div className={styles.content}>
        <div className={styles.title}>{t('errorTitle')}</div>
        <div className={styles.description}>
          {t('errorDescription1')}
          <br />
          {t('errorDescription2')}
        </div>

        <button className={styles.button} onClick={onRetry}>
          {t('tryAgainButton')}
        </button>
      </div>
    </div>
  );
};
