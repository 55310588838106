import React, { useEffect } from 'react';
import styles from './styles.module.scss';

type Props = {
  isVisible: boolean;
  title: string | JSX.Element[] | JSX.Element;
  description: string | JSX.Element[] | JSX.Element;
  darkTheme?: boolean;
};

export const LoadingScreen = ({
  isVisible,
  title,
  description,
  darkTheme = false,
}: Props) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isVisible]);

  return (
    <div
      className={`${styles.background} ${isVisible ? '' : styles.hidden} ${
        darkTheme ? styles.dark : ''
      }`}
    >
      <div className={styles.spinner} />
      <h3>{title}</h3>
      {description}
    </div>
  );
};
