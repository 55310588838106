export const getCardElementStyle = () => {
  return {
    fontSize: '12px',
    lineHeight: '22px',
    fontWeight: '400',
    fontFamily: 'Arial',
    placeholder: {
      fontFamily: 'Arial',
      color: 'grey',
      fontSize: '12px',
      fontWeight: '400',
      content: {
        number: 'Card number',
        expiry: 'MM/YY',
        cvv: 'CVV',
      },
    },
  };
};
