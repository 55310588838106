import { StepperItem } from '../../StepperItem';

export enum DotStates {
  Filled,
  Checked,
  Numbered,
}

export abstract class BaseDot implements StepperItem {
  state: DotStates;

  protected constructor(state: DotStates) {
    this.state = state;
  }
}

export class FilledDot extends BaseDot {
  constructor() {
    super(DotStates.Filled);
  }
}

export class CheckedDot extends BaseDot {
  constructor() {
    super(DotStates.Checked);
  }
}

export class NumberedDot extends BaseDot {
  stepNumber: number;

  constructor(stepNumber: number) {
    super(DotStates.Numbered);
    this.stepNumber = stepNumber;
  }
}

export { DotComponent } from './DotComponent';
