import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import { PurchaseActionTypes } from './types';

export const SET_GIFT_CARD_TYPE = 'SET_GIFT_CARD_TYPE';

export interface SetGiftCardType {
  type: typeof SET_GIFT_CARD_TYPE;
  payload: {
    type: GiftCardTypes;
  };
}

export function setGiftCardType(type: GiftCardTypes): PurchaseActionTypes {
  return {
    type: SET_GIFT_CARD_TYPE,
    payload: {
      type: type,
    },
  };
}
