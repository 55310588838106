import React from 'react';
import { Stepper } from 'onboarding/components/stepper/Stepper';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { useStepperWrapperLogic } from './StepperWrapper.hook';

type Props = { questionId: QuestionIds; className: string };

export const StepperWrapper = ({ questionId, className }: Props) => {
  const { stepName, currentStep, stepsTotal, currentSubstep, substepsTotal } =
    useStepperWrapperLogic({ questionId });

  return (
    <Stepper
      stepName={stepName}
      currentStep={currentStep}
      stepsTotal={stepsTotal}
      currentSubstep={currentSubstep}
      substepsTotal={substepsTotal}
      className={className}
    />
  );
};
