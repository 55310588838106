import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { SyntheticEvent, useEffect, useState } from 'react';
import { PlaceOption } from './PlaceOption';
import { PlaceQuestion } from './PlaceQuestion';

export const usePlaceComponentLogic = (question: PlaceQuestion) => {
  const dispatch = useAppDispatch();
  const { answers } = useAppSelector((state) => state.onboarding);
  const [activeOptions, setActiveOptions] = useState<string[]>([]);
  const [optionStyle, setOptionStyle] = useState('');
  const [isLeaving, setLeaving] = useState(false);

  useEffect(() => {
    setActiveOptionsIds();
    setOptionStyle('enter');
    setLeaving(false);
  }, [question]);

  const optionHandler = (option: PlaceOption) => (e: SyntheticEvent) => {
    e.preventDefault();

    if (activeOptions.includes(option.id)) {
      setActiveOptions(activeOptions.filter((id) => id !== option.id));
    } else {
      setActiveOptions([...activeOptions, option.id]);
    }
  };

  const setActiveOptionsIds = () => {
    const currentAnswer = answers[question.id];

    if (!currentAnswer) setActiveOptions([]);

    if (
      currentAnswer &&
      typeof currentAnswer === 'string' &&
      question.hasOptionId(currentAnswer)
    ) {
      setActiveOptions([currentAnswer]);
    } else if (Array.isArray(currentAnswer)) {
      setActiveOptions(
        (currentAnswer as string[]).filter((it) => question.hasOptionId(it)),
      );
    } else {
      setActiveOptions([]);
    }
  };

  const nextHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isLeaving) {
      setOptionStyle('leave');
      setLeaving(true);
      setTimeout(() => {
        appAnalytics.trackEvent(new SaveAnswer(question.id, activeOptions));
        dispatch(setAnswer(question.id, activeOptions));
      }, 100);
    }
  };

  return {
    activeOptions,
    optionStyle,
    optionHandler,
    nextHandler,
  };
};
