export enum QuestionIds {
  Welcome = 'Welcome',
  Reason = 'Reason',
  HowLikely = 'HowLikely',
  Extend = 'Extend',
  Loader = 'Loader',
  FinishWinBack = 'FinishWinBack',
  FinishUserLost = 'FinishUserLost',
  Offer = 'Offer',
}
