import { RefObject, SyntheticEvent, useEffect } from 'react';

type Props = {
  alert: RefObject<HTMLDivElement>;
  visible: boolean;
  onSuccess: () => void;
};

export const useAlertLogic = ({ alert, visible, onSuccess }: Props) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [visible]);

  const close = (e: SyntheticEvent) => {
    e.preventDefault();
    // eslint-disable-next-line
    if (e.target != alert.current) {
      onSuccess();
    }
  };

  return {
    close,
  };
};
