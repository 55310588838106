import { GiftCardProducts } from 'bootstrap/data/giftproduct/GiftCardProducts';
import {
  GiftCardProductsListResponse,
  GiftCardRequest,
  SubscriptionResponse,
} from './giftcard.types';
import { ENDPOINTS } from '../endpoints';
import { convertToGiftCards } from './helpers';
import { fetchServer, toResultPromise } from '../commonnet';
import { ServerResponse } from '../commonnet.types';
import { RegisterResponse } from '../user/user.types';

export async function getGiftCardProducts(
  currency?: string,
): Promise<GiftCardProducts> {
  const result = await fetchServer<GiftCardProductsListResponse>({
    url: ENDPOINTS.GIFT_CARDS_LIST,
    method: 'GET',
  });

  return toResultPromise(
    result,
    convertToGiftCards(result.response.items, currency),
  );
}

export async function buyGiftCard({
  email,
  product,
  quantity,
  token,
  threeDsToken,
  firstName,
  lastName,
  currency,
  price,
  paymentMethod,
  recaptchaToken,
}: GiftCardRequest): Promise<SubscriptionResponse> {
  const result = await fetchServer<ServerResponse>({
    url: ENDPOINTS.BUY_GIFT_CARD,
    method: 'POST',
    body: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      currency: currency || product.currency,
      user_billing_token: token,
      three_d_secure_token: threeDsToken,
      line_items: {
        quantity: quantity,
        item_code: product.code,
        price: price || product.price,
      },
      payment_method: paymentMethod,
      recaptcha_token: recaptchaToken,
    },
  });

  if (result.error.code === 0 || result.error.code === 522) {
    // @ts-ignore
    return Promise.resolve(result.response);
  } else {
    return Promise.reject(result.error);
  }
}

export async function socialLogin(
  userId: string,
  platform: 'facebook',
): Promise<RegisterResponse> {
  const result = await fetchServer<RegisterResponse>({
    url: ENDPOINTS.SOCIAL_LOGIN[platform],
    method: 'POST',
    body: {
      provider_user_id: userId,
    },
  });

  return toResultPromise(
    result,
    Promise.resolve(() =>
      localStorage.setItem('auth_token', result.response.auth_token),
    ).then(() => result.response),
  );
}

export async function socialRegister(
  firstName: string,
  lastName: string,
  email: string,
  userId: string,
  accessToken: string,
  platform: 'facebook',
): Promise<RegisterResponse> {
  return fetchServer<RegisterResponse>({
    url: ENDPOINTS.SOCIAL_REG[platform],
    method: 'POST',
    body: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      provider_user_id: userId,
      token: {
        key: accessToken,
      },
    },
  })
    .then(() => {
      return socialLogin(userId, platform);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
