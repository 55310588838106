import React from 'react';
import styles from './styles.module.scss';

type BackgroundProps = {
  className?: string;
  children: React.ReactNode;
};

export const FogBackground = ({ className, children }: BackgroundProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={`${styles.gradient} ${styles.start}`} />
        <div className={styles.particles} />
        <div className={`${styles.gradient} ${styles.end}`} />
      </div>
      <div className={`${styles.content} ${className}`}>{children}</div>
    </div>
  );
};
