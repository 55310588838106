import React from 'react';
import Spacer from 'components/spacer/Spacer';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import { useExtendTrialLogic } from './ExtendTrial.hook';
import styles from './styles.module.scss';

const ExtendTrial = () => {
  const {
    error,
    trialExpiredDate,
    prolongSubscription,
    cancelSubscription,
    setError,
  } = useExtendTrialLogic();

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      <div className={`${styles.wrapper} ${styles.maxWidth}`}>
        <div className={styles.title}>
          We just extended your trial for another 15 days
        </div>
        <div className={styles.note}>
          Avoid unexpected charges. <br /> <br />
          Retain access to your personalised plan to improve your sleep quality
          and productivity. You will not be charged until {trialExpiredDate}
        </div>

        <div className={styles.content}>
          <Spacer />
          <StickyContainer className={styles.stickyContainer}>
            <button className={styles.button} onClick={prolongSubscription}>
              Return to Loóna
            </button>
            <button
              className={`${styles.button} ${styles.secondary}`}
              onClick={cancelSubscription}
            >
              Cancel anyway
            </button>
          </StickyContainer>
        </div>
      </div>
    </>
  );
};

export default ExtendTrial;
