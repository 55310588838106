import { QuestionIds } from '../constants/QuestionIds';
import {
  isAdhdTest,
  isDefaultOnboarding,
  isEducationV1Quiz,
  isEducationV2Quiz,
  isEducationV3Quiz,
  isGadTest,
  isInsomniaV1Quiz,
  isInsomniaV2Quiz,
} from 'navigation';
import { DefaultOnboradingQuestionsOrder } from './DefaultOnboardingOrder';
import { BasicQuestionsOrder } from './BasicOrder';
import { GadTestQuestionsOrder } from './GadTestOrder';
import { EducationQuizV1Order } from './EducationQuizV1Order';
import { EducationQuizV2Order } from './EducationQuizV2Order';
import { EducationQuizV3Order } from './EducationQuizV3Order';
import { InsomniaQuizV1Order } from './InsomniaQuizV1Order';
import { InsomniaQuizV2Order } from './insomniaQuizV2Order';
import { AdhdQuestionsOrder } from './AdhdFlowOrder';

export const getQuestionsOrder = (): QuestionIds[] => {
  const isDefaultOnboardingFlow = isDefaultOnboarding();
  const isGadFlow = isGadTest();
  const isEducationV1QuizFlow = isEducationV1Quiz();
  const isEducationV2QuizFlow = isEducationV2Quiz();
  const isEducationV3QuizFlow = isEducationV3Quiz();
  const isInsomniaV1QuizFlow = isInsomniaV1Quiz();
  const isInsomniaV2QuizFlow = isInsomniaV2Quiz();
  const isAdhdFlow = isAdhdTest();

  switch (true) {
    case isDefaultOnboardingFlow:
      return DefaultOnboradingQuestionsOrder;
    case isGadFlow:
      return GadTestQuestionsOrder;
    case isEducationV1QuizFlow:
      return EducationQuizV1Order;
    case isEducationV2QuizFlow:
      return EducationQuizV2Order;
    case isEducationV3QuizFlow:
      return EducationQuizV3Order;
    case isInsomniaV1QuizFlow:
      return InsomniaQuizV1Order;
    case isInsomniaV2QuizFlow:
      return InsomniaQuizV2Order;
    case isAdhdFlow:
      return AdhdQuestionsOrder;
    default:
      return BasicQuestionsOrder;
  }
};

export const QuestionsOrder: QuestionIds[] = getQuestionsOrder();
