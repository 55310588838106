import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Question, QuestionTypes } from 'onboarding/question/Question';

export class StatementQuestion extends Question {
  title: string;
  statement: string;
  isGadStatementQuestion?: boolean;

  constructor(
    id: QuestionIds,
    title: string,
    statement: string,
    isGadStatementQuestion?: boolean,
  ) {
    super(QuestionTypes.Statement, id, true);
    this.title = title;
    this.statement = statement;
    this.isGadStatementQuestion = isGadStatementQuestion;
  }
}
