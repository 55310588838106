import React from 'react';
import { ColorQuestion } from './ColorQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import { useColorComponentLogic } from './ColorQuestion.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<ColorQuestion>;

export const ColorQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const { t } = useTranslation();
  const { title, description } = question;

  const { optionStyle, activeOptionId, optionHandler } =
    useColorComponentLogic(question);

  return (
    <div
      className={`
        ${styles.container} 
        ${styles.speed} 
        maxWidth
      `}
    >
      <h2 className={styles.title}>{t(title)}</h2>
      <h3 className={styles.description}>{t(description)}</h3>
      <div className={styles.options}>
        {question.options.map((option) => (
          <div
            key={option.id}
            className={`
              ${styles.option} 
              ${styles[optionStyle]} 
              ${option.id === activeOptionId ? styles.active : ''}`}
            onClick={optionHandler(option)}
          >
            <div
              className={styles.colorCircle}
              style={{ backgroundColor: option.color }}
            />
            <div className={styles.optionTitle}>{t(option.text)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
