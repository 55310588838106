import React from 'react';
import ArrowIcon from 'assets/icon_arrow.svg';
import { CookieConsent } from 'onboarding/components/cookieconsent/CookieConsent';
import { Background } from 'welcome/components/background/Background';
import { useTranslation } from 'react-i18next';
import { useDefaultWelcomeLogic } from './hooks/useDefaultWelcome';
import styles from './styles.module.scss';

const DefaultWelcome = () => {
  const { t } = useTranslation();
  const { handleReduceStress, handleSleepBetter } = useDefaultWelcomeLogic();

  return (
    <Background>
      <h1 className={styles.title}>{t('welcomeTitle')}</h1>

      <p className={styles.arrowTitle}>{t('welcomeArrowTitle')}</p>

      <img className={styles.arrow} src={ArrowIcon} alt="" />

      <div className={styles.buttons}>
        <button onClick={handleReduceStress}>{t('welcomeStressButton')}</button>

        <button onClick={handleSleepBetter}>{t('welcomeSleepButton')}</button>
      </div>

      <div className={styles.footer}>
        <div className={styles.links}>
          <a href="https://loona.app/terms">{t('termsOfUse')}</a>
          <a href="https://loona.app/privacy">{t('privacyPolicy')}</a>
        </div>
        <span className={styles.copyright}>
          © {new Date().getFullYear()}
          {t('rightsReserved')}
        </span>
      </div>
      <CookieConsent />
    </Background>
  );
};

export default DefaultWelcome;
