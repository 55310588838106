import React from 'react';
import { usePreloadImages } from 'hooks/usePreloadImages';
import { useProgressText } from 'hooks/useProgressText';
import { ProgressBar } from './components/progressbar/ProgressBar';
import { useLoader } from 'hooks/useLoader';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { STEPS } from './ImageLoader.constants';
import { getPreloadImages } from './ImageLoader.utils';
import { isSleepActivityLoaderV2 } from 'navigation';
import styles from './styles.module.scss';

const STEP_DURATION = 1500;
const STEP_DELAY = 350;

export const ImageLoader = () => {
  usePreloadImages(getPreloadImages());
  const { t } = useTranslation();

  const duration = (STEP_DURATION + STEP_DELAY) * STEPS.length;
  const text = useProgressText(
    'BUILDING YOUR PLAN',
    duration,
    'BUILDING YOUR PLAN...',
  );

  const { containerStyle, transitionStyle, step, progress } = useLoader({
    steps: STEPS,
    stepDuration: STEP_DURATION,
    stepDelay: STEP_DELAY,
    duration,
  });

  return (
    <div
      className={`
        ${styles.container} 
        ${styles[containerStyle]} 
        ${isSleepActivityLoaderV2() ? styles.v2 : ''} `}
    >
      <div className={styles.content}>
        <div
          className={`
            ${styles[`loaderImage-${step}`]} 
            ${styles[transitionStyle]}`}
        />
        <h2
          className={`
            ${styles.title} 
            ${styles[transitionStyle]} 
          `}
        >
          {t(`${STEPS[step].text}`)}
        </h2>
        <div
          className={`
            ${styles[`loaderImage-${step}`]} 
            ${isSleepActivityLoaderV2() ? styles.v2 : styles.default}
            ${transitionStyle}
          `}
        />
        <Spacer />
        <ProgressBar progress={progress} />
        <div className={styles.status}>{text}</div>
      </div>
    </div>
  );
};
