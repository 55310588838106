import { appAnalytics } from 'analytics';
import { SubscriptionError, SubscriptionSuccess } from 'analytics/AllEvents';
import { remoteConfig } from 'analytics/Firebase';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { loadProducts } from 'bootstrap/product/actions';
import { setCountryCode, setSubscriptionActive } from 'bootstrap/user/actions';
import { getString } from 'firebase/remote-config';
import { ViewPIScreen } from 'invitepurchase/analytics';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { pushWithSearchQuery } from 'utils';

export const usePaymentLogic = () => {
  const dispatch = useAppDispatch();

  const productCode = getString(remoteConfig, 'premium_invite_product_code');
  const { inviteCodeId } = useParams<{ inviteCodeId: string }>();

  const { authToken, hasSubscription, countryCode } = useAppSelector(
    (state) => state.user,
  );
  const { email } = useAppSelector((state) => state.user);
  const { product } = useAppSelector((state) => {
    const products = state.product.products;
    return {
      product: products.size > 0 && products.get(productCode),
    };
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(setCountryCode(countryCode || ''));

    if (!authToken) {
      dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/signin`));
    } else if (hasSubscription) {
      dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/done`));
    } else if (!product) {
      dispatch(loadProducts())
        .catch((_) => {
          setError(true);
        })
        .finally(() => {
          setError(false);
        });
    }
    appAnalytics.trackEvent(new ViewPIScreen('piPayment'));
  }, []);

  useEffect(() => {
    if (product) {
      setError(false);
    }
  }, [product]);

  const handlePayPalSuccess = ({
    orderId,
    productBought,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));
    appAnalytics.trackEvent(
      new SubscriptionSuccess(
        productBought.trialLength
          ? productBought.setupFee
          : productBought.amount,
        productBought.currency,
        productBought.code,
        {
          eventId: orderId,
          email: email,
          onboardingType: 'pi',
        },
      ),
    );
    dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/done`));
  };

  const handlePayPalError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  const handleCardSuccess = ({
    orderId,
    productBought,
    zip,
    firstName,
    lastName,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));
    appAnalytics.trackEvent(
      new SubscriptionSuccess(
        productBought.trialLength
          ? productBought.setupFee
          : productBought.amount,
        productBought.currency,
        productBought.code,
        {
          eventId: orderId,
          email,
          zip,
          firstName,
          lastName,
          onboardingType: 'pi',
        },
      ),
    );
    dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/done`));
  };

  const handleCardError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  const handleRetry = () => loadProducts();

  return {
    error,
    product,
    handleCardError,
    handlePayPalError,
    handleCardSuccess,
    handlePayPalSuccess,
    handleRetry,
  };
};
