import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { ProductCell } from '../productcell/ProductCell';
import { PresaleProduct } from 'subscription/presale/Presale.types';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  products: Map<string, Product>;
  presaleProducts: PresaleProduct[];
  code: string;
  currencyCode?: string;
  onSetProductCodeHandler: (code: string) => void;
};

export const PricesContainer = ({
  products,
  presaleProducts,
  code,
  currencyCode,
  onSetProductCodeHandler,
}: Props) => {
  const { t } = useTranslation();

  const renderProductCells = () =>
    presaleProducts.map(({ productCode, label }: PresaleProduct) => {
      const product = products.get(productCode);

      if (!product) return null;

      return (
        <ProductCell
          key={productCode}
          product={product}
          currencyCode={currencyCode}
          label={t(label)}
          isActive={productCode === code}
          onSelected={onSetProductCodeHandler}
        />
      );
    });

  return (
    <div
      className={styles.pricesContainer}
      style={{
        gridTemplateColumns: `repeat(${presaleProducts.length}, 1fr)`,
        maxHeight: presaleProducts.length < 3 ? '75px' : '',
      }}
    >
      {renderProductCells()}
    </div>
  );
};
