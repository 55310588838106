import { QuestionIds } from '../constants/QuestionIds';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const InsomniaQuizV2Order: QuestionIds[] = [
  QuestionIds.IsiQ1,
  QuestionIds.IsiQ2,
  QuestionIds.IsiQ3,
  QuestionIds.IsiQ4,
  QuestionIds.IsiQ5,
  QuestionIds.IsiD1,

  QuestionIds.RMeqQ1,
  QuestionIds.RMeqQ2,
  QuestionIds.RMeqQ3,
  QuestionIds.RMeqQ4,
  QuestionIds.RMeqQ5,

  QuestionIds.AboutYouProof1,
  QuestionIds.SleepAndActivityD1,
  QuestionIds.AboutYouGender,
  QuestionIds.AboutYouAge,
  QuestionIds.AboutYouRoutine,
  QuestionIds.AboutYouD2,
  QuestionIds.AboutYouKids,
  QuestionIds.AboutYouD3,
  QuestionIds.AboutYouActivity,
  QuestionIds.AboutYouD4,

  QuestionIds.SleepAndActivityProof2,
  QuestionIds.SleepAndActivityCoffee,
  QuestionIds.SleepAndActivityDinner,
  QuestionIds.SleepAndActivityAfterMidnight,
  QuestionIds.SleepAndActivityLessThanEightHours,
  QuestionIds.SleepAndActivityExtraMotivation,
  QuestionIds.SleepAndActivityD2,
  QuestionIds.SleepAndActivityLoader,

  QuestionIds.PersonalizationD1,
  ...PersonalizationSectionQuestionIds,
];
