import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useEffect, useState } from 'react';

export type Step = {
  text: string | JSX.Element;
  image?: string;
};

interface Props {
  steps: Step[];
  stepDuration: number;
  stepDelay: number;
  duration?: number;
}

export const useLoader = ({
  steps,
  stepDuration,
  stepDelay,
  duration = 0,
}: Props): any => {
  const dispatch = useAppDispatch();
  const [isInTransition, setInTransition] = useState(false);
  const [step, setStep] = useState(0);
  const [containerStyle, setContainerStyle] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    let isDelaying = false;
    const stepsCount = steps.length;
    setContainerStyle('enter');

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const currentStep = Math.floor(elapsedTime / (stepDuration + stepDelay));
      setProgress(elapsedTime / duration);

      if (currentStep < stepsCount) {
        const nextStep = isDelaying ? Math.max(currentStep, 0) : currentStep;
        const currentOffset = stepDuration * nextStep + stepDelay * nextStep;
        const currentStepDuration = elapsedTime - currentOffset;
        const isDelayPeriod =
          currentStepDuration > stepDuration &&
          currentStepDuration <= stepDuration + stepDelay;
        if (isDelayPeriod) {
          if (!isDelaying && nextStep !== stepsCount - 1) {
            isDelaying = true;
            setInTransition(true);
          }
        } else {
          isDelaying = false;
          if (step !== nextStep) {
            setStep(nextStep);
            setInTransition(false);
          }
        }
      } else {
        clearInterval(timer);
        setContainerStyle('leave');
        setTimeout(() => {
          dispatch(goToNextQuestion());
        }, 250);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return {
    step,
    containerStyle,
    progress,
    transitionStyle: isInTransition ? 'transitionOut' : 'transitionIn',
  };
};
