import React, { FC } from 'react';
import { PaymentsPicker } from 'subscription/recurly/components/paymentpicker/PaymentsPicker';
import { useTranslation } from 'react-i18next';
import { ElementsProps } from './DefaultElement.types';
import styles from './styles.module.scss';

export const DefaultElements: FC<ElementsProps> = ({
  product,
  onPaymentSuccess,
  onPaymentError,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className={styles.description}>{t('paymentTitle')}</h4>
      <PaymentsPicker
        product={product}
        onSuccess={onPaymentSuccess}
        onError={onPaymentError}
        translation={t}
      />
    </>
  );
};
