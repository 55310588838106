import { QuestionIds } from '../constants/QuestionIds';

export const EducationQuizV2Order: QuestionIds[] = [
  QuestionIds.RMeqQ1,
  QuestionIds.RMeqQ2,
  QuestionIds.RMeqQ3,
  QuestionIds.RMeqQ4,
  QuestionIds.RMeqQ5,

  QuestionIds.AboutYouProof1,
  QuestionIds.SleepAndActivityD1,
  QuestionIds.AboutYouGender,
  QuestionIds.AboutYouAge,
  QuestionIds.AboutYouRoutine,
  QuestionIds.AboutYouD2,
  QuestionIds.AboutYouKids,
  QuestionIds.AboutYouD3,
  QuestionIds.AboutYouActivity,
  QuestionIds.AboutYouD4,

  QuestionIds.SleepAndActivityProof2,
  QuestionIds.SleepAndActivityCoffee,
  QuestionIds.SleepAndActivityDinner,
  QuestionIds.SleepAndActivityAfterMidnight,
  QuestionIds.SleepAndActivityLessThanEightHours,
  QuestionIds.SleepAndActivityExtraMotivation,
  QuestionIds.SleepAndActivityD2,
  QuestionIds.SleepAndActivityLoader,

  QuestionIds.EduD1,
  QuestionIds.EduQ1,
  QuestionIds.EduQ1Answer,
  QuestionIds.EduQ2,
  QuestionIds.EduQ2Answer,
  QuestionIds.EduQ3,
  QuestionIds.EduQ3Answer,
  QuestionIds.EduQ4,
  QuestionIds.EduQ4Answer,
  QuestionIds.EduQ5,
  QuestionIds.EduQ5Answer,
];
