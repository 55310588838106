export class ColorOption {
  id: string;
  color: string;
  text: string;

  constructor(id: string, color: string, text: string) {
    this.id = id;
    this.color = color;
    this.text = text;
  }
}
