import { appAnalytics } from 'analytics';
import { SwitchPlan } from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { Products } from 'bootstrap/data/product/Product.types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from 'subscription/components/alert/Alert';

type Props = {
  products: Products;
  isShownPromoAlert: boolean;
  showPromoAlert: (shown: boolean) => void;
  setNewProduct: (product: Product) => void;
};

const PromoAlert = ({
  products,
  isShownPromoAlert,
  showPromoAlert,
  setNewProduct,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Alert visible={isShownPromoAlert} onSuccess={() => showPromoAlert(false)}>
      <h3>{t('promoAlertTitle')}</h3>
      <p>
        {t('promoAlertQuestionLine1')}
        <br />
        <b>{t('promoAlertQuestionLine2')}</b>
      </p>
      <button
        onClick={() => {
          showPromoAlert(false);
        }}
      >
        {t('promoAlertContinueButton')}
      </button>
      <button
        className="inverted"
        onClick={() => {
          const newProduct = products.default.standard_trial;
          setNewProduct(newProduct);
          appAnalytics.trackEvent(new SwitchPlan(false, newProduct.id));
          showPromoAlert(false);
        }}
      >
        {t('promoAlertSwitchButton')}
      </button>
    </Alert>
  );
};

export default PromoAlert;
