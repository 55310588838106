import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { emailRegister } from 'bootstrap/user/actions';
import { ViewQPScreen } from 'quickpurchase/analytics';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pushWithSearchQuery, validateEmail } from 'utils';
import { shouldHandleKeyboard } from './SignIn.constants';

export const useSignInLogic = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [emailState, setEmailState] = useState({
    value: '',
    error: '',
    isFocused: false,
  });

  const [passwordState, setPasswordState] = useState({
    value: '',
    error: '',
  });

  const [errorState, setErrorState] = useState({
    error: '',
    isVisible: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [socialsShown, setSocialsShown] = useState(true);

  useEffect(() => {
    appAnalytics.trackEvent(new ViewQPScreen('qpAccount'));

    const listener = () => {
      if (window.innerHeight === window.visualViewport.height) {
        setSocialsShown(true);
      } else if (window.innerHeight > window.visualViewport.height * 0.9) {
        setSocialsShown(false);
      }
    };

    window.visualViewport.addEventListener('resize', listener);
    return () => {
      window.visualViewport.removeEventListener('resize', listener);
    };
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setErrorState({ ...errorState, isVisible: false });

    if (!isLoading && validateForm()) {
      setIsLoading(true);
      dispatch(emailRegister(emailState.value, passwordState.value, 'qp'))
        .then((hasSubscription) => {
          setIsLoading(false);
          navigateNext(hasSubscription);
        })
        .catch((message) => {
          setIsLoading(false);
          if (message?.email) {
            setEmailState({ ...emailState, error: t('emailError') });
          } else if (message?.password || message === 'WRONG_PASSWORD') {
            setPasswordState({ ...passwordState, error: t('passwordError') });
          } else {
            setErrorState({ ...errorState, error: message, isVisible: true });
          }
        });
    }
  };

  const navigateNext = (hasSubscription: boolean) => {
    if (hasSubscription) {
      dispatch(pushWithSearchQuery('/quick-purchase/done'));
    } else {
      dispatch(pushWithSearchQuery('/quick-purchase/payment'));
    }
  };

  const validateForm = (): boolean => {
    const isEmailValid = validateEmail(emailState.value);
    const isPasswordValid = passwordState.value !== '';
    if (!isEmailValid) {
      setEmailState({ ...emailState, error: t('emailError') });
      return false;
    } else if (!isPasswordValid) {
      setPasswordState({ ...passwordState, error: t('passwordError') });
      return false;
    } else return true;
  };

  const onSocialError = (type: 'google' | 'facebook', error: string | any) => {
    setErrorState({
      ...errorState,
      error:
        typeof error.message?.email === 'string'
          ? error.message.email
          : error.message,
      isVisible: true,
    });
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState({ ...emailState, error: '', value: event.target.value });
    setErrorState({ ...errorState, isVisible: false });
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({
      ...passwordState,
      error:
        event.target.value.length < 8
          ? 'Password should be at least 8 characters'
          : '',
      value: event.target.value,
    });
    setErrorState({ ...errorState, isVisible: false });
  };

  const handleForgotPassword = (event: SyntheticEvent) => {
    event.preventDefault();
    const domain =
      process.env.NODE_ENV === 'production'
        ? 'https://my.loona.app'
        : 'https://dev-my.loona.app';
    window.location.href = `${domain}/reset-password`;
  };

  const socialsShownHandler = (isFocused: boolean) => {
    if (shouldHandleKeyboard) {
      setSocialsShown(!isFocused);
    }
  };

  return {
    emailState,
    passwordState,
    errorState,
    socialsShown,
    isLoading,
    setEmailState,
    setErrorState,
    handleSubmit,
    onSocialError,
    onEmailChange,
    onPasswordChange,
    handleForgotPassword,
    socialsShownHandler,
    navigateNext,
  };
};
