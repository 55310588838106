import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import en from './en/en.json';
import de from './de/de.json';
import es from './es/es.json';
import pt from './pt/pt.json';

export const resources = {
  en: en,
  de: de,
  es: es,
  pt: pt,
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    ns: ['translation'],
    defaultNS: 'translation',
    react: {
      wait: true,
    },
  });

export default i18n;
