import { PlanSelectType } from 'subscription/singleproduct/components/plansection/PlanSection.constants';
import { UserActionTypes } from './userActions.types';

export const SET_PLAN_SELECT_TYPE = 'SET_PLAN_SELECT_TYPE';

export function setPlanType(planType: PlanSelectType): UserActionTypes {
  return {
    type: SET_PLAN_SELECT_TYPE,
    payload: {
      planType,
    },
  };
}
