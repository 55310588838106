import React, { SyntheticEvent, useEffect } from 'react';
import { CookieConsent } from 'onboarding/components/cookieconsent/CookieConsent';
import WelcomeGadImage from 'assets/welcome/welcome_gad.png';
import { Footer } from 'welcome/components/footer/Footer';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useAppDispatch } from 'bootstrap/hooks';
import { appAnalytics } from 'analytics';
import { ContinueWelcome, ScreenOpened } from 'analytics/AllEvents';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';

export const AdhdWelcome = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    appAnalytics.trackEvent(new ScreenOpened('onboardingWelcome'));
  }, []);

  const handleTakeTest = (e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new ContinueWelcome('adhd'));
    dispatch(setAnswer(QuestionIds.Welcome, '', true));
  };

  return (
    <div className={styles.wrapper}>
      <img src={WelcomeGadImage} className={styles.image} alt="" />
      <div className={styles.content}>
        <div className={styles.title}>
          {t('welcomeAdhdTitleLine1')}{' '}
          <span className={styles.accent}>{t('welcomeAdhdTitleLine2')}</span>
        </div>

        <div className={styles.subtitle}>{t('welcomeAdhdSubtitle')}</div>

        <button className={styles.button} onClick={handleTakeTest}>
          {t('welcomeRmeqAndGadContinueButton')}
        </button>

        <Footer />
      </div>
      <CookieConsent />
    </div>
  );
};
