import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGadTest } from './GadResults.hook';
import styles from './styles.module.scss';

export const GadResults = () => {
  const { t } = useTranslation();
  const { gadTestResult, nextButtonHandler } = useGadTest();

  const { title, description, imageSrc } = gadTestResult;

  return (
    <div className={styles.container}>
      {imageSrc && <img className={styles.image} src={imageSrc} alt="" />}
      <div className={styles.content}>
        <div className={styles.label}>{t('GadResultLabel')}</div>
        <div className={styles.title}>{t(title)}</div>
        <div className={styles.description}>{t(description)}</div>
        <button className={styles.button} onClick={nextButtonHandler}>
          {t('GadResultStartPlanButton')}
        </button>
        <div className={styles.note}>{t('GadResultNote')}</div>
      </div>
    </div>
  );
};
