import React, { useEffect } from 'react';
import AmericanExpress from 'assets/payments/icon_amex.svg';
import Mastercard from 'assets/payments/icon_mastercard.svg';
import PayPal from 'assets/payments/icon_paypal.png';
import PayPalWhite from 'assets/payments/icon_paypal_white.svg';
import ApplePay from 'assets/payments/icon_apple_pay.svg';
import ApplePayWhite from 'assets/payments/icon_apple_pay_white.svg';
import Visa from 'assets/payments/icon_visa.svg';
import Maestro from 'assets/payments/icon_maestro.svg';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setPaymentMethod } from 'bootstrap/user/actions/setUserInfo';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';
import styles from './styles.module.scss';

type Props = {
  options: PaymentMethods[];
  currentOption: PaymentMethods;
  darkTheme?: boolean;
  onChange: (option: PaymentMethods) => void;
};

export const Picker = ({
  options,
  currentOption,
  darkTheme,
  onChange,
}: Props) => {
  const dispatch = useAppDispatch();
  const { paymentMethod } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!paymentMethod) {
      dispatch(setPaymentMethod(PaymentMethods.Card));
    }
  }, []);

  const buttonContent = {
    [PaymentMethods.Card]: (
      <>
        <img
          className={`${styles.paymentType} ${styles.visa}`}
          src={Visa}
          alt="Visa"
        />
        <img
          className={`${styles.paymentType} ${styles.mastercard}`}
          src={Mastercard}
          alt="Mastercard"
        />
        <img
          className={`${styles.paymentType} ${styles.maestro}`}
          src={Maestro}
          alt="maestro"
        />
        <img
          className={`${styles.paymentType} ${styles.amex}`}
          src={AmericanExpress}
          alt="American Express"
        />
      </>
    ),
    [PaymentMethods.PayPal]: (
      <img
        className={`${styles.paymentType} ${styles.paymentPaypal}`}
        src={darkTheme ? PayPalWhite : PayPal}
        alt="PayPal"
      />
    ),
    [PaymentMethods.ApplePay]: (
      <img
        className={`${styles.paymentType} ${styles.applePay}`}
        src={darkTheme ? ApplePayWhite : ApplePay}
        alt="ApplePay"
      />
    ),
  };

  return (
    <div
      className={`
      ${styles.container} 
      ${darkTheme ? styles.dark : ''}
    `}
    >
      <div
        className={`
          ${styles.buttonBackground} 
          ${options.indexOf(currentOption) === 0 ? styles.left : styles.right}
        `}
      />
      <div className={styles.buttons}>
        {options.map((option) => (
          <div
            key={option}
            className={`
              ${styles.button} 
              ${option === paymentMethod ? styles.active : ''} 
              ${option === PaymentMethods.Card ? styles.card : ''}
            `}
            onClick={() => {
              dispatch(setPaymentMethod(option));
              onChange(option);
            }}
          >
            {buttonContent[option]}
          </div>
        ))}
      </div>
    </div>
  );
};
