import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import Minimal from 'assets/gad/minimal.png';
import Mild from 'assets/gad/mild.png';
import Moderate from 'assets/gad/moderate.png';
import Severe from 'assets/gad/severe.png';
import { GadResult, AnxietyTypes } from './GadResult.types';

export const MappedGadAnswersWeight: Record<string, number> = {
  not_at_all: 0,
  several_days: 1,
  more_than_half_days: 2,
  nearly_every_day: 3,
};

export const GadQuestionIds: QuestionIds[] = [
  QuestionIds.GadQ1,
  QuestionIds.GadQ2,
  QuestionIds.GadQ3,
  QuestionIds.GadQ4,
  QuestionIds.GadQ5,
  QuestionIds.GadQ6,
  QuestionIds.GadQ7,
];

export const GadTestResults: Record<string, number> = {
  LowResult: 5,
  MildResult: 10,
  ModerateResult: 15,
};

export const GadResultData: Record<AnxietyTypes, GadResult> = {
  [AnxietyTypes.Minimal]: {
    title: 'GadResultMinimalAnxietyTitle',
    description: 'GadResultDescription1',
    imageSrc: Minimal,
  },
  [AnxietyTypes.Mild]: {
    title: 'GadResultMildAnxietyTitle',
    description: `GadResultDescription1`,
    imageSrc: Mild,
  },
  [AnxietyTypes.Moderate]: {
    title: 'GadResultModerateAnxietyTitle',
    description: `GadResultDescription2`,
    imageSrc: Moderate,
  },
  [AnxietyTypes.Severe]: {
    title: 'GadResultSevereAnxietyTitle',
    description: `GadResultDescription2`,
    imageSrc: Severe,
  },
};
