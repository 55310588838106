import React from 'react';
import LogoImage from 'assets/logo_loona.svg';

type Props = {
  className?: string;
};

export const Logo = ({ className = 'logo' }: Props) => {
  return <img className={className} src={LogoImage} alt="Loóna" />;
};
