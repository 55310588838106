import React from 'react';
import { useTranslation } from 'react-i18next';
import Stars from './Stars';
import './styles.scss';

type Props = {
  text: string;
  author: string;
  avatar: string;
  rating: number;
};

const Review = ({ text, author, avatar, rating }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="review">
      <div className="review-header">
        <img className="review-avatar" src={avatar} alt="" />
        <div className="review-author">
          <span className="review-title">
            <b>{author}</b> {t('reviewRecommends')} <b>Loóna</b>
          </span>
          <Stars rating={rating} />
        </div>
      </div>
      <div className="review-text">{text}</div>
    </div>
  );
};

export default Review;
