import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { pushWithSearchQuery } from 'utils';
import { WelcomeTemplate } from 'invitepurchase/components/welcometemplate/WelcomeTemplate';
import { Amplitude } from 'analytics/Amplitude';
import { ViewPIScreen, ContinuePIWelcome } from 'invitepurchase/analytics';
import styles from './styles.module.scss';

export const Welcome = () => {
  const dispatch = useAppDispatch();
  const { inviteCodeId } = useParams<{ inviteCodeId: string }>();

  useEffect(() => {
    Amplitude.setUserProperty('invite_code', inviteCodeId);
    appAnalytics.trackEvent(new ViewPIScreen('piWelcome'));
  }, []);

  const onContinue = () => {
    appAnalytics.trackEvent(new ContinuePIWelcome());
    dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/signin`));
  };

  return (
    <WelcomeTemplate>
      <div className={styles.title}>
        Claim your <span className={styles.accent}>free month</span>
        <br />
        of Loóna Plus
      </div>
      <ul className={styles.advantages}>
        <li>
          <span className={styles.accent}>Art activities</span> improve
          emotional
          <br /> and mental well-being
        </li>
        <li>
          <span className={styles.accent}>
            Cognitive behavioral therapy (CBT)
            <br />
          </span>{' '}
          helps to overcome negative thoughts
        </li>
        <li>
          <span className={styles.accent}>Calming music sounds</span> are
          effective <br /> for stress management
        </li>
      </ul>
      <button onClick={onContinue}>Claim your free month</button>
    </WelcomeTemplate>
  );
};
