import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/modal/Modal';
import styles from './styles.module.scss';

type Props = {
  isModalVisible: boolean;
  handleConfirm: () => void;
  closeModal: () => void;
};

const DefaultPromptModal = ({
  isModalVisible,
  handleConfirm,
  closeModal,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Modal visible={isModalVisible} className={styles.modal}>
      <div className={styles.prompt}>
        <div className={styles.title}>{t('routerPromptTitle')}</div>
        <div className={styles.description}>{t('routerPromptDescription')}</div>
        <div className={styles.buttonContainer}>
          <button className={styles.outlined} onClick={handleConfirm}>
            {t('Yes')}
          </button>
          <button onClick={closeModal}>{t('No')}</button>
        </div>
      </div>
    </Modal>
  );
};
export default DefaultPromptModal;
