import React from 'react';
import { EducationAnswer } from './EducationAnswer';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { ReactComponent as HintIcon } from 'assets/ic_hint.svg';
import { ReactComponent as CheckIcon } from 'assets/ic_check.svg';
import Spacer from 'components/spacer/Spacer';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import { useEducationAnswerLogic } from './EducationAnswer.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<EducationAnswer>;

export const EducationAnswerComponent: React.FC<Props> = React.memo(
  ({ question }: Props) => {
    const { isCorrectAnswer, title, description, nextHandler } =
      useEducationAnswerLogic(question);

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.iconWrapper}>
            <div className={styles.border}>
              {isCorrectAnswer ? (
                <CheckIcon className={styles.check} />
              ) : (
                <HintIcon />
              )}
            </div>
          </div>
          <h3
            className={`
              ${styles.title} 
              ${!isCorrectAnswer ? styles.accent : ''}
            `}
          >
            {title}
          </h3>
          <p className={styles.description}>{description}</p>
          <Spacer />
        </div>

        <StickyContainer>
          <button className={styles.button} onClick={nextHandler}>
            Next
          </button>
        </StickyContainer>
      </div>
    );
  },
);
