import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReviewStars } from 'assets/onboarding/review_stars.svg';
import { ReactComponent as Trustpilot } from 'assets/onboarding/trustpilot.svg';
import { ReviewsData } from '../Review.constants';
import styles from './styles.module.scss';

type Props = {
  gender: string;
};

export const MotivationReview: React.FC<Props> = ({ gender }: Props) => {
  const { t } = useTranslation();
  const { userInfo, date, reviewText } = ReviewsData[gender];

  return (
    <div
      className={`
        ${styles.review} 
        ${styles[gender]}
      `}
    >
      <div className={styles.userInfo}>{userInfo}</div>
      <div className={styles.reviewText}>{t(reviewText)}</div>
      <div className={styles.footer}>
        <div className={styles.icons}>
          <ReviewStars />
          <Trustpilot />
        </div>
        <div className={styles.date}>{date}</div>
      </div>
    </div>
  );
};
