import React from 'react';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { CustomQuestion } from '../Question';
import { ReactComponent as Checked } from 'assets/purple_check.svg';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import Spacer from 'components/spacer/Spacer';
import { useAppDispatch } from 'bootstrap/hooks';
import { appAnalytics } from 'analytics';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { SaveAnswer } from 'analytics/AllEvents';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<CustomQuestion>;

export const PersonalizationD3: React.FC<Props> = ({ question }: Props) => {
  const dispatch = useAppDispatch();

  const nextButtonHandler = () => {
    setTimeout(() => {
      appAnalytics.trackEvent(new SaveAnswer(question.id));
      dispatch(goToNextQuestion());
    }, 250);
  };

  return (
    <div className={`${styles.container} maxWidth`}>
      <h3 className={styles.title}>
        What is an <span className={styles.accent}>escape session</span>,
        anyway?
      </h3>
      <p className={styles.description}>
        During an escape, you will complete simple tasks guided by the
        narrator's soothing voice, and watch a fascinating story unfold before
        your very eyes.
      </p>
      <div className={styles.content}>
        <h3 className={styles.title}>How does that work</h3>
        <p className={styles.description}>
          Each escape session bundles together science-based relaxation
          techniques, such as guided meditation, art therapy, music therapy, and
          breathing exercises.
        </p>
        <h3 className={styles.title}>Escape session benefits</h3>
        <ul className={styles.benefitsList}>
          <li>
            <Checked className={styles.check} /> Lower stress level before sleep
          </li>
          <li>
            <Checked className={styles.check} /> Positive sleep association
          </li>
          <li>
            <Checked className={styles.check} /> Help to build healthy bedtime
            ritual
          </li>
        </ul>
        <Spacer />
        <StickyContainer className={styles.buttonContainer}>
          <button className={styles.button} onClick={nextButtonHandler}>
            Continue
          </button>
        </StickyContainer>
      </div>
    </div>
  );
};
