import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { StartRedeem } from './pages/start/StartRedeem';
import { RedeemAccount } from './pages/account/RedeemAccount';
import { RedeemCode } from './pages/code/RedeemCode';
import { RedeemDone } from './pages/done/RedeemDone';
import styles from './styles.module.scss';

export const Redeem = () => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);
    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  }, []);

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 1 }}
      atActive={{ opacity: 1 }}
      className={`switch-wrapper ${styles.dark}`}
    >
      <Route exact path="/redeem" component={StartRedeem} />
      <Route exact path="/redeem/account" component={RedeemAccount} />
      <Route exact path="/redeem/code" component={RedeemCode} />
      <Route exact path="/redeem/done" component={RedeemDone} />
    </AnimatedSwitch>
  );
};
