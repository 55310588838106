import { fetchServer } from '../commonnet';
import moment from 'moment';
import { ENDPOINTS } from '../endpoints';
import { ServerResponse } from '../commonnet.types';

export async function updateOnboardingInfo(info: any): Promise<void> {
  return fetchServer<ServerResponse>({
    url: ENDPOINTS.USER_DATA,
    method: 'POST',
    body: {
      data_batch: [
        {
          created_dt: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          type: 'onboarding',
          data: info,
        },
      ],
    },
  })
    .then((_) => {
      return Promise.resolve();
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
