import React from 'react';
import { AdvantageBox } from './components/box/AdvantageBox';
import Person from 'assets/preplan/person.svg';
import Activities from 'assets/preplan/activities.svg';
import ColorPreferences from 'assets/preplan/color_preferences.svg';
import NarrativePreferences from 'assets/preplan/narrative_preferences.svg';
import { useAdvantagesLogic } from './Advantages.hook';
import { ColorBox } from '../color/ColorBox';
import { useTranslation } from 'react-i18next';
import { PersonalizationBgColorsMap } from './Advantages.constants';
import styles from './styles.module.scss';

export const Advantages = () => {
  const { t } = useTranslation();

  const { personalization, narrativePreferences, colorPreferences } =
    useAdvantagesLogic();

  return (
    <div className={styles.advantagesWrapper}>
      {!!personalization.length && (
        <AdvantageBox title="Just for you" iconSrc={Person} className="person">
          <ul>
            {personalization.map((item, index) => {
              return <li key={index}>{item && t(item)}</li>;
            })}
          </ul>
        </AdvantageBox>
      )}
      <AdvantageBox
        title="250+ activities"
        iconSrc={Activities}
        className="activities"
      >
        <ul>
          <li>Guided sessions</li>
          <li>Breathing exercises</li>
          <li>Gentle alarm</li>
        </ul>
      </AdvantageBox>
      {!!narrativePreferences.length && (
        <AdvantageBox
          title="Narrative preferences"
          iconSrc={NarrativePreferences}
          className="narrative_preferences"
        >
          <ul>
            {narrativePreferences.slice(0, 3).map((item, index) => {
              return (
                <li key={index}>
                  {item &&
                    `${t(item)} ${
                      index === 2 && narrativePreferences.length > 3
                        ? ` & ${narrativePreferences.length - 3} more`
                        : ''
                    }`}
                </li>
              );
            })}
          </ul>
        </AdvantageBox>
      )}
      {!!colorPreferences.length && (
        <AdvantageBox
          title="Color preferences"
          iconSrc={ColorPreferences}
          className="color_preferences"
        >
          <div className={styles.colorsWrapper}>
            {colorPreferences.map((item, index) => {
              return (
                <ColorBox
                  key={index}
                  bgColor={item && PersonalizationBgColorsMap[item as string]}
                  leftPos={index * 25}
                />
              );
            })}
          </div>
        </AdvantageBox>
      )}
    </div>
  );
};
