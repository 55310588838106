import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  improvementPercent: number;
};

export const GraphsContainer = ({ improvementPercent }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.graphsContainer}>
      <div className={styles.graph}>
        <div className={styles.trialGraph} />
        <span className={styles.caption}>{t('graphCaption1')}</span>
      </div>

      <div className={styles.graph}>
        <div className={styles.skipTrialGraph}>
          <span className={styles.percent}>+{improvementPercent}%</span>
        </div>
        <span className={styles.caption}>{t('graphCaption2')}</span>
      </div>
    </div>
  );
};
