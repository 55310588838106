import { Event } from 'analytics/Event';
import { Events } from 'analytics/Events';

export class GiftScreenOpened extends Event {
  constructor(screenName: string) {
    super(Events.GiftScreenOpened, { screenName });
  }
}

export class GiftProductSelected extends Event {
  constructor(productId: string) {
    super(Events.GiftProductSelected, { productId });
  }
}

export class GiftAmountSelected extends Event {
  constructor(quantity: number) {
    super(Events.GiftAmountSelected, { quantity });
  }
}

export class GiftPurchaseSuccess extends Event {
  constructor(price: number, productId: string, quantity: number) {
    super(Events.GiftPurchaseSuccess, {
      price,
      productId,
      $quantity: quantity,
      $revenue: quantity * price,
    });
  }
}

export class PayPalClicked extends Event {
  constructor() {
    super(Events.PayPalClicked);
  }
}

export class CardFormInputStarted extends Event {
  constructor() {
    super(Events.CardFormInputStarted);
  }
}
