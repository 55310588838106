import React from 'react';
import { useAppSelector } from 'bootstrap/hooks';
import { getQuestionComponent } from 'cancelsubscription/utils';
import { OptionalQuestion } from './OptionQuestion';
import { QuestionComponentProps } from '../Question.types';

type Props = QuestionComponentProps<OptionalQuestion>;

export const OptionalComponent: React.FC<Props> = ({ question }: Props) => {
  const { answers } = useAppSelector((state) => state.subscriptionCancel);
  const answer = answers[question.sourceQuestionId];
  const finalQuestion =
    question.options[answer as string] ?? Object.values(question.options)[0];

  const Component = getQuestionComponent(finalQuestion);
  return <Component question={finalQuestion} />;
};
