import React from 'react';
import { QuestionIds } from '../data/constants/QuestionIds';
import { CustomQuestion, Question, QuestionTypes } from './Question';
import { AboutYouProof } from './aboutyouproof/AboutYouProof';
import { AnalysisLoader } from './analysisloader/AnalysisLoader';
import { ColorQuestionComponent } from './colors/ColorQuestionComponent';
import { ImageQuestionComponent } from './image/ImageQuestionComponent';
import { ImageLoader } from './imageloader/ImageLoader';
import { InfoComponent } from './info/InfoComponent';
import { OptionalComponent } from './info/OptionalComponent';
import { YoYoGraph } from './yoyo/YoYoGraph';
import { Motivation } from './motivation/Motivation';
import { MeqResults } from './meqresults/MeqResults';
import { OptionQuestionComponent } from './options/OptionQuestionComponent';
import { ProductivityGraph } from './productivitygraph/ProductivityGraph';
import { StatementQuestionComponent } from './statement/StatementQuestionComponent';
import { GadResults } from './gadresults/GadResults';
import { PlaceQuestionComponent } from './places/PlaceComponents';
import { QuestionComponentProps } from './QuestionComponent.types';
import { Personalization } from './personalization/Personalization';
import { MeqResultsV2 } from './meqresults/meqresultsv2/MeqResultsV2';
import { isMeqResultAnimalFlow, isMeqResultV2Flow } from 'navigation';
import { PersonalizationD3 } from './personalizationd3/PersonalizationD3';
import { EducationAnswerComponent } from './eduanswer/EducationAnswerComponent';
import { InsomniaResults } from './insomniaresults/InsomniaResults';

export const getQuestionComponent = (
  question: Question,
): React.FC<QuestionComponentProps<any>> => {
  switch (question.type) {
    case QuestionTypes.Optional:
      return OptionalComponent;
    case QuestionTypes.Option:
      return OptionQuestionComponent;
    case QuestionTypes.Color:
      return ColorQuestionComponent;
    case QuestionTypes.Places:
      return PlaceQuestionComponent;
    case QuestionTypes.Info:
      return InfoComponent;
    case QuestionTypes.Statement:
      return StatementQuestionComponent;
    case QuestionTypes.Image:
      return ImageQuestionComponent;
    case QuestionTypes.EduAnswer:
      return EducationAnswerComponent;
    case QuestionTypes.Custom:
      switch ((question as CustomQuestion).variationId) {
        case QuestionIds.AboutYouProof1:
          return AboutYouProof;
        case QuestionIds.SleepAndActivityProof1:
          return YoYoGraph;
        case QuestionIds.SleepAndActivityProof2:
          return Motivation;
        case QuestionIds.SleepAndActivityLoader:
          return ImageLoader;
        case QuestionIds.PersonalizationProof1:
          return ProductivityGraph;
        case QuestionIds.PersonalizationLoader:
          return AnalysisLoader;
        case QuestionIds.PersonalizationD3:
          return PersonalizationD3;
        case QuestionIds.MeqResults:
          return isMeqResultV2Flow() || isMeqResultAnimalFlow()
            ? MeqResultsV2
            : MeqResults;
        case QuestionIds.IsiD1:
          return InsomniaResults;
        case QuestionIds.GadResults:
          return GadResults;
        case QuestionIds.PersonalizationD1:
          return Personalization;
        default:
          return InfoComponent;
      }
    case QuestionTypes.Blitz:
      return InfoComponent;
  }
};
