import { useAppSelector } from 'bootstrap/hooks';
import { hasAllRmeqTestAnswers } from 'bootstrap/onboarding/actions';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import {
  getCircadianType,
  getRmeqTestResult,
} from 'onboarding/question/meqresults/MeqResults.hook';

export const useAdvantagesLogic = () => {
  const { answers } = useAppSelector((state) => state.onboarding);

  return {
    personalization: [
      answers[QuestionIds.AboutYouGender],
      answers[QuestionIds.AboutYouAge],
      answers[QuestionIds.AboutYouActivity],
      hasAllRmeqTestAnswers(answers)
        ? getCircadianType(getRmeqTestResult(answers))
        : '',
    ]
      .filter(Boolean)
      .filter((item) => item !== 'none'),
    narrativePreferences: answers[QuestionIds.PersonalizationNarrative]
      ? [
          ...(answers[
            QuestionIds.PersonalizationNarrative
          ] as Array<QuestionIds>),
        ].filter(Boolean)
      : [],
    colorPreferences: [
      answers[QuestionIds.PersonalizationColors1],
      answers[QuestionIds.PersonalizationColors2],
      answers[QuestionIds.PersonalizationColors3],
      answers[QuestionIds.PersonalizationColors4],
    ].filter(Boolean),
  };
};
