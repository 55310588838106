import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { Answers } from 'bootstrap/onboarding/state';
import { useSubmit } from 'hooks/useSubmit';
import { SyntheticEvent, useEffect, useState } from 'react';
import { pushWithSearchQuery } from 'utils';
import {
  GadResultData,
  MappedGadAnswersWeight,
  GadTestResults,
} from './GadResult.constants';
import { AnxietyTypes, GadResult } from './GadResult.types';

interface Props {
  gadTestResult: GadResult;
  nextButtonHandler: (args?: any) => void;
}

export const useGadTest = (): Props => {
  const dispatch = useAppDispatch();

  const { answers } = useAppSelector((state) => state.onboarding);
  const [result, setResult] = useState(0);

  const nextButtonHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    dispatch(pushWithSearchQuery('/done'));
  });

  useEffect(() => {
    setResult(getGadTestResult(answers));
  }, [answers]);

  return {
    gadTestResult: GadResultData[getAnxietyType(result)],
    nextButtonHandler,
  };
};

export const getAnxietyType = (testResult: number): AnxietyTypes => {
  const { LowResult, MildResult, ModerateResult } = GadTestResults;

  if (testResult <= LowResult) {
    return AnxietyTypes.Minimal;
  } else if (testResult <= MildResult) {
    return AnxietyTypes.Mild;
  } else if (testResult <= ModerateResult) {
    return AnxietyTypes.Moderate;
  } else {
    return AnxietyTypes.Severe;
  }
};

export const getGadTestResult = (answers: Answers): number => {
  const result = Object.values(answers)
    .filter((answer) => answer && typeof answer === 'string')
    .map((answer) => MappedGadAnswersWeight[answer as string] || 0)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  return result;
};
