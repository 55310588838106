import { RefObject, useEffect, useState } from 'react';

type Props = {
  startDate: number;
  timerBox: RefObject<HTMLDivElement>;
};

const TIMER_INITIAL_VALUE = 15 * 60;

export const useStickyHeader = ({ startDate, timerBox }: Props) => {
  const [isSticky, setSticky] = useState(false);
  const [period, setPeriod] = useState(TIMER_INITIAL_VALUE);

  useEffect(() => {
    const handleScroll = () => {
      if (timerBox.current) {
        const rect = timerBox.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Show sticky header when the element is no longer visible (its bottom is above the viewport)
        if (rect.bottom <= windowHeight) {
          setSticky(true);
        }

        // Hide sticky header when the user scrolls back near the top (20px from the top)
        if (window.scrollY < 20) {
          setSticky(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    const timerId = setInterval(() => {
      const diff = TIMER_INITIAL_VALUE - (Date.now() - startDate) / 1000;
      if (diff < 0) {
        clearInterval(timerId);
      } else {
        setPeriod(diff);
      }
    }, 1000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(timerId);
    };
  }, [startDate, timerBox]);

  const getTime = () => {
    const minutes = Math.floor(period / 60);
    const seconds = Math.floor(period - minutes * 60);

    if (minutes === 0 && seconds === 0) {
      return 0;
    }
    return `${minutes}:${seconds >= 10 ? '' : '0'}${seconds}`;
  };

  return {
    isSticky,
    time: getTime(),
    timerMinutes: Math.floor(TIMER_INITIAL_VALUE / 60),
  };
};
