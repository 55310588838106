import React, { ChangeEvent } from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { PasswordInput } from 'components/passwordinput/PasswordInput';
import Disclaimer from '../../../components/disclaimer/Disclaimer';
import Delete from 'assets/ic_delete.png';
import BottomInputContainer from '../bottominputcontainer/BottomInputContainer';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  countryCode?: string;
  emailError: string;
  email: string;
  emailFocused: boolean;
  passwordError: string;
  password: string;
  isLoading: boolean;
  isPasswordVisible: boolean;
  isRestrictMailing?: boolean;
  handleSubmit: () => void;
  onEmailFocusHandler: () => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEmailChange: (email: string) => void;
  onEmailBlurHandler: () => void;
  onHandleCheckboxClick: () => void;
};

const SignInForm = ({
  countryCode,
  emailError,
  email,
  emailFocused,
  passwordError,
  password,
  isLoading,
  isPasswordVisible,
  isRestrictMailing,
  handleSubmit,
  onEmailFocusHandler,
  onPasswordChange,
  onEmailChange,
  onEmailBlurHandler,
  onHandleCheckboxClick,
}: Props) => {
  const { t } = useTranslation();

  const isInvalidEmail = emailError !== '' && email !== '';

  return (
    <form className={styles.signInForm} onSubmit={handleSubmit} noValidate>
      <div className={styles.inputContainer}>
        <input
          type="email"
          className={isInvalidEmail ? styles.invalid : ''}
          value={email}
          placeholder={t('emailPlaceholder')}
          onFocus={onEmailFocusHandler}
          onBlur={onEmailBlurHandler}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onEmailChange(e.target.value);
          }}
        />

        {(emailFocused || email.length > 0) && (
          <img
            src={Delete}
            alt="x"
            className={styles.delete}
            onClick={() => onEmailChange('')}
          />
        )}
      </div>

      <div className={styles.signInError}>{emailError}</div>

      <BottomInputContainer
        countryCode={countryCode}
        isRestrictMailing={isRestrictMailing}
        onHandleCheckboxClick={onHandleCheckboxClick}
      />

      {isPasswordVisible && (
        <>
          <PasswordInput
            value={password}
            isValid={!passwordError}
            onChange={onPasswordChange}
          />
          <div className={styles.signInError}>{passwordError}</div>
        </>
      )}

      <LoadingButton
        disabled={
          email === '' ||
          emailError !== '' ||
          (isPasswordVisible && (password === '' || passwordError !== ''))
        }
        isLoading={isLoading}
        label={t('signInFormLabel')}
        onClick={handleSubmit}
      />

      <Disclaimer />
    </form>
  );
};

export default SignInForm;
