import { appAnalytics } from 'analytics';
import { ContinueWelcome, ScreenOpened } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { SyntheticEvent, useEffect } from 'react';

export const useDefaultWelcomeLogic = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    appAnalytics.trackEvent(new ScreenOpened('onboardingWelcome'));
  }, []);

  const handleReduceStress = (e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new ContinueWelcome('reduceStress'));
    dispatch(setAnswer(QuestionIds.Welcome, 'stress'));
  };

  const handleSleepBetter = (e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new ContinueWelcome('sleepBetter'));
    dispatch(setAnswer(QuestionIds.Welcome, 'sleep'));
  };

  return {
    handleReduceStress,
    handleSleepBetter,
  };
};
