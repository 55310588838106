import React from 'react';
import { SuccessTemplate } from 'onboarding/components/successtemplate/SuccessTemplate';
import { useTranslation } from 'react-i18next';
import { useCongratsLogic } from 'success/congrats/Congrats.hook';
import styles from './styles.module.scss';

export const Congrats = () => {
  const { t } = useTranslation();
  const { oldUser, handleButtonClick } = useCongratsLogic();

  return (
    <SuccessTemplate
      title={t('successTemplateTitle')}
      description={
        <>
          {oldUser
            ? t('successTemplateDescription')
            : t('successTemplateDescription2')}
          <br />
          of Loóna <span className={styles.plus}>PLUS</span>
        </>
      }
      onButtonClick={handleButtonClick}
      button={
        oldUser
          ? t('successTemplateContinueButton')
          : t('successTemplateInstallButton')
      }
    />
  );
};
