import React, { FC } from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { useTranslation } from 'react-i18next';
import styles from '../default/styles.module.scss';

interface PaymentButtonProps {
  isLoading: boolean;
}

export const PayPalElement: FC<
  PaymentButtonProps & { onContinue: () => void }
> = ({ isLoading, onContinue }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ul className={styles.checkList}>
        <li>Fast checkout</li>
        <li>Protects your financial data</li>
        <li>Secure transaction</li>
      </ul>
      <LoadingButton
        isLoading={isLoading}
        label={t('continueSecurely')}
        onClick={onContinue}
        className={styles.paypalBtn}
      />
    </div>
  );
};
