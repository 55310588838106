const API = process.env.REACT_APP_BACKEND_URL + '/api';

export const ENDPOINTS = {
  PRODUCTS: `${API}/payments/plans`,
  SOCIAL_REG: {
    facebook: `${API}/soc-auth/register/facebook`,
    google: `${API}/soc-auth/register/google`,
  },
  USER_INFO: `${API}/v1/user/info`,
  USER_PURCHASES: `${API}/user/purchase`,
  EMAIL_REG: `${API}/auth/register`,
  EMAIL_LOGIN: `${API}/auth/login`,
  CREATE_SUBSCRIPTION: `${API}/payments/create-subscription`,
  CREATE_SUBSCRIPTION_BY_IDX: `${API}/payments/create-subscription-no-auth`,
  CHANGE_SUBSCRIPTION: `${API}/payments/subscription`,
  CHANGE_SUBSCRIPTION_V1: `${API}/v1/payments/subscription`,
  USER_DATA: `${API}/user/data-batch`,
  NO_PASSWORD_REGISTER: `${API}/v1/auth/register-no-password`,
  BUNDLE: `${API}/payments/bundle-plan`,
  CHECK_TOKEN: `${API}/auth/jwt-check`,
  GEO_INFO: `${API}/system/geo-info`,
  GIFT_CARDS_LIST: `${API}/payments/gift-cards/list`,
  BUY_GIFT_CARD: `${API}/payments/gift-cards/create`,
  ACTIVATE_GIFT_CARD: `${API}/payments/gift-cards/activate`,
  SOCIAL_LOGIN: {
    facebook: `${API}/soc-auth/login/facebook`,
  },
};
