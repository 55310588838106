import { QuestionIds } from '../constants/QuestionIds';
import { Steps } from '../constants/StepsLabels';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const AdhdSteps: Map<Steps, QuestionIds[]> = new Map([
  [
    Steps.Sleep,
    [
      QuestionIds.RMeqQ1,
      QuestionIds.RMeqQ2,
      QuestionIds.RMeqQ3V2,
      QuestionIds.RMeqQ4,
      QuestionIds.RMeqQ3,
      QuestionIds.RMeqQ6,
      QuestionIds.RMeqQ5,
      QuestionIds.AboutYouProof1,
    ],
  ],
  [
    Steps.AboutYou,
    [
      QuestionIds.AboutYouGender,
      QuestionIds.AboutYouAge,
      QuestionIds.AboutYouRoutine,
      QuestionIds.AboutYouKids,
      QuestionIds.AboutYouActivity,
      QuestionIds.SleepAndActivityProof2,
      QuestionIds.SleepAndActivityCoffee,
      QuestionIds.SleepAndActivityDinner,
      QuestionIds.SleepAndActivityAfterMidnight,
      QuestionIds.SleepAndActivityLessThanEightHours,
      QuestionIds.SleepAndActivityExtraMotivation,
    ],
  ],
  [Steps.Personalization, PersonalizationSectionQuestionIds],
]);

export const DisclaimerWithStepperFlowBasicSteps: Map<Steps, QuestionIds[]> =
  new Map([
    [
      Steps.Sleep,
      [
        QuestionIds.RMeqQ1,
        QuestionIds.RMeqQ2,
        QuestionIds.RMeqQ3,
        QuestionIds.RMeqQ4,
        QuestionIds.RMeqQ5,
        QuestionIds.AboutYouProof1,
        QuestionIds.SleepAndActivityD1,
      ],
    ],
    [
      Steps.AboutYou,
      [
        QuestionIds.AboutYouGender,
        QuestionIds.AboutYouAge,
        QuestionIds.AboutYouRoutine,
        QuestionIds.AboutYouD2,
        QuestionIds.AboutYouKids,
        QuestionIds.AboutYouD3,
        QuestionIds.AboutYouActivity,
        QuestionIds.AboutYouD4,
        QuestionIds.SleepAndActivityProof2,
        QuestionIds.SleepAndActivityCoffee,
        QuestionIds.SleepAndActivityDinner,
        QuestionIds.SleepAndActivityAfterMidnight,
        QuestionIds.SleepAndActivityLessThanEightHours,
        QuestionIds.SleepAndActivityExtraMotivation,
        QuestionIds.SleepAndActivityD2,
        QuestionIds.PersonalizationD1,
      ],
    ],
    [Steps.Personalization, PersonalizationSectionQuestionIds],
  ]);
