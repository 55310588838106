import React from 'react';
import { DotComponent } from './components/dot';
import { BaseLine, LineComponent } from './components/line';
import { useStepperLogic } from './hooks/useStepper';
import styles from './styles.module.scss';

type Props = {
  className: string;
  stepName: string;
  currentStep: number;
  stepsTotal: number;
  currentSubstep: number;
  substepsTotal: number;
};

export const Stepper: React.FC<Props> = ({
  className,
  stepName,
  currentStep,
  stepsTotal,
  currentSubstep,
  substepsTotal,
}: Props) => {
  const { steps } = useStepperLogic({
    stepName,
    currentStep,
    stepsTotal,
    currentSubstep,
    substepsTotal,
  });

  return (
    <div
      className={`
        ${styles.container} 
        ${className} 
        maxWidth
      `}
    >
      {steps.map((step, index) =>
        step instanceof BaseLine ? (
          <LineComponent
            key={`line-${index}`}
            item={step}
            className={className}
          />
        ) : (
          <DotComponent
            key={`dot-${index}`}
            item={step}
            className={className}
          />
        ),
      )}
    </div>
  );
};
