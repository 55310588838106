import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  className?: string;
};

export const Information = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="info">
        <b>{t('paymentInformationLine1')}</b>
        <br />
        {t('paymentInformationLine2')}
      </div>
      <div className="info">
        <b>{t('paymentInformationLine3')}</b>
        <br />
        {t('paymentInformationLine4')}
      </div>
      <div className="info">
        <b> {t('paymentInformationLine5')}</b>
        <br />
        <a href="mailto:hello@loona.app">{t('paymentInformationLine6')}</a>.
      </div>
    </div>
  );
};
