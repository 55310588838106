export enum InsomniaTypes {
  Minimal = 'Minimal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
}

export interface InsomniaResult {
  description: string;
}
