import React from 'react';
import EmptyStarIcon from 'assets/icon_star_empty.svg';
import StarIcon from 'assets/icon_star.svg';

type Props = {
  rating: number;
};

const MAX_RATING = 5;

const Stars = ({ rating }: Props) => (
  <div className="stars">
    {[...Array(MAX_RATING)].map((_, index) => (
      <Star
        key={index}
        fill={rating >= index + 1 ? 100 : (rating - index) * 100}
      />
    ))}
    <span>{rating.toFixed(1)}</span>
  </div>
);

type StarProps = {
  fill: number;
};

const Star = ({ fill }: StarProps) => {
  if (fill === 100) {
    return <img className="star" src={StarIcon} alt="" />;
  } else {
    return (
      <div className="star-container">
        <img className="star" src={EmptyStarIcon} alt="" />
        <div className="cropped-star" style={{ width: `${fill}%` }}>
          <img className="star" src={StarIcon} alt="" />
        </div>
      </div>
    );
  }
};

export default Stars;
