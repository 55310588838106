import { appAnalytics } from 'analytics';
import { useAppSelector } from 'bootstrap/hooks';
import { GiftScreenOpened } from 'giftcards/analytics';
import { useEffect, useState } from 'react';
import { Errors } from './Success.constants';

export const useSuccessLogic = () => {
  const { link, giftCardType } = useAppSelector((state) => state.purchase);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<Errors>(Errors.NoError);

  useEffect(() => {
    appAnalytics.trackEvent(new GiftScreenOpened('giftCongratsScreen'));
  }, []);

  const checkLink = async (url: string) => (await fetch(url)).ok;

  const setErrorHandler = (error: Errors) => {
    setError(error);
  };

  const handleDownloadDoc = () => {
    if (!link) {
      setError(Errors.NoLinkError);
      return;
    }
    setLoading(true);
    checkingUrl(link);
  };

  const checkingUrl = (url: string, retryCount = 0) => {
    if (retryCount > 9) {
      setError(Errors.DownloadError);
      setLoading(false);
    } else {
      checkLink(url)
        .then((isLinkCorrect) => {
          if (isLinkCorrect) {
            setLoading(false);
            const linkElement = document.createElement('a');
            linkElement.href = url;
            linkElement.setAttribute('download', 'Loona Gift Card.pdf');
            document.body.appendChild(linkElement);
            linkElement.click();
            linkElement.parentNode?.removeChild(linkElement);
          } else {
            setTimeout(() => checkingUrl(url, retryCount + 1), 1000);
          }
        })
        .catch((_) => {
          setTimeout(() => checkingUrl(url, retryCount + 1), 1000);
        });
    }
  };

  return {
    giftCardType,
    error,
    isLoading,
    setErrorHandler,
    handleDownloadDoc,
  };
};
