import { Event } from 'analytics/Event';
import { Events } from 'analytics/Events';

export class ViewPIScreen extends Event {
  constructor(screenName: string) {
    super(Events.ViewPIScreen, { screenName });
  }
}

export class ContinuePIWelcome extends Event {
  constructor() {
    super(Events.ContinuePIWelcome);
  }
}
