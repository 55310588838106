import React from 'react';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

export const StickyContainer = ({ children, className }: Props) => {
  return (
    <div className={`${styles.stickyContainer} ${className}`}>{children}</div>
  );
};
