import { QuestionIds } from '../constants/QuestionIds';

export const DefaultQuestionIds: QuestionIds[] = [
  QuestionIds.PersonalizationColors1,
  QuestionIds.PersonalizationColors2,
  QuestionIds.PersonalizationColors3,
  QuestionIds.PersonalizationColors4,
  QuestionIds.PersonalizationProof1,
  QuestionIds.PersonalizationArtwork1,
  QuestionIds.PersonalizationArtwork2,
  QuestionIds.PersonalizationArtwork3,
  QuestionIds.PersonalizationArtwork4,
  QuestionIds.PersonalizationNarrative,
  QuestionIds.PersonalizationD3,
  QuestionIds.PersonalizationLoader,
];
