import { getAcceptLanguageHeader } from 'utils/utils';
import { ServerRequest, ServerResponse } from './commonnet.types';

const getToken = (): string => localStorage.getItem('auth_token') || '';

export async function fetchServer<T extends ServerResponse>({
  url,
  method = 'GET',
  body = null,
  retries = 0,
  token,
}: ServerRequest): Promise<T> {
  return fetch(url, {
    method: method,
    headers: {
      'Accept-Language': getAcceptLanguageHeader() || 'en-US',
      'Content-Type': body ? 'application/json' : '',
      Authorization: token ? token : getToken(),
    },
    body: body && JSON.stringify(body),
  })
    .then((response) => {
      if (response.ok) {
        return response.json().then((result) => {
          if (Object.keys(result).length === 0) {
            return Promise.resolve({
              error: {
                code: 0,
                debug_text: 'NONE',
                description: 'NONE',
              },
              response: {},
            });
          }
          if (result.hasOwnProperty('response')) {
            return Promise.resolve(result);
          } else {
            return Promise.reject(result);
          }
        });
      } else {
        return Promise.reject('Something went wrong. Please try again');
      }
    })
    .catch((reason) => {
      if (retries > 0) {
        return fetchServer({
          url,
          method,
          body,
          retries: retries - 1,
        });
      } else {
        return Promise.reject(
          typeof reason === 'string' ? reason : getErrorMessage(reason),
        );
      }
    });
}

export const getErrorMessage = (reason: any) => {
  let message = '';
  if (typeof reason.debug_text === 'string') {
    message = reason.debug_text;
  } else if (typeof reason.debug_text === 'object') {
    message = reason.debug_text.message;
  } else if (!!reason && Object.keys(reason).length > 0) {
    message = JSON.stringify(reason);
  }
  return message || 'Something went wrong. Please try again';
};

export const getError = (error: any) => {
  return {
    ...error,
    message: getErrorMessage(error),
  };
};

export async function toResultPromise<T>(
  result: any,
  value?: T | Promise<T>,
): Promise<any> {
  if (result.error.code === 0) {
    return value ? Promise.resolve(value) : Promise.resolve();
  }
  return Promise.reject(getError(result.error));
}

export async function toSubscriptionResultResolve(result: any): Promise<any> {
  switch (result.error.code) {
    case 0:
    case 522: {
      return Promise.resolve(result.response);
    }
    case 409: {
      return Promise.reject({
        debug_text:
          'Already a member of Loóna Plus. You can only have one active Loóna Plus subscription.',
        code: 'DATA_EXIST',
      });
    }
    case 401:
    case 404: {
      return Promise.reject({
        ...result.error,
        code: 'USER_NOT_FOUND',
      });
    }
    default: {
      return Promise.reject(result.error);
    }
  }
}
