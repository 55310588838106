import { CommonElementStyle } from '@recurly/recurly-js/lib/elements';

const DEFAULT_COLOR = 'rgba(9, 1, 16, 1)';
const DEFAULT_COLOR_DARK = 'rgba(255, 255, 255, 1)';
const ERROR_COLOR = 'rgba(227, 70, 70, 1)';
const DEFAULT_COLOR_WITH_OPACITY = 'rgba(9, 1, 16, 0.5)';
const DEFAULT_COLOR_WITH_OPACITY_DARK = 'rgba(255, 255, 255, 0.5)';
const ERROR_COLOR_WITH_OPACITY = 'rgba(227, 70, 70, 0.5)';

const cvv = 'CVC/CVV';

type Style = {
  empty: RecurlyStyle;
  default: RecurlyStyle;
  invalid: RecurlyStyle;
};

type RecurlyStyle = CommonElementStyle & {
  invalid?: CommonElementStyle;
  padding?: string;
  placeholder?: {
    color?: string;
    content?: string;
  };
};

const getStyle = (
  placeholder: string,
  isValid: boolean,
  isEmpty: boolean,
  isDark: boolean,
) => {
  return {
    fontFamily: 'Karla',
    fontSize: '16px',
    fontColor: isValid
      ? isDark
        ? DEFAULT_COLOR_DARK
        : DEFAULT_COLOR
      : ERROR_COLOR,
    fontWeight: isEmpty ? '500' : '600',
    invalid: {
      fontColor: ERROR_COLOR,
    },
    placeholder: {
      color: isValid
        ? isDark
          ? DEFAULT_COLOR_WITH_OPACITY_DARK
          : DEFAULT_COLOR_WITH_OPACITY
        : ERROR_COLOR_WITH_OPACITY,
      content: placeholder,
    },
  };
};

export const ELEMENTS_STYLES: Record<string, Style> = {
  cardNumber: {
    empty: getStyle('0000 0000 0000 0000', true, true, false),
    default: getStyle('0000 0000 0000 0000', true, false, false),
    invalid: getStyle('0000 0000 0000 0000', false, false, false),
  },
  month: {
    empty: getStyle('MM', true, true, false),
    default: getStyle('MM', true, false, false),
    invalid: getStyle('MM', false, false, false),
  },
  year: {
    empty: getStyle('YY', true, true, false),
    default: getStyle('YY', true, false, false),
    invalid: getStyle('YY', false, false, false),
  },
  cvv: {
    empty: getStyle(cvv, true, true, false),
    default: getStyle(cvv, true, false, false),
    invalid: getStyle(cvv, false, false, false),
  },
};

export const ELEMENTS_STYLES_DARK: Record<string, Style> = {
  cardNumber: {
    empty: getStyle('0000 0000 0000 0000', true, true, true),
    default: getStyle('0000 0000 0000 0000', true, false, true),
    invalid: getStyle('0000 0000 0000 0000', false, false, true),
  },
  month: {
    empty: getStyle('MM', true, true, true),
    default: getStyle('MM', true, false, true),
    invalid: getStyle('MM', false, false, true),
  },
  year: {
    empty: getStyle('YY', true, true, true),
    default: getStyle('YY', true, false, true),
    invalid: getStyle('YY', false, false, true),
  },
  cvv: {
    empty: getStyle(cvv, true, true, true),
    default: getStyle(cvv, true, false, true),
    invalid: getStyle(cvv, false, false, true),
  },
};
