import { PurchaseActionTypes } from './types';

export const SAVE_LINK = 'SAVE_LINK';

export interface SaveLink {
  type: typeof SAVE_LINK;
  payload: {
    link: string;
  };
}

export function saveLink(link: string): PurchaseActionTypes {
  return {
    type: SAVE_LINK,
    payload: {
      link: link,
    },
  };
}
