import { PurchaseActionTypes } from './types';

export const SET_QUANTITY = 'SET_QUANTITY';

export interface SetQuantity {
  type: typeof SET_QUANTITY;
  payload: {
    quantity: number;
  };
}

export function setQuantity(quantity: number): PurchaseActionTypes {
  return {
    type: SET_QUANTITY,
    payload: {
      quantity: quantity,
    },
  };
}
