import React from 'react';
import HintIcon from 'assets/onboarding/ic_hint.svg';
import { Info } from './Info';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { useInfoComponentLogic } from './InfoComponent.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<Info>;

export const InfoComponent: React.FC<Props> = React.memo(
  ({ question }: Props) => {
    const { t } = useTranslation();

    const { title, description, containerStyle, nextHandler } =
      useInfoComponentLogic(question);

    return (
      <div
        className={`
          ${styles.container} 
          ${styles[containerStyle]}
          ${styles.speed}
        `}
      >
        <div className={styles.fog} />
        <div className={styles.innerContainer}>
          <div className={styles.topSpacer} />
          <div className={styles.background}>
            <div className={styles.icon}>
              <img src={HintIcon} alt="" />
            </div>
            {title && <h2 className={styles.title}>{t(title)}</h2>}
            {description && (
              <p className={styles.description}>{t(description)}</p>
            )}
            <Spacer />
          </div>
          <button className={styles.button} onClick={nextHandler}>
            {t(question.buttonText)}
          </button>
        </div>
      </div>
    );
  },
);
