import React, { useEffect, useState } from 'react';
import Stars from 'components/stars/Stars';
import styles from './styles.module.scss';

const REVIEWS = [
  {
    text: '“Falling asleep like a baby. The app’s a blessing. Thank you! 🙏🏻❤️”️',
    signature: 'Mia, 26 from Seattle',
  },
  {
    text: 'I have suffered with anxiety and insomnia for years. it’s worth every penny!',
    signature: 'Patricia, 37 from Houston',
  },
  {
    text: 'As someone who has always struggled to sleep, and has severe anxiety, this is a life saver.',
    signature: 'Linda, 41 from Galena',
  },
  {
    text: 'I suggest it for anyone that is needing something to calm their mind before bed.',
    signature: 'Barbara, 39 from Beaufort',
  },
  {
    text: 'This is one of the only things that helps me sleep. I love it so much!',
    signature: 'Sandra, 26 from Nashville',
  },
  {
    text: 'So i’ve been having a lot of trouble falling asleep and staying asleep and this is helping a lot!!',
    signature: 'Sarah, 26 from Camden',
  },
];

export const ReviewCarousel = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [isInTransition, setIsInTransition] = useState(false);

  useEffect(() => {
    const startTime = Date.now();
    const reviewDelay = 4000;
    const transitionStart = 500;

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      setCurrentReviewIndex(
        Math.floor((elapsedTime / reviewDelay) % REVIEWS.length),
      );
      if (elapsedTime % reviewDelay >= reviewDelay - transitionStart) {
        setIsInTransition(true);
      } else {
        setIsInTransition(false);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  const review = REVIEWS[currentReviewIndex];
  return (
    <div
      className={`${styles.container} ${
        isInTransition ? styles.transitionOut : styles.transitionIn
      }`}
    >
      <Stars />
      <div className={styles.text}>{review.text}</div>
      <div className={styles.signature}>{review.signature}</div>
    </div>
  );
};
