import { Analytics } from './Analytics';
import { Event } from './Event';

export class DummyAnalytics extends Analytics {
  trackEvent(event: Event) {
    console.log(
      'Tracking event',
      event.name,
      event.params,
      event.optionalParams,
    );
  }
}
