import React from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { ThreeDSecure } from '../../threedsecure/ThreeDSecure';
import { Product } from 'bootstrap/data/product/Product';
import Alert from 'components/error/Error';
import { SuccessPaymentProps } from 'subscription/recurly/Recurly.types';
import { InputField } from './components/inputfield/InputField';
import { useLayoutCardFormLogic } from './LayoutCardForm.hook';
import { CardElementsField } from './components/cardelementfield/CardElementField';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  className?: string;
  children?: JSX.Element | JSX.Element[];
  onSuccess: (params: SuccessPaymentProps) => void;
  onError: (message: string) => void;
};

export const LayoutCardForm = ({
  product,
  className,
  children,
  onSuccess,
  onError,
}: Props) => {
  const {
    error,
    postalCodeRef,
    formRef,
    actionTokenId,
    fullName,
    firstName,
    lastName,
    cardElementValidationError,
    fullNameValidationError,
    postalCodeValidationError,
    isPaymentFormInvalid,
    isLoading,
    isPostalCodeVisible,
    handleThreeDSecureError,
    setError,
    handleSubmit,
    handleFormInputFocus,
    handleThreeDSecureToken,
    onChangeCardElementsHandler,
    onChangeFullNameHandler,
    onChangePostalCodeHandler,
  } = useLayoutCardFormLogic({ onSuccess, onError, product });

  const hiddenInputs = (
    <div className={styles.hidden}>
      <InputField
        name="first_name"
        dataRecurly="first_name"
        value={firstName}
      />
      <InputField name="last_name" dataRecurly="last_name" value={lastName} />
    </div>
  );

  return (
    <form ref={formRef} className={`${styles.container} ${className} `}>
      <Alert visible={!!error} error={error} onClose={() => setError('')} />
      <ThreeDSecure
        token={actionTokenId}
        // @ts-ignore
        onSuccess={handleThreeDSecureToken}
        onError={handleThreeDSecureError}
      />
      <div className={styles.card}>
        <div className={`${styles.inputsWrapper} ${styles.cardElement}`}>
          <CardElementsField
            label="CARD NUMBER"
            validationError={cardElementValidationError}
            onFocusHandler={handleFormInputFocus}
            onChangeHandler={onChangeCardElementsHandler}
          />
        </div>
        <div className={styles.inputsWrapper}>
          <InputField
            className={styles.input}
            label="NAME ON CARD"
            placeholder="Jane Doe"
            value={fullName}
            validationError={fullNameValidationError}
            onChangeHandler={onChangeFullNameHandler}
            onFocusHandler={handleFormInputFocus}
          />
          {isPostalCodeVisible && (
            <InputField
              label="ZIP CODE"
              placeholder="01-210"
              ref={postalCodeRef}
              dataRecurly="postal_code"
              validationError={postalCodeValidationError}
              onChangeHandler={onChangePostalCodeHandler}
              onFocusHandler={handleFormInputFocus}
            />
          )}
        </div>
      </div>
      {hiddenInputs}

      {children}

      <LoadingButton
        disabled={isPaymentFormInvalid}
        isLoading={isLoading}
        label="Continue"
        className={styles.button}
        onClick={handleSubmit}
      />
    </form>
  );
};
