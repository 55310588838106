import React from 'react';
import Logo from 'assets/logo_loona.svg';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import styles from './styles.module.scss';

type Props = {
  giftCardType: GiftCardTypes;
};

export const PlanImage = ({ giftCardType }: Props) => {
  let overlayStyle;

  switch (giftCardType) {
    case GiftCardTypes.Yearly: {
      overlayStyle = styles.yearly;
      break;
    }
    case GiftCardTypes.Lifetime: {
      overlayStyle = styles.lifetime;
      break;
    }
  }

  return (
    <div className={`${styles.container} ${overlayStyle}`}>
      <div className={`${styles.overlay} ${overlayStyle}`} />
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt="Loóna" />
        <div className={`${styles.cardContainer} ${overlayStyle}`} />
      </div>
      <div className={`${styles.containerBackground} ${overlayStyle}`} />
    </div>
  );
};
