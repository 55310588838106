import React from 'react';
import { ReactComponent as CheckedMark } from 'assets/ic_checked.svg';
import MinusSign from 'assets/ic_minus.svg';
import PlusSign from 'assets/ic_plus.svg';
import { GiftTemplate } from '../../components/gifttemplate/GiftTemplate';
import { usePlanSetupLogic } from './PlansSetup.hook';
import styles from './styles.module.scss';

export const PlansSetup = () => {
  const {
    quantity,
    products,
    totalPrice,
    giftCardLabel,
    isEmailValid,
    emailRef,
    giftCardType,
    onContinue,
    increaseQuantity,
    decreaseQuantity,
    onEmailValidHandler,
  } = usePlanSetupLogic();

  if (!products) return null;

  return (
    <GiftTemplate giftCardType={giftCardType}>
      <p className={styles.title}>
        Gift <span className={styles[giftCardType]}>{giftCardLabel}</span>{' '}
        membership
      </p>
      <div className={`${styles.inputContainer} ${styles[giftCardType]}`}>
        <input
          ref={emailRef}
          className={styles.input}
          placeholder="Your email address"
          type="email"
          onChange={onEmailValidHandler}
        />

        {isEmailValid && <CheckedMark />}
      </div>
      <div className={styles.emailDescription}>
        Gift Card will be sent to your email
      </div>
      <div className={styles.quantityContainer}>
        <div className={styles.quantityTitle}>Quantity</div>
        <button className={styles.quantityButton} onClick={decreaseQuantity}>
          <img src={MinusSign} alt="-" />
        </button>
        <div className={styles.quantityAmount}>{quantity}</div>
        <button className={styles.quantityButton} onClick={increaseQuantity}>
          <img src={PlusSign} alt="+" />
        </button>
      </div>
      <div className={styles.divider} />
      <div className={styles.totalContainer}>
        <div className={styles.totalTitle}>Total</div>
        <div className={styles.totalAmount}>{totalPrice}</div>
      </div>
      <button
        className={`${styles.submitButton} ${styles[giftCardType]}`}
        onClick={onContinue}
        disabled={!isEmailValid}
      >
        Continue
      </button>
    </GiftTemplate>
  );
};
