import { appAnalytics } from 'analytics';
import { SubscriptionError, SubscriptionSuccess } from 'analytics/AllEvents';
import { OptionalParams } from 'analytics/Event';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { goToNextPage, setSubscriptionActive } from 'bootstrap/user/actions';
import { getQueryVariable, pushWithSearchQuery } from 'utils';
import { SuccessPaymentProps } from './Recurly.types';
import {
  isAdhdTest,
  isIgnoreSkipTrialFlow,
  isWorkbookUpsellFlow,
} from 'navigation';

export const useRecurlyLogic = () => {
  const dispatch = useAppDispatch();
  const idx = getQueryVariable('idx');

  const { email, currency } = useAppSelector((state) => state.user);

  const onPaymentSuccess = ({
    orderId,
    productBought,
    zip,
    firstName,
    lastName,
  }: SuccessPaymentProps) => {
    dispatch(setSubscriptionActive(false));

    const params: OptionalParams = {
      eventId: orderId,
      email: email,
      zip: zip,
      firstName: firstName,
      lastName: lastName,
      onboardingType: 'onboarding',
    };

    appAnalytics.trackEvent(
      new SubscriptionSuccess(
        productBought.trialLength
          ? productBought.setupFee
          : productBought.amount,
        currency || productBought.currency,
        productBought.code,
        params,
      ),
    );

    if (idx) {
      dispatch(pushWithSearchQuery('/quick-purchase/done'));
    }

    if (isWorkbookUpsellFlow() || isAdhdTest()) {
      dispatch(pushWithSearchQuery('/workbook'));
    } else if (isIgnoreSkipTrialFlow()) {
      dispatch(pushWithSearchQuery('/done'));
    } else {
      dispatch(goToNextPage());
    }
  };

  const onPaymentError = (message: string) => {
    appAnalytics.trackEvent(new SubscriptionError(message));
  };

  return {
    currency,
    onPaymentSuccess,
    onPaymentError,
  };
};
