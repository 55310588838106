import React, { useState } from 'react';

import { ReactComponent as ClosedEyeLight } from 'assets/icon_eye_closed_light.svg';
import { ReactComponent as ClosedEye } from 'assets/icon_eye_closed.svg';
import { ReactComponent as OpenedEyeLight } from 'assets/icon_eye_opened_light.svg';
import { ReactComponent as OpenedEye } from 'assets/icon_eye_opened.svg';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
  placeholder?: string;
  isValid: boolean;
  dark?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocusChange?: (isFocused: boolean) => void;
  className?: string;
};

export const PasswordInput = ({
  value,
  placeholder = 'passwordPlaceholder',
  isValid,
  dark,
  onChange,
  onFocusChange,
  className,
}: Props) => {
  const { t } = useTranslation();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const OpenedEyeComponent = dark ? OpenedEyeLight : OpenedEye;
  const ClosedEyeComponent = dark ? ClosedEyeLight : ClosedEye;

  return (
    <div
      className={`
        ${styles.container} 
        ${className} 
        ${dark ? styles.dark : ''}
      `}
    >
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        className={!isValid ? 'invalid' : ''}
        value={value}
        placeholder={t(placeholder)}
        onFocus={() => {
          setFocused(true);
          onFocusChange?.(true);
        }}
        onBlur={() => {
          setFocused(false);
          onFocusChange?.(false);
        }}
        onChange={onChange}
      ></input>
      {(isFocused || value.length >= 1) &&
        (isPasswordVisible ? (
          <OpenedEyeComponent
            className={`${styles.eye} ${styles.opened}`}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        ) : (
          <ClosedEyeComponent
            className={`${styles.eye} ${styles.opened}`}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        ))}
    </div>
  );
};
