import React from 'react';
import { useTranslation } from 'react-i18next';
import GreenLikeIcon from 'assets/onboarding/ic_green_like.svg';
import styles from './styles.module.scss';

export const SocialProof: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <img src={GreenLikeIcon} className={styles.image} alt="" />
      <div className={styles.descriptionWrapper}>
        <div className={styles.boldDescription}>
          {t('socialProofDescriptionLine1', { percent: 92 })}
        </div>
        <div className={styles.addInfo}>
          {t('socialProofDescriptionLine2', { year: 2022 })}
        </div>
      </div>
    </div>
  );
};
