import React from 'react';
import { OptionQuestion } from './OptionQuestion';
import Spacer from 'components/spacer/Spacer';
import { useOptionQuestionLogic } from './OptionQuestions.hook';
import { QuestionComponentProps } from '../Question.types';
import styles from './styles.module.scss';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';

type Props = QuestionComponentProps<OptionQuestion>;

export const OptionQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const {
    title,
    activeOptions,
    containerStyle,
    error,
    setError,
    optionHandler,
  } = useOptionQuestionLogic(question);

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      <div
        className={`
        ${styles.container} 
        ${styles[containerStyle]} 
        maxWidth
      `}
      >
        <h3 className={styles.title}>{title}</h3>
        {question.description && (
          <div className={styles.description}>{question.description}</div>
        )}
        {question.options.map((option) => (
          <button
            key={option.id}
            className={`
            ${styles.option} 
            ${activeOptions.includes(option.id) ? styles.active : ''}
          `}
            onClick={optionHandler(option)}
          >
            {option.text}
          </button>
        ))}
        <Spacer />
      </div>
    </>
  );
};
