import amplitude from 'amplitude-js';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { appAnalytics } from 'analytics';
import { SignUp, SignUpError } from 'analytics/AllEvents';
import { updateOnboardingInfo } from 'bootstrap/net/onboarding';
import {
  noPasswordRegister,
  register,
  socialRegister,
  login,
} from 'bootstrap/net/user';
import { RootState } from 'bootstrap/types';
import { checkActiveSubscription } from './subscripionOperations';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { CircadianTypes } from 'onboarding/question/meqresults/MeqResults.constants';

export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const registerNoPassword =
  (
    email: string,
    planCode: string,
    isRestrictMailing?: boolean,
    quizResult?: CircadianTypes,
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, _) => {
    return noPasswordRegister(email, planCode, isRestrictMailing, quizResult)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: {
            authToken: userInfo.auth_token,
            email: email,
          },
        });
        appAnalytics.trackEvent(
          new SignUp('email', isRestrictMailing, userInfo.is_new_user),
        );
        amplitude.getInstance().setUserId(userInfo.user.idx);
        dispatch(setOnboardingInfo(userInfo.userdata));
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        let message;
        if (error.description === 'DATA_EXIST') {
          message = error.description;
        } else {
          message = getErrorMessage(error);
          appAnalytics.trackEvent(new SignUpError('email', message));
        }
        return Promise.reject(message);
      });
  };

export const emailLogin =
  (
    email: string,
    password: string,
    userdata: 'onboarding' | 'redeem' | 'qp' | 'pi',
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return login(email, password, userdata)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: {
            authToken: userInfo.auth_token,
            email: email,
          },
        });
        appAnalytics.trackEvent(new SignUp('email'));
        amplitude.getInstance().setUserId(userInfo.user.idx);
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        return Promise.reject(message);
      });
  };

export const emailRegister =
  (
    email: string,
    password: string,
    userdata: 'onboarding' | 'redeem' | 'qp' | 'pi',
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return register(email, password, userdata)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: {
            authToken: userInfo.auth_token,
            email: email,
          },
        });
        appAnalytics.trackEvent(new SignUp('email'));
        amplitude.getInstance().setUserId(userInfo.user.idx);
        dispatch(setOnboardingInfo(userInfo.userdata));
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        let message;
        if (error.description === 'DATA_EXIST') {
          return dispatch(emailLogin(email, password, userdata));
        } else {
          message = getErrorMessage(error);
        }
        appAnalytics.trackEvent(new SignUpError('email', message));
        return Promise.reject(message);
      });
  };

export const socialLogin =
  (
    firstName: string,
    lastName: string,
    email: string,
    id: string,
    accessToken: string,
    type: 'google' | 'facebook',
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    return socialRegister(firstName, lastName, email, id, accessToken, type)
      .then((userInfo) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: {
            authToken: userInfo.auth_token,
            email: email,
          },
        });
        amplitude.getInstance().setUserId(userInfo.user.idx);
        appAnalytics.trackEvent(new SignUp(type));
        return dispatch(checkActiveSubscription());
      })
      .catch((error) => {
        appAnalytics.trackEvent(new SignUpError(type, error));
        return Promise.reject(error);
      });
  };

export const clean = (obj: Record<string, any>): Record<string, any> => {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
};

const setOnboardingInfo =
  (info: any): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState,
  ) => {
    const { onboarding } = getState();

    const result = clean({ ...onboarding });

    if (Object.keys(result).length !== 0) {
      await updateOnboardingInfo({
        ...info,
        initial_onboarding: result,
      });
    }
  };
