import { appAnalytics } from 'analytics';
import { useAppSelector } from 'bootstrap/hooks';
import { useEffect } from 'react';
import { RedeemAppContinue, RedeemScreenOpened } from 'redeem/analytics';
import { getOneLink, getQueryVariable, isDevEnv, isTouchDevice } from 'utils';

export const useRedeemDoneLogic = () => {
  const { authToken } = useAppSelector((state) => state.user);
  const giftCode = getQueryVariable('code');

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemCongratsScreen'));
  }, []);

  const handleAppLink = () => {
    appAnalytics.trackEvent(new RedeemAppContinue());

    window.location.href = getOneLink(
      giftCode
        ? {
            mediaSource: isDevEnv() ? 'web_payments_dev' : 'web_payments',
            webDp: 'https%3A%2F%2Floona.app%2F',
          }
        : {
            mediaSource: isDevEnv() ? 'web_payments_dev' : 'web_payments',
            webDp: 'https%3A%2F%2Floona.app%2F',
            deepLink: authToken,
          },
      !isTouchDevice() ? `${process.env.REACT_APP_FRONTEND_URL}/install` : '',
    );
  };

  return { giftCode, handleAppLink };
};
