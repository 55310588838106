import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { SyntheticEvent, useEffect, useState } from 'react';
import { ColorOption } from './ColorOption';
import { ColorQuestion } from './ColorQuestion';

export const useColorComponentLogic = (question: ColorQuestion) => {
  const dispatch = useAppDispatch();

  const { id } = question;
  const [activeOptionId, setActiveOptionId] = useState<string>('');
  const [optionStyle, setOptionStyle] = useState('');
  const [isLeaving, setLeaving] = useState(false);

  useEffect(() => {
    setOptionStyle('enter');
    setLeaving(false);
  }, [question]);

  const optionHandler = (option: ColorOption) => (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isLeaving) {
      setActiveOptionId(option.id);
      setLeaving(true);
      setTimeout(() => {
        setOptionStyle('leave');
        setTimeout(() => {
          appAnalytics.trackEvent(new SaveAnswer(id, option.id));
          dispatch(setAnswer(id, option.id));
          setLeaving(false);
        }, 200);
      }, 100);
    }
  };

  return {
    activeOptionId,
    optionStyle,
    optionHandler,
  };
};
