import React, { useState } from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch } from 'bootstrap/hooks';
import { loadProducts } from 'bootstrap/product/actions';
import { LoadingScreen } from 'components/loadingscreen/LoadingScreen';
import { useProduct } from 'hooks/useProduct';
import { Error } from '../components/error/Error';
import { useTranslation } from 'react-i18next';

export interface WithDefaultProduct {
  product: Product;
  products: Map<string, Product>;
}

export const withDefaultProduct = <T extends WithDefaultProduct>(
  Component: React.FC<T>,
) => {
  return (props: Omit<T, 'product' | 'products'>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const {
      product,
      error: productLoadError,
      isLoading: isProductLoading,
      products,
    } = useProduct();
    const [isLoading, setIsLoading] = useState(false);

    const fetchProducts = () => {
      setIsLoading(true);
      if (!products || Object.keys(products).length === 0) {
        dispatch(loadProducts()).catch((_) => {
          setIsLoading(false);
        });
      }
    };

    if (productLoadError) {
      return <Error onRetry={fetchProducts} />;
    }

    return (
      <>
        <LoadingScreen
          isVisible={isProductLoading || isLoading}
          title={t('loadingScreenTitle')}
          description={
            <>
              {t('loadingScreenDescriptionLine1')}
              <br />
              {t('loadingScreenDescriptionLine2')}
            </>
          }
        />
        {products && products.size > 0 && (
          <Component {...(props as T)} product={product} products={products} />
        )}
      </>
    );
  };
};
