import { useEffect } from 'react';

interface Props {
  visible: boolean;
}

export const useModalLogic = ({ visible }: Props) => {
  useEffect(() => {
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [visible]);
};
