import React from 'react';
import Delete from 'assets/ic_delete.png';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { PasswordInput } from 'components/passwordinput/PasswordInput';
import Alert from 'components/error/Error';
import Spacer from 'components/spacer/Spacer';
import { useSignInLogic } from './SignIn.hook';
import styles from './styles.module.scss';

export const SignIn = () => {
  const {
    isLargeScreen,
    isLoading,
    emailState,
    passwordState,
    errorState,
    setEmailState,
    onPasswordChange,
    onEmailChange,
    setErrorState,
    handleSubmit,
  } = useSignInLogic();

  const content = (
    <div className={styles.contentContainer}>
      <div className={styles.title}>Sign in</div>
      <div className={styles.description}>
        Create your Loóna account to claim a free month of Loóna Plus.
      </div>

      <form className={styles.formContainer} onSubmit={handleSubmit} noValidate>
        <div className={styles.inputContainer}>
          <input
            type="email"
            className={
              emailState.error !== '' && emailState.value !== ''
                ? 'invalid'
                : ''
            }
            value={emailState.value}
            placeholder="Email"
            onChange={onEmailChange}
          />
          {emailState.value && (
            <img
              src={Delete}
              alt="x"
              className={styles.delete}
              onClick={() => setEmailState({ ...emailState, value: '' })}
            />
          )}
        </div>
        <div className={styles.errorLabel}>{emailState.error}</div>

        {passwordState.isVisible && (
          <>
            <PasswordInput
              value={passwordState.value}
              isValid={!passwordState.error}
              onChange={onPasswordChange}
            />
            <div className={styles.errorLabel}>{passwordState.error}</div>
          </>
        )}

        <Spacer />

        <span className={styles.legal}>
          By continuing, you agree to Loóna's
          <br />
          <a href="https://loona.app/terms.html">Terms of Use</a> &{' '}
          <a href="https://loona.app/privacy.html">Privacy Policy</a>
        </span>

        <LoadingButton
          disabled={
            emailState.value === '' ||
            emailState.error !== '' ||
            (passwordState.isVisible &&
              (passwordState.value === '' || passwordState.error !== ''))
          }
          isLoading={isLoading}
          label="Continue"
          onClick={handleSubmit}
        />

        <p className={styles.disclaimer}>
          Your information is 100% secure. Loóna does not share any personal
          information. By submitting your email address, you may also receive
          emails from Loóna about new offers, features and updates. You may
          unsubscribe at any time.
        </p>
      </form>
    </div>
  );

  return (
    <>
      <Alert
        visible={errorState.isVisible}
        error={errorState.error}
        onClose={() => setErrorState({ ...errorState, isVisible: false })}
      />
      {isLargeScreen ? (
        <div className={styles.bigBackgroundContainer}>{content}</div>
      ) : (
        content
      )}
    </>
  );
};
