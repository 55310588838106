import Divider from 'components/divider/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  withDivider?: boolean;
};

export const ReasonToUseTemplate = ({
  withDivider = true,
  className,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} ${styles.template}`}>
      {withDivider && <Divider />}
      <h3>{t('paymentReasonToUseTitle')}</h3>
      <ul className={styles.listAccent}>
        <li>{t('paymentReasonToUseLine1')}</li>
        <li>{t('paymentReasonToUseLine2')}</li>
        <li>{t('paymentReasonToUseLine3')}</li>
        <li>{t('paymentReasonToUseLine4')}</li>
      </ul>
    </div>
  );
};
