import React from 'react';
import { SuccessTemplate } from 'onboarding/components/successtemplate/SuccessTemplate';
import { useSuccessLogic } from './Success.hook';

export const Success = () => {
  const { oldUser, handleButtonClick } = useSuccessLogic();

  return (
    <SuccessTemplate
      title={oldUser ? 'Oops!' : 'Congratulations!'}
      subTitle={
        oldUser
          ? "You're already a member of Loóna"
          : "You're now a member of Loóna"
      }
      description={
        oldUser
          ? `Invite code can be applied only to ${'\n'} accounts without Loóna Plus.`
          : `Install the Loóna app now and ${'\n'} complete your first escape!`
      }
      onButtonClick={handleButtonClick}
      button="Continue in the app"
      note={`Invoice and a copy of the gift card ${'\n'} was sent to your email.`}
    />
  );
};
