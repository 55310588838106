import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import {
  hasAllGadTestAnswers,
  hasAllRmeqTestAnswers,
} from 'bootstrap/onboarding/actions';
import { pushWithSearchQuery } from 'utils';
import { RootState } from 'bootstrap/types';
import { isGadTest } from 'navigation';

export const goToNextPage =
  () =>
  (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState: () => RootState,
  ) => {
    const { answers } = getState().onboarding;

    if (isGadTest() && hasAllGadTestAnswers(answers)) {
      dispatch(pushWithSearchQuery(`/onboarding/${QuestionIds.GadResults}`));
    } else if (hasAllRmeqTestAnswers(answers)) {
      dispatch(pushWithSearchQuery(`/onboarding/${QuestionIds.MeqResults}`));
    } else {
      dispatch(pushWithSearchQuery('/done'));
    }
  };
