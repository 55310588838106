import { useEffect } from 'react';

declare global {
  interface Window {
    usePreloadImagesData?: Record<string, unknown[]>;
  }
}

export const usePreloadImages = (imageSources: string[]): void => {
  useEffect(() => {
    const randomStr = Math.random().toString(32).slice(2) + Date.now();
    window.usePreloadImagesData = window.usePreloadImagesData ?? {};
    window.usePreloadImagesData[randomStr] = [];
    for (const src of imageSources) {
      const img = new Image();
      img.src = src;
      window.usePreloadImagesData[randomStr].push(img);

      // Only for Safari <= 14.0
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      document.head.appendChild(link);
    }
    return () => {
      delete window.usePreloadImagesData?.[randomStr];
    };
  }, [imageSources]);
};
