import React from 'react';
import Ebook from 'assets/onboarding/ebook_v2.png';
import { ReactComponent as Checked } from 'assets/stepper/check.svg';
import styles from './styles.module.scss';

export const EbookOfferBox = () => {
  return (
    <div className={styles.container}>
      <img src={Ebook} alt="" />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>One-time price</div>
          <div className={styles.price}>$9.99</div>
        </div>
        <ul>
          <li>
            <Checked className={styles.check} /> Extra help
          </li>
          <li>
            <Checked className={styles.check} /> Improve your sleep routine
          </li>
          <li>
            <Checked className={styles.check} /> Foods and Activities
            recommendations
          </li>
        </ul>
      </div>
    </div>
  );
};
