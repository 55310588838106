import { appAnalytics } from 'analytics';
import { getCurrencyByCurrencyCode } from 'bootstrap/data/product/Product.utils';
import { buyGiftCard } from 'bootstrap/net/giftcards';
import { RootState } from 'bootstrap/types';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { GiftPurchaseSuccess } from '../../../giftcards/analytics';
import { GiftCardProduct } from '../../data/giftproduct/GiftCardProduct';
import { saveLink } from './saveLink';
import { getErrorMessage } from 'bootstrap/net/commonnet';

interface BuyProduct {
  product: GiftCardProduct;
  token: string;
  threeDsToken?: string;
  firstName?: string;
  lastName?: string;
  recaptchaToken: string;
}

export const buyProduct =
  ({
    product,
    token,
    threeDsToken,
    firstName,
    lastName,
    recaptchaToken,
  }: BuyProduct): ThunkAction<
    Promise<string | undefined>,
    RootState,
    unknown,
    AnyAction
  > =>
  async (dispatch, getState) => {
    const { email, quantity } = getState().purchase;
    const { currency: currencyCode, paymentMethod } = getState().user;

    const { currency, unit_amount: price } = getCurrencyByCurrencyCode(
      product.currencies,
      currencyCode,
    );

    if (!email) {
      return Promise.reject('No email provided');
    }
    return buyGiftCard({
      email,
      product,
      quantity,
      token,
      threeDsToken,
      firstName,
      lastName,
      currency: currency || product.currency,
      price: price || product.price,
      paymentMethod,
      recaptchaToken,
    })
      .then((result) => {
        const threeDsToken =
          ('three_d_secure_action_token_id' in result &&
            result.three_d_secure_action_token_id) ||
          undefined;
        const link = ('pdf_link' in result && result.pdf_link) || undefined;
        if (link) {
          dispatch(saveLink(link));
          appAnalytics.trackEvent(
            new GiftPurchaseSuccess(product.price, product.code, quantity),
          );
        }
        return Promise.resolve(threeDsToken);
      })
      .catch((error: any) => {
        return Promise.reject(getErrorMessage(error.debug_text));
      });
  };
