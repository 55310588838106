import React from 'react';
import { useInsomniaTestLogic } from './InsomniaResults.hook';
import { Info } from '../info/Info';
import { getQuestionComponent } from '../helper';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';

export const InsomniaResults = () => {
  const { insomniaTestResult } = useInsomniaTestLogic();

  const { description } = insomniaTestResult;

  const question: Info = new Info(
    QuestionIds.IsiD1,
    'We’ve got you covered',
    description,
  );

  const Component = getQuestionComponent(question);

  return <Component question={question} />;
};
