import React, { ChangeEvent } from 'react';
import styles from './styles.module.scss';

type Props = {
  name?: string;
  label?: string;
  type?: string;
  className?: string;
  inputClassName?: string;
  value?: string;
  validationError?: string;
  placeholder?: string;
  dataRecurly?: string;
  ref?: React.RefObject<HTMLInputElement>;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocusHandler?: () => void;
};

export const InputField = ({
  name,
  label,
  type = 'text',
  className,
  value,
  validationError,
  placeholder,
  dataRecurly,
  ref,
  onChangeHandler,
  onFocusHandler,
}: Props) => {
  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>

      <input
        type={type}
        placeholder={placeholder}
        className={`${styles.input} ${
          validationError ? styles.validationError : ''
        }`}
        value={value}
        name={name}
        data-recurly={dataRecurly}
        ref={ref}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
      />

      <div className={styles.formError}>{validationError}</div>
    </div>
  );
};
