import { UserActionTypes } from './userActions.types';

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

export function setSubscriptionActive(oldUser: boolean): UserActionTypes {
  return {
    type: SET_SUBSCRIPTION,
    payload: {
      hasSubscription: true,
      oldUser,
    },
  };
}
