import {
  fetchServer,
  getErrorMessage,
  toResultPromise,
  toSubscriptionResultResolve,
} from '../commonnet';
import { ENDPOINTS } from '../endpoints';
import { ServerResponse } from '../commonnet.types';
import {
  PaymentInfoResponse,
  SubscriptionInfo,
  SubscriptionNoAuthInfo,
  SubscriptionResponse,
  Workbook,
} from './subscription.types';
import { getQueryVariable } from 'utils';

export async function createSubscription({
  product,
  token,
  currency,
  threeDSToken,
  paymentMethod,
  recaptchaToken,
}: SubscriptionInfo): Promise<any> {
  const result = await fetchServer<SubscriptionResponse>({
    url: ENDPOINTS.CREATE_SUBSCRIPTION,
    method: 'POST',
    body: {
      user_billing_token: token,
      plan_code: product.code,
      currency: currency || product.currency,
      three_d_secure_token: threeDSToken,
      action_source: window.location.href,
      payment_method: paymentMethod,
      recaptcha_token: recaptchaToken,
    },
  });

  return toSubscriptionResultResolve(result);
}

export async function createSubscriptionByIdx({
  product,
  token,
  idx,
  threeDSToken,
  paymentMethod,
  bundle = 'default',
  recaptchaToken,
}: SubscriptionNoAuthInfo): Promise<any> {
  const result = await fetchServer<SubscriptionResponse>({
    url: ENDPOINTS.CREATE_SUBSCRIPTION_BY_IDX,
    method: 'POST',
    body: {
      user_billing_token: token,
      plan_code: product.code,
      currency: product.currency,
      three_d_secure_token: threeDSToken,
      action_source: window.location.href,
      subs_purchase_token: idx,
      bundle_name: bundle,
      payment_method: paymentMethod,
      recaptcha_token: recaptchaToken,
    },
    retries: 0,
    token: '',
  });

  return toSubscriptionResultResolve(result);
}

export async function checkSubscription(): Promise<boolean> {
  const result = await fetchServer<PaymentInfoResponse>({
    url: ENDPOINTS.USER_PURCHASES,
  });

  return toResultPromise(result, result.response.purchase?.is_active || false);
}

export async function getPurchaseToken(): Promise<any> {
  const uid = getQueryVariable('uid');

  const result = await fetchServer<PaymentInfoResponse>({
    url: uid
      ? `${ENDPOINTS.USER_PURCHASES}?uid=${uid}`
      : ENDPOINTS.USER_PURCHASES,
  });

  if (result.error.code === 0) {
    const purchase = result.response.purchase;

    return Promise.resolve(purchase);
  }
  return Promise.reject(getErrorMessage(result.error));
}

export async function buyWorkbook({
  currency,
  planCode,
}: Workbook): Promise<void> {
  const purchase = await getPurchaseToken();
  const purchaseToken = purchase?.purchase_token;

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${purchaseToken}/buy-workbook`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'POST',
    body: {
      action_source: window.location.href,
      coupon_codes: [],
      currency: currency,
      line_items: [
        {
          item_code: planCode,
          quantity: 1,
        },
      ],
    },
  });

  return toResultPromise(result);
}

export async function changeSubscription(planCode?: string): Promise<void> {
  const uid = getQueryVariable('uid');

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${uid}/change-plan`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'PATCH',
    body: {
      plan_code: planCode,
    },
  });

  return toResultPromise(result);
}

export async function changeSkipTrialSubscription(
  planCode?: string,
): Promise<void> {
  const purchase = await getPurchaseToken();
  const purchaseToken = purchase?.purchase_token;

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION}/${purchaseToken}/skip-trial-change-plan?plan_code=${planCode}`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'PUT',
  });

  return toResultPromise(result);
}

export async function changeSubscriptionWithCredit(
  planCode?: string,
): Promise<void> {
  const purchase = await getPurchaseToken();
  const purchaseToken = purchase?.purchase_token;

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${purchaseToken}/skip-trial-change-plan-credit?plan_code=${planCode}`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'PUT',
  });

  return toResultPromise(result);
}

export async function cancelSubscription(): Promise<void> {
  const uid = getQueryVariable('uid');
  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${uid}/no-auth`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'DELETE',
  });

  return toResultPromise(result);
}

export async function prolongSubscription(days: number): Promise<void> {
  const uid = getQueryVariable('uid');

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${uid}/extend-trial?days=${days}`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'PATCH',
  });

  return toResultPromise(result);
}

export async function refundSkipTrialSubscription(): Promise<void> {
  const purchase = await getPurchaseToken();
  const purchaseToken = purchase?.purchase_token;

  const skipTrialRefundUrl = `${ENDPOINTS.CHANGE_SUBSCRIPTION}/${purchaseToken}/skip-trial-refund`;

  const result = await fetchServer<ServerResponse>({
    url: skipTrialRefundUrl,
    method: 'PUT',
  });

  return toResultPromise(result);
}

export async function skipTrialWithCreditSubscription(): Promise<void> {
  const purchase = await getPurchaseToken();
  const purchaseToken = purchase?.purchase_token;

  const url = `${ENDPOINTS.CHANGE_SUBSCRIPTION_V1}/${purchaseToken}/skip-trial-credit`;

  const result = await fetchServer<ServerResponse>({
    url,
    method: 'PUT',
  });

  return toResultPromise(result);
}
