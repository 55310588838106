import React from 'react';
import BingeWatchingGraph from 'assets/onboarding/graphs/bingewatching_graph.svg';
import Spacer from 'components/spacer/Spacer';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import { useWelcomeLogic } from './Welcome.hook';
import styles from './styles.module.scss';

const WelcomeCancel = () => {
  const {
    error,
    onCancelSubscriptionHandler,
    onKeepBenefitsHandler,
    setError,
  } = useWelcomeLogic();

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      <div className={`${styles.wrapper} ${styles.maxWidth}`}>
        <div className={styles.title}>
          You’re more likely to reclaim
          <br /> your sleep with Loóna
        </div>
        <div className={styles.subtitle}>
          Don’t lose <span className={styles.accent}>personalised</span> escape
          plan and access to ever growing collection of escapes, immersive
          stories, music and ambiances
        </div>

        <img
          loading="lazy"
          src={BingeWatchingGraph}
          srcSet={BingeWatchingGraph}
          className={styles.image}
          alt=""
        />
        <div className={styles.note}>
          Loóna is here to help - if you’ll let us <br />
          <span className={styles.accent}>Don’t let a good thing go</span>
        </div>
        <Spacer />

        <div className={styles.content}>
          <StickyContainer className={styles.stickyContainer}>
            <button className={styles.button} onClick={onKeepBenefitsHandler}>
              Keep my benefits
            </button>
            <button
              className={`${styles.button} ${styles.secondary}`}
              onClick={onCancelSubscriptionHandler}
            >
              Cancel Loóna Plus
            </button>
          </StickyContainer>
        </div>
      </div>
    </>
  );
};

export default WelcomeCancel;
