import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { Products } from 'bootstrap/data/product/Product.types';
import Divider from 'components/divider/Divider';
import { useTranslation } from 'react-i18next';
import RadioButton from 'subscription/components/radiobutton/RadioButton';
import styles from './styles.module.scss';

type Props = {
  products: Products;
  product: Product;
  expanded: boolean;
  currencyCode?: string;
  handleChange: (type: string) => void;
  scrollToPaymentForm: () => void;
};

export const PaymentFormTemplate = ({
  products,
  product,
  currencyCode,
  handleChange,
  scrollToPaymentForm,
  expanded,
}: Props) => {
  const { t } = useTranslation();
  const productPrice = product.getFormattedPrice(currencyCode);
  const productInvoicePrice = product.getFormattedInvoicePrice(currencyCode);
  const productWeeklyPrice = product.getFormattedWeeklyPrice(currencyCode);

  return (
    <div className={styles.form}>
      <RadioButton
        key="extended_trial"
        name="trial"
        description="extendedTrialDescription"
        currencyCode={currencyCode}
        product={products.default.extended_trial}
        checked={products.default.extended_trial.code === product.code}
        onChange={() => handleChange('extended_trial')}
      />
      <RadioButton
        key="standard_trial"
        name="trial"
        description="standardTrialDescription"
        currencyCode={currencyCode}
        product={products.default.standard_trial}
        checked={products.default.standard_trial.code === product.code}
        onChange={() => handleChange('standard_trial')}
      />

      <Divider />

      <div className={`${styles.total} ${styles.bold}`}>
        {t('paymentTotalPriceWithoutColon')}
        <span className={`${styles.accent} ${styles.bold}`}>
          {productInvoicePrice}
        </span>
      </div>

      <Divider />

      <div className={`${styles.total} ${styles.bottom9}`}>
        {t('paymentAfterTrialPrice')}
      </div>

      <div className={`${styles.total} ${styles.top0}`}>
        {t('paymentPlanDuration', { duration: product.getTerm(t) })}
        <span className={styles.bold}>
          {t('paymentDuration', { price: productWeeklyPrice })}
        </span>
      </div>

      <div className={styles.total}>
        <span className={styles.purpleBadge}>
          {product.discount?.value || 30}% OFF
        </span>
        <span className={styles.grey}>
          {t('paymentBilledAt')} {productPrice}
        </span>
      </div>

      <Divider />

      {!expanded && (
        <button className={styles.submit} onClick={scrollToPaymentForm}>
          {t('getPlanButton')}
        </button>
      )}
    </div>
  );
};
