import { appAnalytics } from 'analytics';
import { ContinueWelcome, ScreenOpened } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { SyntheticEvent, useEffect } from 'react';

export const useGadWelcomeLogic = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    appAnalytics.trackEvent(new ScreenOpened('onboardingWelcome'));
  }, []);

  const handleTakeTest = (e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new ContinueWelcome('gad'));
    dispatch(setAnswer(QuestionIds.Welcome, ''));
  };

  return {
    handleTakeTest,
  };
};
