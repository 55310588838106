import React, { useEffect } from 'react';
import { useAppDispatch } from 'bootstrap/hooks';
import { socialLogin } from 'bootstrap/user/actions';
import styles from './styles.module.scss';

type Props = {
  onSuccess: (hasSubscription: boolean) => void;
  onError: (type: 'google', error: string) => void;
};

export const GoogleSignInButton = ({ onError, onSuccess }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    gapi.signin2.render('g-signin2', {
      scope: 'https://www.googleapis.com/auth/plus.login',
      width: 500,
      height: 50,
      longtitle: true,
      onsuccess: (response: gapi.auth2.GoogleUser) => {
        const googleResponse = response;

        if (googleResponse.getId()) {
          const profile = googleResponse.getBasicProfile();

          dispatch(
            socialLogin(
              profile.getGivenName(),
              profile.getFamilyName(),
              profile.getEmail(),
              profile.getId(),
              googleResponse.getAuthResponse().access_token,
              'google',
            ),
          )
            .then((hasSubscription) => {
              onSuccess(hasSubscription);
            })
            .catch((error) => {
              onError('google', error);
            });
        }
      },
      onfailure: ({ error }) => onError('google', error),
    });
  }, []);

  return <div id="g-signin2" className={styles.button}></div>;
};
