import { PurchaseActionTypes } from './types';

export const SET_EMAIL = 'SET_EMAIL';

export interface SetEmail {
  type: typeof SET_EMAIL;
  payload: {
    email: string;
  };
}

export function setEmail(email: string): PurchaseActionTypes {
  return {
    type: SET_EMAIL,
    payload: {
      email: email,
    },
  };
}
