import React from 'react';
import Alert from 'components/error/Error';
import { Stepper as NewStepper } from 'onboarding/components/stepper/Stepper';
import { useSignInLogic } from './SignIn.hook';
import DisclaimerInfo from './components/disclaimerinfo/DisclaimerInfo';
import SignInForm from './components/singinform/SignInForm';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import 'common.scss';

const SignIn = () => {
  const { t } = useTranslation();
  const {
    isErrorVisible,
    onErrorVisibleHandler,
    errorMessage,
    planCode,
    ...otherProps
  } = useSignInLogic();

  return (
    <div className={styles.template}>
      <Alert
        visible={isErrorVisible}
        error={errorMessage}
        onClose={onErrorVisibleHandler}
      />
      <div className={`${styles.templateContainer} ${styles.signIn}`}>
        <NewStepper
          className={styles.newStepper}
          stepName={t('almostSectionLabel')}
          currentStep={2}
          stepsTotal={3}
          currentSubstep={7}
          substepsTotal={9}
        />

        <p className={styles.question}>
          {t('signInPlanCodeTitle', { planCode })}
        </p>
        <div className={styles.description}>{t('signInDescription')}</div>

        <SignInForm {...otherProps} />
        <DisclaimerInfo />
      </div>
    </div>
  );
};

export default SignIn;
