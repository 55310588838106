import React from 'react';
import GlobeNotification from 'assets/earth_globe_notification.svg';
import Star from 'assets/review_star.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  text: string;
  author: string;
  avatar: string;
};

const FacebookReview = ({ text, author, avatar }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="facebook-review">
      <div className="review-header">
        <img className="review-avatar" src={avatar} alt="" />
        <div className="review-author">
          <div className="review-title">
            <b>{author}</b>
            <img src={Star} alt="" />
          </div>
          <div className="review-subtitle">
            <img src={GlobeNotification} alt="" />
            {t('reviewRecommends')} <b>Loóna</b>
          </div>
        </div>
      </div>
      <div className="review-text">{text}</div>
    </div>
  );
};

export default FacebookReview;
