import { appAnalytics } from 'analytics';
import { Install, ViewCongrats } from 'analytics/AllEvents';
import { useAppSelector } from 'bootstrap/hooks';
import { SyntheticEvent, useEffect } from 'react';
import { getOneLink, isDevEnv } from 'utils';

export const useCongratsLogic = () => {
  const { authToken, oldUser, email } = useAppSelector((state) => state.user);

  const qrCodeLink = getOneLink({
    qr: true,
    mediaSource: 'web_payments',
    campaign: 'qr',
    deepLink: authToken,
  });

  useEffect(() => {
    appAnalytics.trackEvent(new ViewCongrats(!oldUser));
  }, []);

  const handleButtonClick = (e?: SyntheticEvent) => {
    e?.preventDefault();
    appAnalytics.trackEvent(new Install());
    window.location.href = getOneLink({
      webDp: `${process.env.REACT_APP_FRONTEND_URL}/install`,
      mediaSource: isDevEnv() ? 'web_payments_dev' : 'web_payments',
      deepLink: authToken,
    });
  };

  return { qrCodeLink, email, oldUser, handleButtonClick };
};
