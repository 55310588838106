import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as ArrowUpCircle } from 'assets/arrow_up.svg';
import { ReactComponent as ArrowDownCircle } from 'assets/arrow_down.svg';
import { appAnalytics } from 'analytics';
import { UnfoldFaq } from 'analytics/AllEvents';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  question: string;
  answer: string;
};

export const ListItem = ({ id, question, answer }: Props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!contentRef || !contentRef.current) return;

    if (active) {
      setMaxHeight(contentRef.current.scrollHeight);
    } else {
      setMaxHeight(0);
    }
  }, [contentRef, active]);

  const toggleAccordionItem = (event: any) => {
    if (event?.target instanceof HTMLAnchorElement) return;

    if (!active) {
      appAnalytics.trackEvent(new UnfoldFaq(id));
    }
    setActive(!active);
  };

  return (
    <div className={styles.item} onClick={toggleAccordionItem}>
      <div className={styles.questionContent}>
        <div className={styles.question}>{t(question)}</div>
        {active ? <ArrowUpCircle /> : <ArrowDownCircle />}
      </div>
      <div ref={contentRef} className={styles.answer} style={{ maxHeight }}>
        <p>{t(answer)}</p>
      </div>
    </div>
  );
};
