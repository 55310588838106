export type Option = {
  id: string;
  text?: string;
};

export const DEFAULT_OPTIONS: Option[] = [{ id: 'yes' }, { id: 'no' }];

export const GAD_OPTIONS: Option[] = [
  { id: 'not_at_all' },
  { id: 'several_days' },
  { id: 'more_than_half_days' },
  { id: 'nearly_every_day' },
];

export const RELATE_SCALE_OPTIONS: Option[] = [
  { id: 'not_at_all', text: '1' },
  { id: 'mildy', text: '2' },
  { id: 'moderately', text: '3' },
  { id: 'severely', text: '4' },
  { id: 'agree', text: '5' },
];
