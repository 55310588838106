import { SyntheticEvent, useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import { ContinueWelcome, ScreenOpened } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { useProgress } from 'hooks/useProgress';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { getQueryVariable } from 'utils';
import WelcomeRmeqImage from 'assets/welcome/welcome_rmeq.svg';

const DURATION = 10000;
const DELAY = 0;
const STEPS = [0.34, 0.69, 0.91, 1];

export const useRmeqWelcomeLogic = () => {
  const dispatch = useAppDispatch();

  const progress = useProgress(DURATION, DELAY, STEPS);
  const [imageUrl, setImageUrl] = useState('');

  const title = getQueryVariable('uat');
  const subtitle = getQueryVariable('uam');
  const imageName = getQueryVariable('uai');

  const fetchImage = async (imageName: string) => {
    const cloudImageUrl = `${process.env.REACT_APP_CLOUD_FRONT_URL}/web-onboarding/${imageName}`;

    try {
      const response = await fetch(cloudImageUrl, {
        mode: 'no-cors',
      });
      return response.status === 0 ? cloudImageUrl : WelcomeRmeqImage;
    } catch (error) {
      return WelcomeRmeqImage;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      const imageUrl = imageName
        ? await fetchImage(imageName)
        : WelcomeRmeqImage;
      setImageUrl(imageUrl);
    };

    loadImage();
  }, []);

  useEffect(() => {
    appAnalytics.trackEvent(
      new ScreenOpened('onboardingWelcome', {
        uat: title || 'default',
        uam: subtitle || 'default',
        uai: imageName || 'default',
      }),
    );
  }, []);

  const handleTakeTest = (e?: SyntheticEvent) => {
    e?.preventDefault();
    appAnalytics.trackEvent(new ContinueWelcome('rmeq'));
    dispatch(setAnswer(QuestionIds.Welcome, ''));
  };

  return {
    title: title && title?.length <= 70 ? title : '',
    subtitle:
      subtitle && subtitle?.trim().length <= 140 ? subtitle?.trim() : '',
    imageUrl,
    progress,
    handleTakeTest,
  };
};
