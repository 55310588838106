import { Step } from 'hooks/useLoader';
import { getPreloadImages } from './ImageLoader.utils';

const preloadImages = getPreloadImages();

export const STEPS: Step[] = [
  {
    text: 'imageLoaderStep1',
    image: preloadImages[0],
  },
  { text: 'imageLoaderStep2', image: preloadImages[1] },
  {
    text: 'imageLoaderStep3',
    image: preloadImages[2],
  },
  {
    text: 'imageLoaderStep4',
    image: preloadImages[3],
  },
  {
    text: 'imageLoaderStep5',
    image: preloadImages[4],
  },
  {
    text: 'imageLoaderStep6',
    image: preloadImages[5],
  },
  {
    text: 'imageLoaderStep7',
    image: preloadImages[6],
  },
  {
    text: 'imageLoaderStep8',
    image: preloadImages[7],
  },
  { text: 'imageLoaderStep9', image: preloadImages[8] },
  {
    text: 'imageLoaderStep10',
    image: preloadImages[9],
  },
  { text: 'imageLoaderStep11', image: preloadImages[10] },
];
