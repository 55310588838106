import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { CustomQuestion, Question, QuestionTypes } from '../Question';
import { TextOption } from './TextOption';

export class OptionalQuestion extends Question {
  sourceQuestionId: QuestionIds;
  options: Record<string, OptionQuestion | CustomQuestion>;

  constructor(
    sourceQuestionId: QuestionIds,
    questionId: QuestionIds,
    options: Record<string, OptionQuestion | CustomQuestion>,
  ) {
    super(QuestionTypes.Optional, questionId);
    this.sourceQuestionId = sourceQuestionId;
    this.options = options;
  }
}

export class OptionQuestion extends Question {
  title: string;
  description?: string | JSX.Element;
  options: TextOption[];
  multiple: boolean;

  constructor(
    id: QuestionIds,
    title: string,
    options: TextOption[],
    multiple = false,
    description?: string | JSX.Element,
  ) {
    super(QuestionTypes.Option, id);

    this.id = id;
    this.title = title;
    this.description = description;
    this.options = options;
    this.multiple = multiple;
  }

  hasOptionId(id: string): boolean {
    for (const option of this.options) {
      if (option.id === id) {
        return true;
      }
    }
    return false;
  }
}
