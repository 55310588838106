import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  className: string;
};

export const Footer = ({ className }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className={styles.links}>
        <a href="https://loona.app/terms">{t('termsOfUse')}</a>
        <a href="https://loona.app/privacy">{t('privacyPolicy')}</a>
      </div>
      <div className={styles.copyright}>
        © {new Date().getFullYear()} {t('rightsReserved')}
      </div>
    </div>
  );
};
