import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { Welcome } from './pages/welcome/Welcome';
import { SignIn } from './pages/signin/SignIn';
import { Payment } from './pages/payment/Payment';
import { Success } from './pages/success/Success';
import styles from './styles.module.scss';

export const InvitePurchase = () => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);
    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  }, []);

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 1 }}
      atActive={{ opacity: 1 }}
      className={`switch-wrapper ${styles.dark}`}
    >
      <Route exact path="/invite/:inviteCodeId">
        <Welcome />
      </Route>
      <Route exact path={`/invite/:inviteCodeId/signin`}>
        <SignIn />
      </Route>
      <Route exact path={`/invite/:inviteCodeId/payment`}>
        <Payment />
      </Route>
      <Route exact path={`/invite/:inviteCodeId/done`}>
        <Success />
      </Route>
    </AnimatedSwitch>
  );
};
