import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { activateGiftCode } from 'bootstrap/net/redeem';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { RedeemGiftCodeActivated, RedeemScreenOpened } from 'redeem/analytics';
import { getQueryVariable, pushWithSearchQuery } from 'utils';

export const useRedeemCodeLogic = () => {
  const dispatch = useAppDispatch();
  const [giftCode, setGiftCode] = useState(getQueryVariable('c') || '');
  const [giftCodeApplied, setGiftCodeApplied] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemGiftCode'));

    if (giftCode) {
      onRedeem();
    }
  }, []);

  const onRedeem = () => {
    const code = giftCode;
    activateGiftCode(code)
      .then((_) => {
        setGiftCodeApplied(true);
      })
      .catch((error) => {
        if (error.code === 409) {
          dispatch(push(`/redeem/done?code=${code}`));
        } else {
          setError(error.debug_text);
        }
      });
  };

  const onGiftCodeChange = (newGiftCode: string) => {
    setError('');
    setGiftCode(newGiftCode.trim());
  };

  const onContinue = () => {
    appAnalytics.trackEvent(new RedeemGiftCodeActivated(giftCode));
    dispatch(pushWithSearchQuery('/redeem/done'));
  };

  return {
    giftCode,
    giftCodeApplied,
    error,
    onRedeem,
    onGiftCodeChange,
    onContinue,
  };
};
