import React from 'react';
import AlaskaClinicLogo from 'assets/onboarding/alaska_clinic.png';
import { ReactComponent as Graph } from 'assets/onboarding/graphs/yoyo_graph.svg';
import { CustomQuestion } from 'onboarding/question/Question';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { useYoYoGraphLogic } from './YoYoGraph.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<CustomQuestion>;

export const YoYoGraph: React.FC<Props> = ({ question }: Props) => {
  const { t } = useTranslation();

  const { containerStyle, nextButtonHandler } = useYoYoGraphLogic(question);

  return (
    <div
      className={`
        ${styles.container} 
        ${styles[containerStyle]} 
        maxWidth
      `}
    >
      <div className={styles.content}>
        <div className={styles.innerContent}>
          <h3 className={styles.title}>
            Why weekend binge-sleeping fail or the "Yo-Yo" effect:
          </h3>
          <Graph className={styles.graph} />
          <div className={styles.info}>
            <img src={AlaskaClinicLogo} alt="" />
            <div className={styles.description}>
              <b>Alaska Sleep Clinic</b> reports weekend binge-sleeping creates
              a crash and burn lifestyle that disrupts the normal sleep REM
              cycle leading people to straight exhaustion.
              <br />
              <br />
              Establishing <b>a healthy pattern of sleep</b> will help you repay
              your sleep debt and deal with the effects of sleep deprivation.
            </div>
          </div>
          <Spacer />
          <div className={styles.bottomGradient} />
        </div>
      </div>
      <button className={styles.button} onClick={nextButtonHandler}>
        {t('Next')}
      </button>
    </div>
  );
};
