import { useEffect, useState } from 'react';
import { CheckedDot, FilledDot, NumberedDot } from '../components/dot';
import { ActiveLine, EmptyLine, FilledLine } from '../components/line';
import { StepperItem } from '../StepperItem';

type Props = {
  stepName: string;
  currentStep: number;
  stepsTotal: number;
  currentSubstep: number;
  substepsTotal: number;
};

export const useStepperLogic = ({
  stepName,
  currentStep,
  stepsTotal,
  currentSubstep,
  substepsTotal,
}: Props) => {
  const [steps, setSteps] = useState<any[]>([]);

  useEffect(() => {
    const result: StepperItem[] = [new FilledDot()];
    for (let step = 0; step < stepsTotal; step++) {
      switch (true) {
        case step < currentStep: {
          result.push(new FilledLine());
          result.push(new CheckedDot());
          break;
        }
        case step === currentStep: {
          const progress = ((currentSubstep + 1) / substepsTotal) * 100;
          result.push(new ActiveLine(stepName, progress));
          const isLastStep = currentSubstep === substepsTotal - 1;
          if (isLastStep) {
            result.push(new CheckedDot());
          } else {
            result.push(new NumberedDot(step + 1));
          }
          break;
        }
        case step > currentStep: {
          result.push(new EmptyLine());
          result.push(new NumberedDot(step + 1));
          break;
        }
      }
    }
    setSteps(result);
  }, [stepName, currentStep, stepsTotal, currentSubstep, substepsTotal]);

  return { steps };
};
