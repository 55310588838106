import React, { SyntheticEvent } from 'react';
import { appAnalytics } from 'analytics';
import { SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { useSubmit } from 'hooks/useSubmit';
import { CustomQuestion } from 'onboarding/question/Question';
import Graph from 'assets/onboarding/graphs/productivity_graph.svg';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<CustomQuestion>;

export const ProductivityGraph: React.FC<Props> = ({ question }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const nextButtonHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    appAnalytics.trackEvent(new SaveAnswer(question.id));
    setTimeout(() => {
      dispatch(goToNextQuestion());
    }, 250);
  });

  return (
    <div className={`${styles.container} maxWidth`}>
      <img src={Graph} alt="" />
      <div className={styles.title}>{t('PersonalizationProof1Title')}</div>
      <Spacer />
      <div className={styles.description}>
        Hoffmann, S.G., Asnaani, A., Vonk, I.J. et al. The Efficacy of Cognitive
        Behavioral Therapy a Review of Meta-analyses. Cogn Ther Res 36,
        427-440(2012).
      </div>
      <StickyContainer>
        <button className={styles.button} onClick={nextButtonHandler}>
          {t('Continue')}
        </button>
      </StickyContainer>
    </div>
  );
};
