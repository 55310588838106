import React from 'react';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { ErrorPopup } from '../../../components/errorpopup/ErrorPopup';
import { useSuccessLogic } from './Success.hook';
import { Errors } from './Success.constants';
import { SuccessTemplate } from 'onboarding/components/successtemplate/SuccessTemplate';
import styles from './styles.module.scss';

export const Success = () => {
  const { giftCardType, error, isLoading, setErrorHandler, handleDownloadDoc } =
    useSuccessLogic();

  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setErrorHandler(Errors.NoError)}
      />
      <SuccessTemplate
        title="Congratulations!"
        description={
          <>
            Download the Gift Card and share it
            <br />
            with the one you care about
          </>
        }
        button={
          <LoadingButton
            className={`${styles.loadingButton} ${styles[giftCardType]}`}
            disabled={false}
            isLoading={isLoading}
            label="Download gift card"
            onClick={handleDownloadDoc}
          />
        }
      />
    </>
  );
};
