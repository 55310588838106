import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  currencyCode?: string;
};

export const PaymentChecklist = ({ product, currencyCode }: Props) => {
  const { t } = useTranslation();
  return (
    <ul className={styles.paymentCheckList}>
      <li>
        {t('paymentChecklistLine1', { trialLength: product.trialLength })}{' '}
        {product.getFormattedInvoicePrice(currencyCode)}
      </li>
      <li>{t('paymentChecklistLine2')}</li>
    </ul>
  );
};
