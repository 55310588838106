import { QUESTIONS_META } from 'onboarding/data/meta';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { OptionalQuestion } from 'onboarding/question/options/OptionQuestion';
import { pushWithSearchQuery } from 'utils';
import { AppThunk } from 'bootstrap/types';
import { DEFAULT_ANSWERS } from 'onboarding/data/constants/DefaultAnswers';
import { Answers } from '../state';
import { OnboardingActionTypes } from './types';
import { AppDispatch } from 'index';
import { GadQuestionIds } from 'onboarding/question/gadresults/GadResult.constants';
import { QuestionTypes } from 'onboarding/question/Question';
import { QuestionsOrder } from 'onboarding/data/QuestionsOrder';
import { RmeqQuestionIds } from 'onboarding/question/meqresults/MeqResults.constants';
import { InsomniaQuestionIds } from 'onboarding/question/insomniaresults/InsomniaResults.constants';

export const SET_ANSWER = 'SET_ANSWER';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';

export const setAnswer =
  (
    question: QuestionIds,
    answer: string | string[],
    navigateNext = true,
  ): AppThunk<OnboardingActionTypes> =>
  (dispatch: AppDispatch, _) => {
    dispatch({
      type: SET_ANSWER,
      payload: {
        question,
        answer,
      },
    });
    navigateNext && dispatch(goToNextQuestion());
  };

export const hasOption = (
  answers: Answers,
  question: OptionalQuestion,
): boolean => {
  const answer =
    answers[question.sourceQuestionId] ||
    DEFAULT_ANSWERS[question.sourceQuestionId];
  return typeof answer === 'string' && !!question.options[answer];
};

export const hasAllRmeqTestAnswers = (answers: Answers) => {
  return RmeqQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const hasAllGadTestAnswers = (answers: Answers) => {
  return GadQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const hasAllInsomniaTestAnswers = (answers: Answers) => {
  return InsomniaQuestionIds.every(
    (questionId) =>
      Object.keys(answers).includes(questionId) && answers[questionId],
  );
};

export const goToNextQuestion =
  (): AppThunk<OnboardingActionTypes> => (dispatch: AppDispatch, getState) => {
    const currentQuestionId = window.location.pathname.replace(
      '/onboarding/',
      '',
    );
    let currentQuestionIndex = QuestionsOrder.indexOf(
      currentQuestionId as QuestionIds,
    );
    const { answers } = getState().onboarding;

    const nextQuestionMeta =
      QUESTIONS_META[QuestionsOrder[currentQuestionIndex + 1]];
    if (nextQuestionMeta && nextQuestionMeta.type === QuestionTypes.Optional) {
      if (!hasOption(answers, nextQuestionMeta as OptionalQuestion)) {
        currentQuestionIndex += 1;
      }
    }

    if (nextQuestionMeta && nextQuestionMeta.id) {
      if (
        nextQuestionMeta.id === QuestionIds.IsiD1 &&
        !hasAllInsomniaTestAnswers(answers)
      ) {
        currentQuestionIndex += 1;
      }
      if (
        nextQuestionMeta.id === QuestionIds.MeqResults &&
        !hasAllRmeqTestAnswers(answers)
      ) {
        currentQuestionIndex += 1;
      }
    }
    if (QuestionsOrder.length - 1 > currentQuestionIndex) {
      const nextQuestion = QuestionsOrder[currentQuestionIndex + 1];

      dispatch({
        type: SET_CURRENT_QUESTION,
        question: QuestionsOrder[currentQuestionIndex + 1],
      });
      dispatch(pushWithSearchQuery(`/onboarding/${nextQuestion}`));
    } else {
      dispatch(pushWithSearchQuery('/create-account'));
    }
  };
