import React, { useEffect } from 'react';
import { ReactComponent as Logo } from 'assets/logo_loona.svg';
import { AppStoreButton } from 'install/components/AppStoreButton';
import { GooglePlayButton } from 'install/components/GooglePlayButton';
import { appAnalytics } from 'analytics';
import { ViewInstallQRScreen } from 'analytics/AllEvents';
import { useAppSelector } from 'bootstrap/hooks';
import QrCode from 'components/qrcode/QrCode';
import { getOneLink } from 'utils';
import styles from './styles.module.scss';

const Install = () => {
  const { authToken } = useAppSelector((state) => state.user);
  const qrCodeLink = getOneLink({
    qr: true,
    mediaSource: 'web_payments',
    campaign: 'qr',
    deepLink: authToken,
  });

  useEffect(() => {
    appAnalytics.trackEvent(new ViewInstallQRScreen());
  }, []);

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.content}>
        <QrCode size={200} value={qrCodeLink} />
        <div className={styles.title}>Install Loóna</div>
        <div className={styles.description}>
          You need an iPhone or Android smartphone to access your personal
          escape plan.
        </div>
        <div className={styles.note}>
          Scan the qr code above or use {'\n'} the links below:
        </div>
        <div className={styles.markets}>
          <AppStoreButton />
          <GooglePlayButton />
        </div>
      </div>
    </div>
  );
};

export default Install;
