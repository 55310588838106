import React from 'react';
import Lock from 'assets/ic_lock.svg';
import './styles.scss';

export const SecureServer = () => {
  return (
    <div className="secure-server">
      <img src={Lock} alt="" />
      <div className="text">Secure Server</div>
    </div>
  );
};
