import React, { ReactNode } from 'react';
import Logo from 'assets/logo_loona.svg';
import styles from './styles.module.scss';

type Props = {
  children: ReactNode;
};

export const Background = ({ children }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.gradient} />
      <div className={styles.content}>
        <img className={styles.logo} src={Logo} alt="" />
        {children}
      </div>
    </div>
  );
};
