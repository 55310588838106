import React from 'react';
import AppleLogo from 'assets/icon_apple_new.svg';
import AppleLogoLight from 'assets/icon_apple_white.svg';
import GooglePlayLogo from 'assets/icon_google_play_new.svg';
import { Product } from 'bootstrap/data/product/Product';
import Stars from 'components/stars/Stars';
import { Note } from '../note/Note';
import styles from './styles.module.scss';

type Props = {
  darkTheme?: boolean;
  currencyCode?: string;
  product: Product;
};

export const MarketInfo = ({
  darkTheme = false,
  product,
  currencyCode,
}: Props) => {
  return (
    <div className={`${styles.container} ${darkTheme ? styles.dark : ''}`}>
      <Note
        product={product}
        currencyCode={currencyCode}
        trialLength={product.trialLength}
      />

      <div className={styles.markets}>
        <div className={`${styles.market} ${styles.google}`}>
          <img src={GooglePlayLogo} alt="" />
          Google Play
        </div>
        <div className={`${styles.market} ${styles.apple}`}>
          <img src={darkTheme ? AppleLogoLight : AppleLogo} alt="" />
          App Store
        </div>
      </div>
      <div className={styles.rating}>
        <Stars />
        <div>
          <div className={styles.apple}>
            <span>4.9</span> out of 5
          </div>
        </div>
      </div>
      <div className={styles.metrics}>
        <div>
          <b>1M+</b> downloads
        </div>
        <div className={styles.verticalDivider} />
        <div>
          <b>38K</b> 5-star ratings
        </div>
      </div>
    </div>
  );
};
