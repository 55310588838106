import { Event } from 'analytics/Event';
import { Events } from 'analytics/Events';

export class RedeemScreenOpened extends Event {
  constructor(screenName: string) {
    super(Events.RedeemScreenOpened, { screenName });
  }
}

export class RedeemWelcomeContinue extends Event {
  constructor() {
    super(Events.RedeemWelcomeContinue);
  }
}

export class RedeemSignUp extends Event {
  constructor(accountType: 'email' | 'facebook' | 'google') {
    super(Events.RedeemSignUp, { accountType });
  }
}

export class RedeemGiftCodeActivated extends Event {
  constructor(giftCode: string) {
    super(Events.RedeemGiftCodeActivated, { giftCode });
  }
}

export class RedeemAppContinue extends Event {
  constructor() {
    super(Events.RedeemAppContinue);
  }
}
