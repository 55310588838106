export enum GiftCardTypes {
  Yearly = 'yearly',
  Lifetime = 'lifetime',
}

export interface GiftCardProduct {
  name: string;
  price: number;
  currency: string;
  code: string;
  description: string;
}
