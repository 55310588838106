import React from 'react';
import { BaseDot, DotStates, NumberedDot } from './index';
import styles from './styles.module.scss';

type Props = {
  item: BaseDot;
  className: string;
};

export const DotComponent: React.FC<Props> = ({ item, className }: Props) => {
  switch (item.state) {
    case DotStates.Filled:
      return (
        <div
          className={`
            ${styles.dot} 
            ${styles.dotFilled} 
            ${styles[className]}
          `}
        />
      );
    case DotStates.Checked:
      return (
        <div
          className={`
            ${styles.dot} 
            ${styles.dotChecked}  
            ${styles[className]}
          `}
        />
      );
    case DotStates.Numbered:
      return (
        <div
          className={`
            ${styles.dot} 
            ${styles[className]}
          `}
        >
          <div className={styles.label}>{(item as NumberedDot).stepNumber}</div>
        </div>
      );
  }
};
