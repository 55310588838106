import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Question, QuestionTypes } from 'onboarding/question/Question';

export class BlitzQuestion extends Question {
  title: string;

  constructor(id: QuestionIds, title: string) {
    super(QuestionTypes.Blitz, id, false);
    this.id = id;
    this.title = title;
  }
}
