import React from 'react';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import styles from './styles.module.scss';

type BackgroundProps = {
  className?: string;
  children: React.ReactNode;
  giftCardType: GiftCardTypes;
};

export const FogBackground = ({
  className,
  children,
  giftCardType,
}: BackgroundProps) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.overlay} ${styles[giftCardType]}`}>
        <div className={`${styles.gradient} ${styles.start}`} />
        <div
          className={`
            ${styles.particles}
            ${styles[giftCardType]}
          `}
        />
        <div className={`${styles.gradient} ${styles.end}`} />
      </div>
      <div className={`${styles.content} ${className}`}>{children}</div>
    </div>
  );
};
