import React, { useEffect } from 'react';
import { Spinner } from './components/Spinner';
import { useAppDispatch } from 'bootstrap/hooks';
import { push } from 'connected-react-router';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import styles from './styles.module.scss';

export const LoadingScreen = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(push(QuestionIds.FinishWinBack)), 1000);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Spinner />
      <h4 className={styles.title}>Updating your subscription</h4>
      <p className={styles.subtitle}>
        Please don't hit "back" or refresh the page
      </p>
    </div>
  );
};
