import React from 'react';
import MonthlySubscriptionCard from 'assets/monthly_subscription.png';
import Logo from 'assets/logo_loona.svg';
import styles from './styles.module.scss';

type BackgroundProps = {
  className?: string;
  children: React.ReactNode;
};

export const PlanImage = ({ className, children }: BackgroundProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <div className={styles.particles} />
        <img className={styles.logo} src={Logo} alt="Loóna" />
        <img
          className={styles.cardContainer}
          src={MonthlySubscriptionCard}
          alt=""
        />
      </div>
      <div className={`${styles.content} ${className}`}>{children}</div>
    </div>
  );
};
