type FaqListItem = {
  id: string;
  question: string;
  answer: string;
};

export const faqListData: FaqListItem[] = [
  {
    id: 'loona',
    question: 'faqListQuestion1',
    answer: 'faqListAnswer1',
  },
  {
    id: 'plan',
    question: 'faqListQuestion2',
    answer: 'faqListAnswer2',
  },
  {
    id: 'escape',
    question: 'faqListQuestion3',
    answer: 'faqListAnswer3',
  },
  {
    id: 'includedPlan',
    question: 'faqListQuestion4',
    answer: 'faqListAnswer4',
  },
  {
    id: 'trialWork',
    question: 'faqListQuestion5',
    answer: 'faqListAnswer5',
  },
  {
    id: 'isForMe',
    question: 'faqListQuestion6',
    answer: 'faqListAnswer6',
  },
];
