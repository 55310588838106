import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { appAnalytics } from 'analytics';
import { activateOptimize } from './analytics/GoogleAnalytics';
import App from './App';
import {
  SET_ADDON_PRODUCTS,
  SET_PRODUCTS,
} from './bootstrap/product/actions/loadProducts';
import { rootReducer } from './bootstrap/reducers';
import * as serviceWorker from './serviceWorker';
import { getQueryVariable } from './utils';
import './locales/i18n';
import { isIos } from 'utils/utils';
import './index.scss';

const isInAppBrowser =
  navigator.userAgent.indexOf('Instagram') !== -1 ||
  navigator.userAgent.indexOf('FBAN') !== -1 ||
  navigator.userAgent.indexOf('FBAV') !== -1;
const shouldRedirect = getQueryVariable('redirect') === 'true';

if (
  shouldRedirect &&
  isIos(navigator.userAgent) &&
  isInAppBrowser &&
  process.env.REACT_APP_FTP_REDIRECT_URL
) {
  const target_url = new URL(process.env.REACT_APP_FTP_REDIRECT_URL || '');
  target_url.searchParams.append('initial_url', window.location.href);
  window.location.href = target_url.href;
}

export const history = createBrowserHistory();

history.listen(() => {
  activateOptimize();
});

appAnalytics.initialize();

Sentry.init({
  dsn: 'https://9d9ac99cdfa447119f3e1a56c04b504e@o247740.ingest.sentry.io/5693954',
  environment: process.env.REACT_APP_HOST_ENV,
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    '<unknown>',
    'TypeError: Illegal invocation',
    'TypeError: Load failed',
    "Can't find variable: _AutofillCallbackHandler",
    'TimeoutError: Transaction timed out due to inactivity.',
    'function Error() { [native code] }',
    'Firebase: Error (auth/network-request-failed).',
    'FirebaseError: Firebase: Error (auth/network-request-failed).',
    'FirebaseError: Installations: Firebase Installation is not registered.',
    'UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
    'AbortError: AbortError',
    'AbortError: The transaction was aborted, so the request cannot be fulfilled.',
    'Remote Config: Error thrown when writing to storage.',
    'Remote Config: Error thrown when reading from storage.',
    'Remote Config: Fetch client failed to connect to a network. Check Internet connection. Original error: Load failed.',
    'Remote Config: Fetch client failed to connect to a network.',
    "InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
    'UnknownError: Database deleted by request of the user',
    'QuotaExceededError',
    'Error: O',
    'QuotaExceededError',
    'AbortError: AbortError',
    'SecurityError: The operation is insecure.',
    'Remote Config: Error thrown when opening storage.',
    "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
    'UnknownError: Database deleted by request of the user',
    'UnknownError: Error creating Records table (13) - database or disk is full',
    'QuotaExceededError',
    'Failed to fetch',
    "Failed to execute 'createObjectStore' on 'IDBDatabase': The database is not running a version change transaction.",
    'Firebase: Error',
    'Installations: Create Installation request failed with error',
    'UnknownError: Error looking up record in object store by key range',
    "Failed to execute 'transaction' on 'IDBDatabase': One of the specified object stores was not found.",
  ],
  tracesSampleRate: 1.0,
});

const persistConfig = {
  key: 'root',
  storage,
};

const reducer = rootReducer(history);
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          SET_PRODUCTS,
          SET_ADDON_PRODUCTS,
        ],
        ignoredPaths: ['product.products'],
      },
    }).concat(routerMiddleware(history), thunk),
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

const FallbackComponent = () => <div>An error has occurred</div>;

const container = document.getElementById('root') as Element;
const root = ReactDOM.createRoot(container);

root.render(
  <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          {' '}
          {/* place ConnectedRouter under Provider */}
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </Sentry.ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
