import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Forbes } from 'assets/onboarding/logos/forbes.svg';
import { ReactComponent as CNN } from 'assets/onboarding/logos/cnn.svg';
import { ReactComponent as Vogue } from 'assets/onboarding/logos/vogue.svg';
import { ReactComponent as Bazaar } from 'assets/onboarding/logos/bazaar.svg';
import { ReactComponent as TheGuardian } from 'assets/onboarding/logos/theguardian.svg';
import { ReactComponent as GQ } from 'assets/onboarding/logos/gq.svg';
import styles from './styles.module.scss';

export const FeatureSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('featureInTitle')}</div>

      <div className={styles.logosWrapper}>
        <div>
          <CNN />
          <TheGuardian />
          <GQ />
        </div>
        <div>
          <Vogue />
          <Bazaar />
          <Forbes />
        </div>
      </div>
    </div>
  );
};
