import React from 'react';
import { RedeemTemplate } from 'redeem/pages/start/components/redeemtemplate/RedeemTemplate';
import { InputWithButton } from './components/inputwithbutton/InputWithButton';
import { useRedeemCodeLogic } from './RedeemCode.hook';
import styles from './styles.module.scss';

export const RedeemCode = () => {
  const {
    giftCodeApplied,
    giftCode,
    error,
    onGiftCodeChange,
    onContinue,
    onRedeem,
  } = useRedeemCodeLogic();

  return (
    <RedeemTemplate>
      <div className={styles.title}>
        Redeem your <span className={styles.accent}>gift code</span>
      </div>
      <InputWithButton
        value={giftCode}
        placeholder="Gift Code"
        applied={giftCodeApplied}
        error={error}
        onChange={onGiftCodeChange}
        onButtonClick={onRedeem}
      />
      <button
        className={styles.submitButton}
        onClick={onContinue}
        disabled={!giftCodeApplied}
      >
        Continue
      </button>
      <div className={styles.helpSection}>
        Receiving an error message?
        <br />
        <a href="mailto:hello@loona.app">Click here for help</a>
      </div>
    </RedeemTemplate>
  );
};
