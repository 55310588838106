import React, { SyntheticEvent, useEffect, useRef } from 'react';

import Exclamation from 'assets/icon_exclamation.svg';
import './styles.scss';

type Props = {
  visible: boolean;
  error: string;
  onClose: () => void;
};

const Error = ({ visible, error, onClose }: Props) => {
  const alert = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  });

  const close = (e: SyntheticEvent) => {
    e.preventDefault();
    // eslint-disable-next-line
    if (e.target == alert.current) {
      onClose();
    }
  };

  return (
    <div
      ref={alert}
      className={visible ? 'error' : 'error hidden'}
      onClick={close}
    >
      <div className="icon-wrapper">
        <img src={Exclamation} alt="" />
      </div>
      <p className="error-content">{error}</p>
    </div>
  );
};

export default Error;
