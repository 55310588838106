import { fetchServer, toResultPromise } from '../commonnet';
import { ENDPOINTS } from '../endpoints';
import { PaymentInfoResponse } from '../subscription/subscription.types';

export async function activateGiftCode(code: string): Promise<boolean> {
  const result = await fetchServer<PaymentInfoResponse>({
    url: ENDPOINTS.ACTIVATE_GIFT_CARD,
    method: 'POST',
    body: {
      gift_code: code,
    },
  });

  return toResultPromise(result, result.response.purchase?.is_active || false);
}
