import React from 'react';
import { RedeemTemplate } from './components/redeemtemplate/RedeemTemplate';
import { useStartRedeemLogic } from './StartRedeem.hook';
import styles from './styles.module.scss';

export const StartRedeem = () => {
  const { onRedeemClick } = useStartRedeemLogic();

  return (
    <RedeemTemplate>
      <div className={styles.title}>
        Redeem your
        <br />
        <span className={styles.accent}>gift code</span>
      </div>
      <button className={styles.button} onClick={onRedeemClick}>
        Redeem
      </button>
    </RedeemTemplate>
  );
};
