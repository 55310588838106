import { Events } from './Events';

type EventParams = {} | string[] | string;

export type OptionalParams = {
  eventId?: string;
  email?: string;
  zip?: string;
  firstName?: string;
  lastName?: string;
  onboardingType: string;
};

export class Event {
  name: Events;
  params?: EventParams;
  optionalParams?: OptionalParams;

  constructor(
    name: Events,
    params?: EventParams,
    optionalParams?: OptionalParams,
  ) {
    this.name = name;
    this.params = params;
    this.optionalParams = optionalParams;
  }
}
