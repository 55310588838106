import { SyntheticEvent, useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import { ContinueBoardingSkipTrial, ScreenOpened } from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { getSkipTrialProductCode } from 'bootstrap/data/product/Product.utils';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import {
  changeSkipTrialSubscriptionPlan,
  changeSubscriptionWithCreditPlan,
  goToNextPage,
  refundSkipTrialSubscriptionPlan,
  setTrialOfferDiscountApplied,
  setTrialOfferShown,
  skipTrialWithCreditSubscriptionPlan,
} from 'bootstrap/user/actions';
import { isSkipTrialWithoutRefund } from 'navigation';
import { useTranslation } from 'react-i18next';

type Props = {
  defaultProduct: Product;
  isTrialOfferVisible: boolean;
};

export const useTrialOfferLogic = ({
  defaultProduct,
  isTrialOfferVisible,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { products } = useAppSelector((state) => state.product);
  const {
    isTrialOfferDiscountApplied: isDiscountApplied,
    currency,
    planType,
  } = useAppSelector((state) => state.user);

  const discountPercent = 20;

  const promoProduct =
    products?.get && products?.get(getSkipTrialProductCode(planType));

  const [isDiscountPopupShown, setDiscountPopupShown] = useState(false);
  const [totalPrice, setTotalPrice] = useState<string>('');

  const [term, setTerm] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  useEffect(() => {
    return () => {
      document.body.classList.remove('scroll-hidden');
    };
  }, []);

  useEffect(() => {
    if (isTrialOfferVisible) {
      document.body.classList.add('scroll-hidden');
      appAnalytics.trackEvent(
        new ScreenOpened('skipTrial', { discount: 'regular' }),
      );
    } else {
      document.body.classList.remove('scroll-hidden');
    }
  }, [isTrialOfferVisible]);

  useEffect(() => {
    if (!isDiscountApplied) {
      setTotalPrice(
        isSkipTrialWithoutRefund()
          ? defaultProduct.getPriceWithoutTrial(currency)
          : defaultProduct.getFormattedPrice(currency),
      );
    }
  }, [defaultProduct]);

  useEffect(() => {
    if (isDiscountApplied && promoProduct) {
      if (isSkipTrialWithoutRefund()) {
        setTotalPrice(
          defaultProduct.getPriceWithTotalSavings(discountPercent, currency),
        );
      } else {
        setTotalPrice(promoProduct.getFormattedPrice(currency));
      }
      setTerm(promoProduct.getTerm(t));
    }
  }, [promoProduct, isDiscountApplied, defaultProduct]);

  const handleSkipTrialClick = () => {
    setLoading(true);

    const action = isDiscountApplied
      ? changeSkipTrialSubscriptionPlan(promoProduct?.code)
      : refundSkipTrialSubscriptionPlan();

    const actionV2 = isDiscountApplied
      ? changeSubscriptionWithCreditPlan(promoProduct?.code)
      : skipTrialWithCreditSubscriptionPlan();

    const onSuccess = () => {
      dispatch(goToNextPage());
      dispatch(setTrialOfferShown(false));
    };

    const onFailure = ({ message }: { message: string }) => {
      setErrorMessage(message);
      setIsErrorVisible(true);
    };

    dispatch(isSkipTrialWithoutRefund() ? actionV2 : action)
      .then(onSuccess)
      .catch(onFailure)
      .finally(() => {
        setLoading(false);
      });

    appAnalytics.trackEvent(new ContinueBoardingSkipTrial('skipTrial'));
  };

  const handleContinueWithTrialClick = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!isDiscountApplied) {
      setDiscountPopupShown(true);
    } else {
      dispatch(setTrialOfferShown(false));
      dispatch(goToNextPage());
    }
    appAnalytics.trackEvent(new ContinueBoardingSkipTrial('continueWithTrial'));
  };

  const handleContinueClick = () => {
    setDiscountPopupShown(false);
    dispatch(setTrialOfferDiscountApplied(true));
    appAnalytics.trackEvent(
      new ScreenOpened('skipTrial', { discount: '20off' }),
    );
  };

  const handleErrorVisible = () => {
    setIsErrorVisible(false);
  };

  return {
    isLoading,
    errorMessage,
    isErrorVisible,
    term,
    discountPercent,
    isDiscountPopupShown,
    isDiscountApplied,
    totalPrice,
    duration: defaultProduct.getTerm(t, true),
    totalSavings: defaultProduct.getTotalSavings(discountPercent, currency),
    discountAmount: defaultProduct.getDiscountAmount(discountPercent, currency),
    defaultProductPrice: defaultProduct.getFormattedPrice(currency),
    priceWithTrial: defaultProduct.getPriceWithTrial(currency),
    priceWithoutTrial: defaultProduct.getPriceWithoutTrial(currency),
    trialFee: defaultProduct.getFormattedInvoicePrice(currency),
    handleErrorVisible,
    handleContinueWithTrialClick,
    handleContinueClick,
    handleSkipTrialClick,
  };
};
