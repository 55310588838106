import React from 'react';
import { GiftTemplate } from 'giftcards/components/gifttemplate/GiftTemplate';
import { getFormattedPrice } from 'bootstrap/data/giftproduct/GiftCardProduct';
import { GiftCardProducts } from 'bootstrap/data/giftproduct/GiftCardProducts';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import { PlanCard } from '../plancard/PlanCard';
import { ZENDESK_GUIDE_LINK } from '../Plans.constants';
import { usePlanContentLogic } from './PlansContent.hook';
import styles from './styles.module.scss';

type Props = {
  giftCardType: GiftCardTypes;
  products: GiftCardProducts;
  currency?: string;
};

export const PlansContent = ({ giftCardType, products, currency }: Props) => {
  const { giftCardLabel, onContinue, onCardSelect } = usePlanContentLogic({
    products,
    giftCardType,
  });

  const faq = (
    <div className={`${styles.faq} ${styles[giftCardType]}`}>
      Check our <a href={ZENDESK_GUIDE_LINK}>FAQ</a> for more details.
    </div>
  );

  return (
    <GiftTemplate giftCardType={giftCardType} bottomSection={faq}>
      <div className={styles.title}>
        Gift <span className={styles[giftCardType]}>{giftCardLabel}</span>
        {'\n'}
        membership
      </div>
      <div className={styles.description}>
        Help someone you care about stress <br />
        less, feel better and fall asleep faster
      </div>
      <div className={styles.planCards}>
        <PlanCard
          label="Yearly"
          giftCardType={giftCardType}
          price={getFormattedPrice(products.yearly, currency) || ''}
          selected={giftCardType === GiftCardTypes.Yearly}
          onSelect={() => onCardSelect(GiftCardTypes.Yearly)}
        />
        <PlanCard
          label="Lifetime"
          giftCardType={giftCardType}
          price={getFormattedPrice(products.lifetime, currency) || ''}
          selected={giftCardType === GiftCardTypes.Lifetime}
          onSelect={() => onCardSelect(GiftCardTypes.Lifetime)}
        />
      </div>
      <button className={`${styles[giftCardType]}`} onClick={onContinue}>
        Continue
      </button>
    </GiftTemplate>
  );
};
