import { ProductActionTypes } from './productActions.types';

export const SET_PRESALE_PRODUCT = 'SET_PRESALE_PRODUCT';

export function setPresaleProduct(productCode: string): ProductActionTypes {
  return {
    type: SET_PRESALE_PRODUCT,
    payload: {
      productCode,
    },
  };
}
