import { CircadianTypes } from 'onboarding/question/meqresults/MeqResults.constants';
import { getDeviceInfo } from 'utils';
import { fetchServer, toResultPromise } from '../commonnet';
import { ENDPOINTS } from '../endpoints';
import {
  UserInfo,
  GeoInfoRespose,
  RegisterResponse,
  UserInfoResponse,
} from './user.types';

export async function getUserInfo(): Promise<UserInfo> {
  const result = await fetchServer<UserInfoResponse>({
    url: ENDPOINTS.USER_INFO,
  });

  return toResultPromise(result, result.response);
}

export async function register(
  email: string,
  password: string,
  userdata: 'onboarding' | 'redeem' | 'qp' | 'pi' | 'gift',
): Promise<UserInfo> {
  const result = await fetchServer<RegisterResponse>({
    url: ENDPOINTS.EMAIL_REG,
    method: 'POST',
    body: {
      email,
      password,
      confirm: password,
      device: getDeviceInfo(),
      userdata: userdata || 'onboarding',
      action_source: window.location.href,
    },
  });

  if (result.error.code === 0) {
    localStorage.setItem('auth_token', result.response.auth_token);
    return Promise.resolve(result.response);
  } else if (result.error.code === 409) {
    return login(email, password, userdata);
  } else {
    return Promise.reject(
      result.error || {
        debug_text: 'Unknown server error',
      },
    );
  }
}

export async function socialRegister(
  firstName: string,
  lastName: string,
  email: string,
  userId: string,
  accessToken: string,
  platform: 'facebook' | 'google',
): Promise<UserInfo> {
  const result = await fetchServer<RegisterResponse>({
    url: ENDPOINTS.SOCIAL_REG[platform],
    method: 'POST',
    body: {
      first_name: firstName,
      last_name: lastName,
      email: email,
      provider_user_id: userId,
      token: {
        key: accessToken,
      },
      device: getDeviceInfo(),
      userdata: 'onboarding',
      action_source: window.location.href,
    },
  });
  return toResultPromise(
    result,
    Promise.resolve(
      localStorage.setItem('auth_token', result.response.auth_token),
    ).then(() => result.response),
  );
}

export async function login(
  email: string,
  password: string,
  userdata: 'onboarding' | 'redeem' | 'qp' | 'pi' | 'gift',
): Promise<UserInfo> {
  const result = await fetchServer<RegisterResponse>({
    url: ENDPOINTS.EMAIL_LOGIN,
    method: 'POST',
    body: {
      email,
      password,
      device: getDeviceInfo(),
      userdata: userdata || 'onboarding',
      action_source: window.location.href,
    },
  });

  return toResultPromise(
    result,
    Promise.resolve(
      localStorage.setItem('auth_token', result.response.auth_token),
    ).then(() => result.response),
  );
}

export async function noPasswordRegister(
  email: string,
  planCode: string,
  isRestrictMailing?: boolean,
  quizResult?: CircadianTypes,
): Promise<UserInfo> {
  const result = await fetchServer<RegisterResponse>({
    url: ENDPOINTS.NO_PASSWORD_REGISTER,
    method: 'POST',
    body: {
      email,
      device: getDeviceInfo(),
      userdata: 'onboarding',
      action_source: window.location.href,
      marketing_data: {
        restrict_email_sending: isRestrictMailing,
        plan_code: planCode,
        quiz_result: quizResult || '',
      },
    },
  });

  return toResultPromise(
    result,
    Promise.resolve(
      localStorage.setItem('auth_token', result.response.auth_token),
    ).then(() => result.response),
  );
}

export async function getGeoInfo(): Promise<any> {
  const result = await fetchServer<GeoInfoRespose>({
    url: ENDPOINTS.GEO_INFO,
    method: 'GET',
  });

  return toResultPromise(result, result.response);
}
