import { QuestionIds } from '../constants/QuestionIds';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const EducationQuizV1Order: QuestionIds[] = [
  QuestionIds.EduQ1,
  QuestionIds.EduQ1Answer,
  QuestionIds.EduQ2,
  QuestionIds.EduQ2Answer,
  QuestionIds.EduQ3,
  QuestionIds.EduQ3Answer,
  QuestionIds.EduQ4,
  QuestionIds.EduQ4Answer,
  QuestionIds.EduQ5,
  QuestionIds.EduQ5Answer,

  QuestionIds.AboutYouProof1,
  QuestionIds.SleepAndActivityD1,
  QuestionIds.AboutYouGender,
  QuestionIds.AboutYouAge,
  QuestionIds.AboutYouRoutine,
  QuestionIds.AboutYouD2,
  QuestionIds.AboutYouKids,
  QuestionIds.AboutYouD3,
  QuestionIds.AboutYouActivity,
  QuestionIds.AboutYouD4,

  QuestionIds.SleepAndActivityProof2,
  QuestionIds.SleepAndActivityCoffee,
  QuestionIds.SleepAndActivityDinner,
  QuestionIds.SleepAndActivityAfterMidnight,
  QuestionIds.SleepAndActivityLessThanEightHours,
  QuestionIds.SleepAndActivityExtraMotivation,
  QuestionIds.SleepAndActivityD2,
  QuestionIds.SleepAndActivityLoader,

  QuestionIds.PersonalizationD1,
  ...PersonalizationSectionQuestionIds,
];
