import React, { useRef } from 'react';
import { useStickyHeader } from 'hooks/useStickyHeader';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type StickyHeaderProps = {
  startDate: number;
  trialLength: number;
};

export const StickyHeader = ({ startDate }: StickyHeaderProps) => {
  const { t } = useTranslation();
  const timerBox = useRef<HTMLDivElement>(null);
  const { isSticky, time } = useStickyHeader({
    timerBox,
    startDate,
  });

  if (!time) {
    return null;
  }

  return (
    <>
      <div ref={timerBox} className="sticky-container">
        <div className="timer-box">
          <div className="active-timer">{t('stickyHeaderLine1', { time })}</div>
        </div>
      </div>

      {isSticky && (
        <div className="sticky-container sticky-bottom">
          <div className="active-timer">{t('stickyHeaderLine2', { time })}</div>
        </div>
      )}
    </>
  );
};
