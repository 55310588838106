import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../bootstrap/hooks';
import { loadProducts } from '../../../bootstrap/giftpurchase/actions/loadProducts';
import { LoadingScreen } from 'components/loadingscreen/LoadingScreen';
import { PlansContent } from './planscontent/PlansContent';

export const Plans = () => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.user);
  const { products, giftCardType } = useAppSelector((state) => state.purchase);

  const isProductsLoaded = products && Object.keys(products).length !== 0;

  useEffect(() => {
    if (!isProductsLoaded) {
      dispatch(loadProducts(currency));
    }
  }, [dispatch, products]);

  return (
    <>
      <LoadingScreen
        isVisible={!isProductsLoaded}
        title="Loading..."
        description=""
      />
      {isProductsLoaded && (
        <PlansContent
          giftCardType={giftCardType}
          products={products}
          currency={currency}
        />
      )}
    </>
  );
};
