import React from 'react';
import Star from 'assets/icon_star.svg';

const Stars = () => {
  return (
    <div className="stars">
      <img style={{ marginRight: '1px' }} src={Star} alt="" />
      <img style={{ marginRight: '1px' }} src={Star} alt="" />
      <img style={{ marginRight: '1px' }} src={Star} alt="" />
      <img style={{ marginRight: '1px' }} src={Star} alt="" />
      <img src={Star} alt="" />
    </div>
  );
};

export default Stars;
