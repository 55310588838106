import { GiftCardProduct } from 'bootstrap/data/giftproduct/GiftCardProduct';
import { Currency } from 'bootstrap/data/product/Product.types';
import { ResponseError } from '../commonnet.types';
import { PaymentMethods } from 'subscription/components/Payments/Payments.types';

export const YEARLY_PRODUCT_CODE = 'yearly';
export const LIFETIME_PRODUCT_CODE = 'lifetime';

export interface ServerGiftCardProduct {
  name: string;
  currencies: Currency[];
  code: string;
  description: string;
}

export interface ServerGiftCardProducts {
  [YEARLY_PRODUCT_CODE]: ServerGiftCardProduct;
  [LIFETIME_PRODUCT_CODE]: ServerGiftCardProduct;
}

export interface GiftCardProductsListResponse {
  response: {
    items: ServerGiftCardProducts;
  };
  error: ResponseError;
}

export type SubscriptionResponse =
  | ThreeDSecureSubscriptionResponse
  | SuccessSubscriptionResponse;

export interface ThreeDSecureSubscriptionResponse {
  three_d_secure_action_token_id: string;
  error: ResponseError;
}

export interface SuccessSubscriptionResponse {
  pdf_link: string;
  error: ResponseError;
}

export interface GiftCardRequest {
  email: string;
  product: GiftCardProduct;
  quantity: number;
  token: string;
  threeDsToken: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  currency: string | undefined;
  price?: number;
  paymentMethod?: PaymentMethods;
  recaptchaToken: string;
}
