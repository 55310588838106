import { appAnalytics } from 'analytics';
import { ContinueUnfoldPayment, ScreenOpened } from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { isFaq } from 'navigation';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { isApplePayAvailable } from 'subscription/components/Payments/Payments.utlls';
import {
  getMonthDirectProductCode,
  getQuarterDirectProductCode,
  getWeekDirectProductCode,
} from 'bootstrap/data/product/Product.utils';
import { setPlanType } from 'bootstrap/user/actions/setPlanType';
import { PlanSelectType } from './components/plansection/PlanSection.constants';

type Props = {
  defaultProduct: Product;
  products: Map<string, Product>;
  paymentFormRef: RefObject<HTMLDivElement>;
};

export const useSingleProductLogic = ({
  defaultProduct,
  products,
  paymentFormRef,
}: Props) => {
  const dispatch = useAppDispatch();

  const {
    authToken,
    hasSubscription,
    isTrialOfferShown,
    currency,
    isPreventBackPaymentOfferConfirmed,
    planType,
  } = useAppSelector((state) => state.user);

  const [expanded, setExpanded] = useState<boolean>(true);
  const [product, setProduct] = useState<Product>(defaultProduct);

  const weekProductCode = useMemo(() => getWeekDirectProductCode(), [product]);

  const monthProductCode = useMemo(
    () => getMonthDirectProductCode(),
    [product],
  );

  const quarterProductCode = useMemo(
    () => getQuarterDirectProductCode(),
    [product],
  );

  useEffect(() => {
    if (planType === undefined) {
      dispatch(setPlanType(PlanSelectType.MONTH));
    }
  }, [planType]);

  useEffect(() => {
    let productCode = '';

    switch (planType) {
      case PlanSelectType.QUARTER:
        productCode = quarterProductCode;
        break;
      case PlanSelectType.MONTH:
        productCode = monthProductCode;
        break;
      case PlanSelectType.WEEK:
        productCode = weekProductCode;
        break;
      default:
        if (isPreventBackPaymentOfferConfirmed) {
          productCode = 'co-loona-web-ar-q-t7-59';
        } else {
          productCode = defaultProduct.code;
        }
        break;
    }

    if (productCode && products?.get(productCode)) {
      setProduct(products?.get(productCode) as Product);
    } else {
      setProduct(products?.get(defaultProduct.code) as Product);
    }
  }, [
    isPreventBackPaymentOfferConfirmed,
    planType,
    quarterProductCode,
    monthProductCode,
    products,
    product,
  ]);

  useEffect(() => {
    appAnalytics.trackEvent(
      new ScreenOpened('onboardingPayment', {
        isApplePayAvailable: isApplePayAvailable() || {
          reason: {
            paymentRequest: !!window.PaymentRequest,
            applePaySession: !!window.ApplePaySession,
            canMakePayments: !!window.ApplePaySession?.canMakePayments,
          },
        },
      }),
    );
  }, []);

  const scrollToPaymentForm = () => {
    setExpanded(true);
    setTimeout(() => {
      const contentHeightBeforePaymentForm = isFaq() ? 70 : 100;
      const paymentFormOffsetTop = paymentFormRef.current?.offsetTop || 0;
      const topPosition = +(
        paymentFormOffsetTop - contentHeightBeforePaymentForm
      );

      if (paymentFormRef.current) {
        window.scroll({
          top: topPosition,
          behavior: 'smooth',
        });
      }
    }, 1);
  };

  const handleGetPlanClick = () => {
    appAnalytics.trackEvent(new ContinueUnfoldPayment(''));
    scrollToPaymentForm();
  };

  return {
    product,
    isTrialOfferShown,
    weekProduct: products?.get(weekProductCode) || defaultProduct,
    monthProduct: products?.get(monthProductCode) || defaultProduct,
    quarterProduct: products?.get(quarterProductCode) || defaultProduct,
    planType,
    hasSubscription,
    authToken,
    expanded,
    currencyCode: currency,
    handleGetPlanClick,
  };
};
