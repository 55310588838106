import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { Success } from './pages/success/Success';
import { PlansSetup } from './pages/planssetup/PlansSetup';
import { Purchase } from './pages/purchase/Purchase';
import { Plans } from './pages/plans/Plans';
import styles from './styles.module.scss';

export const GiftCards = () => {
  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);
    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  }, []);

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 1 }}
      atActive={{ opacity: 1 }}
      className={`switch-wrapper ${styles.dark}`}
    >
      <Route exact path="/gift/setup" component={PlansSetup} />
      <Route exact path="/gift/purchase" component={Purchase} />
      <Route exact path="/gift/done" component={Success} />
      <Route exact path="/gift" component={Plans} />
    </AnimatedSwitch>
  );
};
