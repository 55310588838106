import React from 'react';
import Spacer from 'components/spacer/Spacer';
import { Advantages } from './components/advantages/Advantages';
import { useAppDispatch } from 'bootstrap/hooks';
import { pushWithSearchQuery } from 'utils';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';

import styles from './styles.module.scss';

export const Preplan = () => {
  const dispatch = useAppDispatch();

  const onContinueClickHandler = () => {
    dispatch(pushWithSearchQuery('/subscription'));
  };

  return (
    <div className={`${styles.container} maxWidth`}>
      <h4>
        Your personalised
        <br /> 12 weeks plan
      </h4>
      <div className={styles.description}>
        Based on your answers we designed a plan to help you improve your sleep
        and build healthy habits
      </div>
      <Advantages />
      <Spacer />
      <StickyContainer className={styles.stickyContainer}>
        <button className={styles.button} onClick={onContinueClickHandler}>
          Continue
        </button>
      </StickyContainer>
    </div>
  );
};
