import { Answers } from 'bootstrap/onboarding/state';
import { useTranslation } from 'react-i18next';
import { QuestionIds } from './QuestionIds';

interface Placeholder {
  default: string;
  [key: string]: string;
}

const Placeholders: Record<string, Placeholder> = {
  [QuestionIds.AboutYouGender]: {
    female: 'women',
    male: 'men',
    default: 'people',
  },
  [QuestionIds.AboutYouAge]: {
    '20s': 'in their 20s',
    '30s': 'in their 30s',
    '40s': 'in their 40s',
    '50s': 'in their 50s',
    '60s': 'in their 60s',
    default: '',
  },
};

export const placeholderFormatter = (
  answers: Answers,
  placeholder: string,
): string => {
  const { t } = useTranslation();

  if (Placeholders.hasOwnProperty(placeholder)) {
    // @ts-ignore
    const answer = answers[placeholder];
    const placeholders = Placeholders[placeholder];
    return placeholders[answer]
      ? t(placeholders[answer])
      : t(placeholders['default']);
  } else {
    return '';
  }
};
