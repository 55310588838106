import { isSleepActivityLoaderV2 } from 'navigation';
import React from 'react';
import styles from './styles.module.scss';

type Props = {
  progress: number;
};

export const ProgressBar = ({ progress }: Props) => {
  return (
    <div
      className={`
        ${styles.container} 
        ${isSleepActivityLoaderV2() ? styles.v2 : ''}`}
    >
      <div className={styles.percent}>
        <div>{progress > 0 ? Math.floor(progress * 100) + '%' : ''}</div>
      </div>
      <div className={styles.background}>
        <div
          className={styles.progress}
          style={{ width: `${progress * 100}%` }}
        />
      </div>
    </div>
  );
};
