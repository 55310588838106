import React from 'react';
import Delete from 'assets/ic_delete.svg';
import { LoadingButton } from 'components/loadingbutton/LoadingButton';
import { PasswordInput } from 'components/passwordinput/PasswordInput';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import { FacebookLoginButton } from 'components/facebookbutton/FacebookLoginButton';
import { useRedeemAccountLogic } from './RedeemAccount.hook';
import styles from './styles.module.scss';

export const RedeemAccount = () => {
  const {
    isLoading,
    isLargeScreen,
    errorState,
    passwordState,
    emailState,
    setErrorState,
    setEmailState,
    navigateNext,
    handleSubmit,
    onSocialError,
    onEmailChange,
    onPasswordChange,
  } = useRedeemAccountLogic();

  const content = (
    <div className={styles.contentContainer}>
      <div className={styles.title}>Sign up</div>
      <div className={styles.description}>
        Create a free account or sign in with your existing account to redeem
        your Loóna gift code
      </div>
      <FacebookLoginButton
        onError={onSocialError}
        onSuccess={navigateNext}
        className={styles.socialLoginButton}
      />
      <form className={styles.formContainer} onSubmit={handleSubmit} noValidate>
        <div className={styles.inputContainer}>
          <input
            type="email"
            className={
              emailState.error !== '' && emailState.value !== ''
                ? 'invalid'
                : ''
            }
            value={emailState.value}
            placeholder="E-mail"
            onChange={onEmailChange}
          />
          {emailState.value && (
            <img
              src={Delete}
              alt="x"
              className={styles.delete}
              onClick={() => setEmailState({ ...emailState, value: '' })}
            />
          )}
        </div>

        <div className={styles.errorLabel}>{emailState.error}</div>

        <PasswordInput
          value={passwordState.value}
          isValid={!passwordState.error}
          dark={true}
          onChange={onPasswordChange}
        />

        <div className={styles.errorLabel}>{passwordState.error}</div>

        <span className={styles.legal}>
          By continuing, you agree to Loóna&#39;s
          <br />
          <a href="https://loona.app/terms.html">Terms of Use</a> &{' '}
          <a href="https://loona.app/privacy.html">Privacy Policy</a>
        </span>

        <LoadingButton
          disabled={
            emailState.value === '' ||
            emailState.error !== '' ||
            passwordState.value === '' ||
            passwordState.error !== ''
          }
          isLoading={isLoading}
          label="Continue"
          onClick={handleSubmit}
        />

        <p className={styles.disclaimer}>
          Your information is 100% secure. Loóna does not share any personal
          information. By submitting your email address, you may also receive
          emails from Loóna about new offers, features and updates. You may
          unsubscribe at any time.
        </p>
      </form>
    </div>
  );

  return (
    <>
      <ErrorPopup
        visible={errorState.isVisible}
        error={errorState.error}
        onClose={() => setErrorState({ ...errorState, isVisible: false })}
      />
      {isLargeScreen ? (
        <div className={styles.bigBackgroundContainer}>{content}</div>
      ) : (
        content
      )}
    </>
  );
};
