import React from 'react';
import styles from './styles.module.scss';

type Props = {
  icon: JSX.Element | JSX.Element[];
  title: string;
  subtitle: string;
};

export const Label: React.FC<Props> = ({ icon, title, subtitle }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        {icon}
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  );
};
