export class PlaceOption {
  id: string;
  icon: any;
  text: string;

  constructor(id: string, icon: any, text: string) {
    this.id = id;
    this.icon = icon;
    this.text = text;
  }
}
