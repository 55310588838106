import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { emailLogin, registerNoPassword } from 'bootstrap/user/actions';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import { ViewPIScreen } from 'invitepurchase/analytics';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { pushWithSearchQuery, validateEmail } from 'utils';
import {
  EMAIL_VALIDATION_ERROR,
  PASSWORD_VALIDATION_ERROR,
} from 'utils/utils.constants';

export const useSignInLogic = () => {
  const dispatch = useAppDispatch();
  const { inviteCodeId } = useParams<{ inviteCodeId: string }>();

  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  const [emailState, setEmailState] = useState({
    value: '',
    error: '',
  });

  const [passwordState, setPasswordState] = useState({
    value: '',
    error: '',
    isVisible: false,
  });

  const [errorState, setErrorState] = useState({
    error: '',
    isVisible: false,
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    appAnalytics.trackEvent(new ViewPIScreen('piAccount'));
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setErrorState({ ...errorState, isVisible: false });

    if (!isLoading && validateForm()) {
      setIsLoading(true);
      let action;
      if (passwordState.value !== '') {
        action = emailLogin(emailState.value, passwordState.value, 'pi');
      } else {
        action = registerNoPassword(emailState.value, '');
      }

      dispatch(action)
        .then(() => {
          setIsLoading(false);
          dispatch(pushWithSearchQuery(`/invite/${inviteCodeId}/payment`));
        })
        .catch((message) => {
          setIsLoading(false);
          if (message?.email) {
            setEmailState({ ...emailState, error: EMAIL_VALIDATION_ERROR });
          } else if (message?.password || message === 'WRONG_PASSWORD') {
            setPasswordState({
              ...passwordState,
              error: PASSWORD_VALIDATION_ERROR,
            });
          } else if (message === 'DATA_EXIST') {
            setPasswordState({
              ...passwordState,
              isVisible: true,
            });
          } else {
            setErrorState({
              ...errorState,
              error: message,
              isVisible: true,
            });
          }
        });
    }
  };

  const validateForm = (): boolean => {
    const isEmailValid = validateEmail(emailState.value);
    const isPasswordValid = passwordState.value !== '';

    if (!isEmailValid) {
      setEmailState({
        ...emailState,
        error: EMAIL_VALIDATION_ERROR,
      });
      return false;
    } else if (passwordState.isVisible && !isPasswordValid) {
      setPasswordState({
        ...passwordState,
        error: PASSWORD_VALIDATION_ERROR,
      });
      return false;
    } else return true;
  };

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isValid = validateEmail(event.target.value);

    setEmailState({
      ...emailState,
      error: isValid ? '' : 'Please enter a valid email',
      value: event.target.value,
    });

    setErrorState({ ...errorState, isVisible: false });
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState({
      ...passwordState,
      error:
        event.target.value.length < 8
          ? 'Password should be at least 8 characters'
          : '',
      value: event.target.value,
    });

    setErrorState({ ...errorState, isVisible: false });
  };

  return {
    isLargeScreen,
    isLoading,
    emailState,
    passwordState,
    errorState,
    setEmailState,
    onPasswordChange,
    onEmailChange,
    setErrorState,
    handleSubmit,
  };
};
