import { appAnalytics } from 'analytics';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import {
  cancelSubscriptionPlan,
  prolongSubscriptionPlan,
} from 'bootstrap/user/actions';
import {
  ContinueSubscriptionCancel,
  SubscriptionProlongSuccess,
} from 'cancelsubscription/analytics';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { getTrialExpiredDate } from 'cancelsubscription/utils';
import { push } from 'connected-react-router';
import { useMemo, useState } from 'react';

export const useExtendTrialLogic = () => {
  const dispatch = useAppDispatch();

  const { subscriptonExpiredDate } = useAppSelector((state) => state.user);
  const [error, setError] = useState<string>('');

  const trialExpiredDate = useMemo(
    () => getTrialExpiredDate(subscriptonExpiredDate, 15),
    [subscriptonExpiredDate],
  );

  const cancelSubscription = () => {
    dispatch(cancelSubscriptionPlan())
      .then(() => {
        appAnalytics.trackEvent(new ContinueSubscriptionCancel());
        dispatch(push(QuestionIds.FinishUserLost));
      })
      .catch((error) => setError(error));
  };

  const prolongSubscription = () => {
    const duration = 15;

    dispatch(prolongSubscriptionPlan(duration))
      .then(() => {
        appAnalytics.trackEvent(new SubscriptionProlongSuccess(duration));
        dispatch(push(QuestionIds.Loader));
      })
      .catch((error) => {
        setError(getErrorMessage(error));
      });
  };

  return {
    trialExpiredDate,
    error,
    setError,
    prolongSubscription,
    cancelSubscription,
  };
};
