export const isFormFieldNotEmpty = (value: string) => {
  return value.length > 0;
};

export const isFullNameValid = (value: string): boolean => {
  const regex = /^[a-z]+ [a-z]+$/i;
  const matchesPattern = regex.test(value.trim());

  return matchesPattern;
};

export const isPostalCodeShown = (countryCode: string | undefined) => {
  const requirePostCodeCountriesCodes = ['US', 'CA', 'IN'];
  if (!countryCode) return true;

  return requirePostCodeCountriesCodes.includes(countryCode);
};
