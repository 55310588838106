import { Info } from 'onboarding/question/info/Info';
import { QuestionIds } from './constants/QuestionIds';
import {
  OptionalQuestion,
  OptionQuestion,
} from 'onboarding/question/options/OptionQuestion';
import { isAdhdTest, isDefaultOnboarding, isGadTest } from 'navigation';
import { TextOption } from 'onboarding/question/options/TextOption';

export const getAboutYouD4MetaData = () => {
  if (isGadTest()) {
    return new Info(
      QuestionIds.AboutYouD4,
      'AboutYouD4Title',
      'AboutYouD4Description',
    );
  }
  if (isAdhdTest()) {
    return new Info(
      QuestionIds.AboutYouD4,
      'AboutYouD4TitleV2',
      'AboutYouD4DescriptionV2',
    );
  }
  return new Info(
    QuestionIds.AboutYouD4,
    'AboutYouD4V2Title',
    'AboutYouD4Description',
  );
};

export const getSleepAndActivityD1MetaData = () => {
  if (isDefaultOnboarding()) {
    return new OptionalQuestion(
      QuestionIds.Welcome,
      QuestionIds.SleepAndActivityD1,
      {
        sleep: new Info(
          QuestionIds.SleepAndActivityD1,
          'SleepAndActivityD1SleepTitle',
          'SleepAndActivityD1SleepAndStressDescription',
        ),
        stress: new Info(
          QuestionIds.SleepAndActivityD1,
          'SleepAndActivityD1StressTitle',
          'SleepAndActivityD1SleepAndStressDescription',
        ),
      },
    );
  }
  if (isAdhdTest()) {
    return new OptionalQuestion(
      QuestionIds.Welcome,
      QuestionIds.SleepAndActivityD1,
      {
        sleep: new Info(
          QuestionIds.SleepAndActivityD1,
          'SleepAndActivityD1SleepTitle',
          'SleepAndActivityD1V2Description',
        ),
        stress: new Info(
          QuestionIds.SleepAndActivityD1,
          'SleepAndActivityD1StressTitle',
          'SleepAndActivityD1V2Description',
        ),
      },
    );
  }
  return new Info(
    QuestionIds.SleepAndActivityD1,
    'SleepAndActivityD1Title',
    `${
      isGadTest()
        ? `SleepAndActivityD1DescriptionGad`
        : `SleepAndActivityD1Description`
    }`,
  );
};

export const getAboutYourRoutineMetaData = () => {
  if (isGadTest()) {
    return new OptionQuestion(
      QuestionIds.AboutYouRoutine,
      'AboutYourRotineMentalHealth',
      [
        new TextOption('no_routine', 'no_routine'),
        new TextOption('some_routine', 'some_routine'),
        new TextOption('mindful_routine', 'mindful_routine'),
      ],
    );
  }
  if (isAdhdTest()) {
    return new OptionQuestion(
      QuestionIds.AboutYouRoutine,
      'AboutYourRotineSleepV2',
      [
        new TextOption('no_routine', 'no_routine'),
        new TextOption('some_routine', 'some_routine'),
        new TextOption('mindful_routine', 'mindful_routine'),
      ],
    );
  }
  return new OptionQuestion(
    QuestionIds.AboutYouRoutine,
    'AboutYourRotineSleep',
    [
      new TextOption('no_routine', 'no_routine'),
      new TextOption('some_routine', 'some_routine'),
      new TextOption('mindful_routine', 'mindful_routine'),
    ],
  );
};
