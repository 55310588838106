import { getLocaleDateString } from 'utils/utils';

type ReviewProps = {
  userInfo: string;
  reviewText: string;
  date: string;
};

export const ReviewsData: Record<string, ReviewProps> = {
  male: {
    userInfo: 'Alex, 31',
    reviewText: 'SleepAndActivityProof2ManReview',
    date: getLocaleDateString(new Date(2021, 8, 7), {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
  },
  female: {
    userInfo: 'Ami, 27',
    reviewText: 'SleepAndActivityProof2WomanReview',
    date: getLocaleDateString(new Date(2021, 8, 12), {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    }),
  },
};
