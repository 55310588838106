import { useEffect, useState } from 'react';
import { isCookiesAccepted } from 'utils';

export const useCookieConsentLogic = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isCookiesAccepted()) {
      setIsVisible(true);
    }
  }, []);

  const onClose = () => {
    setIsVisible(false);
    localStorage.setItem('cookies-accepted', 'true');
  };

  return {
    isVisible,
    onClose,
  };
};
