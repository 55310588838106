import React from 'react';
import styles from './styles.module.scss';

type Props = {
  isDividerWithMargin?: boolean;
  isDividerWithNoMargin?: boolean;
  darkTheme?: boolean;
};

const Divider = ({
  darkTheme,
  isDividerWithMargin,
  isDividerWithNoMargin,
}: Props) => {
  return (
    <div
      className={`
        ${`${styles.divider} ${darkTheme ? styles.darkTheme : ''}`} 
        ${isDividerWithMargin ? styles.dividerWithMargin : ''}
        ${isDividerWithNoMargin ? styles.dividerWithNoMargin : ''}
      `}
    />
  );
};

export default Divider;
