import { UserActionTypes } from './userActions.types';

export const SET_TRIAL_OFFER_SHOWN = 'SET_TRIAL_OFFER_SHOWN';
export const SET_TRIAL_OFFER_DISCOUNT_APPLIED =
  'SET_TRIAL_OFFER_DISCOUNT_APPLIED';

export function setTrialOfferShown(shown: boolean): UserActionTypes {
  return {
    type: SET_TRIAL_OFFER_SHOWN,
    payload: {
      isTrialOfferShown: shown,
    },
  };
}

export function setTrialOfferDiscountApplied(
  isDiscountApplied: boolean,
): UserActionTypes {
  return {
    type: SET_TRIAL_OFFER_DISCOUNT_APPLIED,
    payload: {
      isTrialOfferDiscountApplied: isDiscountApplied,
    },
  };
}
