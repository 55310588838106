import { Answers } from 'bootstrap/onboarding/state';
import { placeholderFormatter } from 'onboarding/data/constants/Placeholders';

export const format = (title: string, answers: Answers) => {
  const capitalize = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);
  const removeBraces = (text: string) => text.replace('{', '').replace('}', '');
  const replacer = (value: string): string => {
    const placeholder = removeBraces(value);
    const formatPlaceholder = placeholderFormatter(answers, placeholder);
    // @ts-ignore
    return formatPlaceholder ?? answers[placeholder];
  };
  return capitalize(title.replace(/{(\w*)}/g, replacer));
};
