import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { InsomniaResult, InsomniaTypes } from './InsomniaResults.types';

export const MappedInsomniaAnswersWeight: Record<string, number> = {
  very_satisfied: 0,
  satisfied: 1,
  moderately_satisfied: 2,
  dissatisfied: 3,
  very_dissatisfied: 4,
  not_at_all: 0,
  little: 1,
  somewhat: 2,
  much: 3,
  very_much: 4,
};

export const InsomniaQuestionIds: QuestionIds[] = [
  QuestionIds.IsiQ1,
  QuestionIds.IsiQ2,
  QuestionIds.IsiQ3,
  QuestionIds.IsiQ4,
];

export const InsomniaResultData: Record<InsomniaTypes, InsomniaResult> = {
  [InsomniaTypes.Minimal]: {
    description:
      'You are doing great job. Together with Loóna you could understand how your internal clock works and improve your sleep quality',
  },
  [InsomniaTypes.Mild]: {
    description:
      'You sleep pretty well but several factors are disturbing your sleep. \n\nTogether with Loóna you could understand how your internal clock works and improve your sleep quality.',
  },
  [InsomniaTypes.Moderate]: {
    description:
      'Your symptoms might be a result of moderate insomnia. Together with Loóna you could understand how your internal clock works and improve your sleep quality',
  },
  [InsomniaTypes.Severe]: {
    description:
      'Your symptoms might be a result of severe insomnia.  Together with Loóna you could understand how your internal clock works and improve your sleep quality',
  },
};
