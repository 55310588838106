import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { Answers } from 'bootstrap/onboarding/state';
import { useSubmit } from 'hooks/useSubmit';
import { SyntheticEvent, useEffect, useState } from 'react';
import { pushWithSearchQuery } from 'utils';
import {
  CircadianTypes,
  RmeqResult,
  RmeqResultData,
  MappedRmeqAnswersWeight,
} from './MeqResults.constants';

interface Props {
  rmeqTestResult: RmeqResult;
  nextButtonHandler: (args?: any) => void;
  type: CircadianTypes;
}

export const useRmeqTestLogic = (): Props => {
  const dispatch = useAppDispatch();

  const { answers } = useAppSelector((state) => state.onboarding);
  const [result, setResult] = useState(0);

  useEffect(() => {
    setResult(getRmeqTestResult(answers));
  }, [answers]);

  const nextButtonHandler = useSubmit((e: SyntheticEvent) => {
    e.preventDefault();
    setTimeout(() => {
      dispatch(pushWithSearchQuery('/done'));
    }, 250);
  });

  return {
    rmeqTestResult: RmeqResultData[getCircadianType(result)],
    type: getCircadianType(result),
    nextButtonHandler,
  };
};

export const getRmeqTestResult = (answers: Answers): number => {
  let result = 0;
  Object.values(answers).forEach((answer) => {
    if (typeof answer === 'string') {
      if (MappedRmeqAnswersWeight[answer]) {
        result += MappedRmeqAnswersWeight[answer];
      }
    }
  });
  return result;
};

export const getCircadianType = (result: number): CircadianTypes => {
  if (result >= 4 && result <= 11) {
    return CircadianTypes.Evening;
  } else if (result >= 12 && result <= 17) {
    return CircadianTypes.Neutral;
  } else if (result >= 18 && result <= 25) {
    return CircadianTypes.Morning;
  } else {
    return CircadianTypes.Evening;
  }
};
