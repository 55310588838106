import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import SignIn from './pages/signin/SignIn';
import { Success } from './pages/success/Success';
import { Payment } from './pages/payment/payment/Payment';
import styles from './styles.module.scss';

export const QuickPurchase = () => {
  useEffect(() => {
    document.body.classList.add(styles.whiteBodyBackground);

    return () => {
      document.body.classList.remove(styles.whiteBodyBackground);
    };
  }, []);

  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 1 }}
      atActive={{ opacity: 1 }}
      className={`switch-wrapper ${styles.white}`}
    >
      <Route exact path="/quick-purchase/login">
        <SignIn />
      </Route>
      <Route exact path="/quick-purchase">
        <Payment />
      </Route>
      <Route exact path="/quick-purchase/payment">
        <Payment />
      </Route>
      <Route exact path="/quick-purchase/done">
        <Success />
      </Route>
    </AnimatedSwitch>
  );
};
