import React from 'react';
import { PlaceQuestion } from './PlaceQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { usePlaceComponentLogic } from './PlaceQuestion.hook';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<PlaceQuestion>;

export const PlaceQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const { activeOptions, optionStyle, nextHandler, optionHandler } =
    usePlaceComponentLogic(question);
  const { t } = useTranslation();

  return (
    <div
      className={`
        ${styles.container}   
        ${styles.speed}  
        maxWidth
      `}
    >
      <h2 className={styles.title}>{t(question.title)}</h2>
      <h3 className={styles.description}>{t(question.description)}</h3>
      <div className={`${styles.options} ${styles[optionStyle]}`}>
        {question.options.map((option) => (
          <div
            key={option.id}
            className={`
              ${styles.option}     
              ${activeOptions.includes(option.id) ? styles.active : ''}
            `}
            onClick={optionHandler(option)}
          >
            <option.icon />
            <div className={styles.optionTitle}>{option.text}</div>
          </div>
        ))}
      </div>

      <Spacer />
      {activeOptions.length > 0 && (
        <button className={styles.button} onClick={nextHandler}>
          {t('Next')}
        </button>
      )}
    </div>
  );
};
