import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import {
  cancelSubscriptionPlan,
  changeSubscriptionPlan,
} from 'bootstrap/user/actions';
import {
  ContinueSubscriptionCancel,
  SubscriptionSwitchSuccess,
} from 'cancelsubscription/analytics';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { push } from 'connected-react-router';
import { useState } from 'react';

export const useOfferLogic = () => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<string>('');

  const cancelSubscription = () => {
    dispatch(cancelSubscriptionPlan())
      .then(() => {
        appAnalytics.trackEvent(new ContinueSubscriptionCancel());
        dispatch(push(QuestionIds.FinishUserLost));
      })
      .catch((error) => setError(error));
  };

  const changeSubscription = () => {
    const planCode = 'co-loona-web-ar-q-t0-1-c99-59';

    dispatch(changeSubscriptionPlan(planCode))
      .then(() => {
        appAnalytics.trackEvent(new SubscriptionSwitchSuccess(planCode));
        dispatch(push(QuestionIds.Loader));
      })
      .catch((error) => setError(getErrorMessage(error)));
  };

  return {
    error,
    setError,
    changeSubscription,
    cancelSubscription,
  };
};
