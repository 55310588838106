import { QuestionIds } from '../constants/QuestionIds';
import { Steps } from '../constants/StepsLabels';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const DefaultOnboardingSteps: Map<Steps, QuestionIds[]> = new Map([
  [
    Steps.AboutYou,
    [
      QuestionIds.AboutYouGuided,
      QuestionIds.AboutYouGender,
      QuestionIds.AboutYouAge,
      QuestionIds.AboutYouRoutine,
      QuestionIds.AboutYouKids,
      QuestionIds.AboutYouActivity,
      QuestionIds.AboutYouProof1,
    ],
  ],
  [
    Steps.SleepAndActivity,
    [
      QuestionIds.SleepAndActivityQ1,
      QuestionIds.SleepAndActivityQ2,
      QuestionIds.SleepAndActivityQ3,
      QuestionIds.SleepAndActivityProof1,
      QuestionIds.SleepAndActivityCoffee,
      QuestionIds.SleepAndActivityDinner,
      QuestionIds.SleepAndActivityAfterMidnight,
      QuestionIds.SleepAndActivityLessThanEightHours,
      QuestionIds.SleepAndActivityExtraMotivation,
    ],
  ],
  [Steps.Personalization, PersonalizationSectionQuestionIds],
]);
