import { Reducer } from 'redux';
import { SubscriptionCancelActionTypes } from './actions/types';
import { SET_ANSWER, SET_CURRENT_QUESTION } from './actions/answer';
import { SubscriptionCancelState } from './state';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { QuestionsOrder } from 'cancelsubscription/data/constants/QuestionOrder';

const INITIAL_STATE: SubscriptionCancelState = {
  answers: {
    [QuestionIds.Welcome]: undefined,
    [QuestionIds.Reason]: undefined,
    [QuestionIds.HowLikely]: undefined,
    [QuestionIds.Offer]: undefined,
    [QuestionIds.Extend]: undefined,
    [QuestionIds.FinishWinBack]: undefined,
    [QuestionIds.Loader]: undefined,
    [QuestionIds.FinishUserLost]: undefined,
  },
  currentStep: QuestionsOrder[0],
};

export const subscriptionCancelReducer: Reducer<
  SubscriptionCancelState,
  SubscriptionCancelActionTypes
> = (state = INITIAL_STATE, action: SubscriptionCancelActionTypes) => {
  switch (action.type) {
    case SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.question]: action.payload.answer,
        },
      };
    case SET_CURRENT_QUESTION: {
      return {
        ...state,
        currentStep: action.question,
      };
    }

    default:
      return state;
  }
};
