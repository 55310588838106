import Spacer from 'components/spacer/Spacer';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocaleIntervalTimeString } from 'utils/utils';
import { useRmeqTestLogic } from './MeqResults.hook';
import styles from './styles.module.scss';

export const MeqResults = () => {
  const { t } = useTranslation();
  const { rmeqTestResult, nextButtonHandler } = useRmeqTestLogic();

  const { title, description, bedTime, wakeUpTime } = rmeqTestResult;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.label}>{t('RMeqResultTitle')}</div>
        <div className={styles.title}>{t(title)}</div>
        <div className={styles.description}>{t(description)}</div>
        <div className={`${styles.cardContainer} ${styles.bedTimeCard}`}>
          <div className={styles.label}>{t('RMeqResultLabel1')}</div>
          <div className={styles.time}>
            {getLocaleIntervalTimeString(bedTime)}
          </div>
        </div>
        <div className={`${styles.cardContainer} ${styles.wakeUpCard}`}>
          <div className={styles.label}>{t('RMeqResultLabel2')}</div>
          <div className={styles.time}>
            {getLocaleIntervalTimeString(wakeUpTime)}
          </div>
        </div>
        <div className={styles.figcaption}>{t('RMeqResultFigcaption')}</div>
        <Spacer />
        <div className={styles.note}>{t('RmeqResultNote')}</div>
        <button className={styles.button} onClick={nextButtonHandler}>
          {t('RmeqResultStartPlanButton')}
        </button>
      </div>
    </div>
  );
};
