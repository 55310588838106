import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useTranslation } from 'react-i18next';
import { Products } from 'bootstrap/data/product/Product.types';
import styles from './styles.module.scss';

type Props = {
  products: Products;
  product: Product;
  currencyCode?: string;
};

export const PaymentChecklist = ({
  products,
  product,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.paymentCheckList}>
      {product === products.default.extended_trial ? (
        <li>
          {t('paymentChecklistLine1', { trialLength: product.trialLength })}{' '}
          {product.getFormattedInvoicePrice(currencyCode)}
        </li>
      ) : (
        <li>
          {t('paymentChecklistLine3', { trialLength: product.trialLength })}
        </li>
      )}
      <li>{t('paymentChecklistLine2')}</li>
    </ul>
  );
};
