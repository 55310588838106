import { getErrorMessage } from 'bootstrap/net/commonnet';
import { getProducts } from 'bootstrap/net/product';
import { Thunk } from 'bootstrap/types';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ADDON_PRODUCTS = 'SET_ADDON_PRODUCTS';

export const loadProducts = (): Thunk => async (dispatch, _) => {
  return getProducts()
    .then((p) => {
      dispatch({
        type: SET_PRODUCTS,
        payload: {
          products: p,
        },
      });
    })
    .catch((error) => {
      return Promise.reject(getErrorMessage(error));
    });
};
