import React from 'react';
import { useAppSelector } from 'bootstrap/hooks';
import { DEFAULT_ANSWERS } from 'onboarding/data/constants/DefaultAnswers';
import { getQuestionComponent } from '../helper';
import { OptionalQuestion } from '../options/OptionQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';

type Props = QuestionComponentProps<OptionalQuestion>;

export const OptionalComponent: React.FC<Props> = ({ question }: Props) => {
  const { answers } = useAppSelector((state) => state.onboarding);
  const answer =
    answers[question.sourceQuestionId] ||
    DEFAULT_ANSWERS[question.sourceQuestionId];
  const finalQuestion =
    question.options[answer as string] ?? Object.values(question.options)[0];

  const Component = getQuestionComponent(finalQuestion);
  return <Component question={finalQuestion} />;
};
