import { useAppSelector } from 'bootstrap/hooks';
import {
  SubscriptionCancelFlow,
  getSubscriptionCancelFlow,
} from 'cancelsubscription/data/constants/subscriptionCancelFlow';
import { getTrialExpiredDate } from 'cancelsubscription/utils';
import { useMemo } from 'react';
import { getOneLink } from 'utils';

export const useFinishLogic = () => {
  const { answers } = useAppSelector((state) => state.subscriptionCancel);
  const { subscriptionStatus, subscriptonExpiredDate } = useAppSelector(
    (state) => state.user,
  );

  const onGotItHandler = () => {
    window.location.href = getOneLink({
      mediaSource: 'web_cancel',
      campaign: 'retained',
      experience: 'custom',
    });
  };

  const title = useMemo(() => {
    const subscriptionCancelFlow = getSubscriptionCancelFlow(
      answers,
      subscriptionStatus,
    );

    if (subscriptionCancelFlow === SubscriptionCancelFlow.ExtendTrial) {
      return "We've got you covered!";
    }
    return 'Great news!';
  }, [answers]);

  const description = useMemo(() => {
    const trialExpiredDate = getTrialExpiredDate(subscriptonExpiredDate);
    const trialExpiredDateWithExtend = getTrialExpiredDate(
      subscriptonExpiredDate,
      15,
    );

    const flow = getSubscriptionCancelFlow(answers, subscriptionStatus);

    switch (flow) {
      case SubscriptionCancelFlow.ExtendTrial: {
        return `Your trial has been extended until ${trialExpiredDateWithExtend} and we can't wait to see you back in the Loóna App.`;
      }
      case SubscriptionCancelFlow.Expensive: {
        return "We're glad that you've decided to say with us and we'll work hard to make sure you're happy with your decision";
      }
      default:
        return `We're thrilled you decided to stick with us.  Your subscription will renew on ${trialExpiredDate} at $1 / per 3 months`;
    }
  }, [answers]);

  return { title, description, onGotItHandler };
};
