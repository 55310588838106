import React from 'react';
import { appAnalytics } from 'analytics';
import { InstallQR } from 'analytics/AllEvents';
import AppStore from 'assets/app_store.svg';
import { useAppSelector } from 'bootstrap/hooks';
import { getOneLink, isDevEnv } from 'utils';

export const AppStoreButton = () => {
  const { authToken } = useAppSelector((state) => state.user);

  const handleClick = () => {
    window.location.href = getOneLink({
      mediaSource: isDevEnv() ? 'web_payments_dev' : 'web_payments',
      webDp:
        'https://apps.apple.com/us/app/loóna-calm-relax-and-sleep/id1465238901',
      deepLink: authToken,
      campaign: 'onboarding-ios',
      retargeting: 'true',
      androidUrl:
        'https://apps.apple.com/us/app/loóna-calm-relax-and-sleep/id1465238901',
    });
    appAnalytics.trackEvent(new InstallQR('ios', authToken || false));
  };
  return (
    <a onClick={handleClick}>
      <img src={AppStore} alt="Download Loóna App" />
    </a>
  );
};
