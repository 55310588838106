import { Product } from 'bootstrap/data/product/Product';
import {
  convertToProduct,
  convertToProductMap,
} from 'bootstrap/data/product/Product.utils';
import { fetchServer, toResultPromise } from '../commonnet';
import { ENDPOINTS } from '../endpoints';
import { PricesResponse, ProductResponse } from './product.types';

export async function getProducts(): Promise<Map<string, Product>> {
  const result = await fetchServer<PricesResponse>({
    url: ENDPOINTS.PRODUCTS,
    method: 'GET',
    body: null,
    retries: 2,
  });

  return toResultPromise(result, convertToProductMap(result.response.plans));
}

export async function getBundle(id: string): Promise<Product> {
  const params = new URLSearchParams({ bundle_name: id });
  const result = await fetchServer<ProductResponse>({
    url: ENDPOINTS.BUNDLE + '?' + params,
    method: 'GET',
    body: null,
    retries: 2,
  });

  return toResultPromise(result, convertToProduct(result.response));
}
