import React from 'react';
import styles from './styles.module.scss';

type Props = {
  iconSrc: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  className: string;
};

export const AdvantageBox = ({
  iconSrc,
  title,
  children,
  className,
}: Props) => {
  return (
    <div className={`${styles.container} ${styles[className]}`}>
      <img src={iconSrc} alt="" />
      <h3>{title}</h3>
      {children}
    </div>
  );
};
