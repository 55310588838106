import { getCurrencyByCurrencyCode } from 'bootstrap/data/product/Product.utils';
import { ServerGiftCardProduct } from 'bootstrap/net/giftcards/giftcard.types';
import { formatPrice } from 'utils';
import { Currency } from '../product/Product.types';

export interface GiftCardProduct {
  name: string;
  price: number;
  currency: string;
  code: string;
  description: string;
  currencies: Currency[];
}

export const convertFromServer = (
  serverGiftCardProduct: ServerGiftCardProduct,
  currencyCode?: string,
): GiftCardProduct => {
  const { unit_amount, currency } = getCurrencyByCurrencyCode(
    serverGiftCardProduct.currencies,
    currencyCode,
  );

  if (!unit_amount) {
    throw Error(
      `Product ${serverGiftCardProduct.code} has no price information`,
    );
  }
  return {
    name: serverGiftCardProduct.name,
    code: serverGiftCardProduct.code,
    description: serverGiftCardProduct.description,
    price: unit_amount,
    currency: currency,
    currencies: serverGiftCardProduct.currencies,
  };
};

export const getFormattedPrice = (
  giftCardProduct: GiftCardProduct,
  currencyCode?: string,
  q?: number,
): string => {
  const quantity = q || 1;

  if (!giftCardProduct.currencies || !currencyCode) {
    return formatPrice(
      giftCardProduct.price * quantity,
      giftCardProduct.currency,
    );
  }

  const { unit_amount, currency } = getCurrencyByCurrencyCode(
    giftCardProduct.currencies,
    currencyCode,
  );

  return formatPrice(unit_amount * quantity, currency);
};
