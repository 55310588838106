import { push } from 'connected-react-router';
import { Amplitude } from 'analytics/Amplitude';
import { firebaseId } from 'analytics/Firebase';
import { LINK_PARAMS_MAP } from './utils.constants';
import i18n, { resources } from 'locales/i18n';
import moment from 'moment';

export function formatPrice(
  price?: number | string,
  currencySign?: string,
  minFractions = 2,
  maxFractions = 2,
): string {
  const amount =
    typeof price === 'number' ? price : parseFloat(price || '0.00');

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencySign,
    minimumFractionDigits: minFractions,
    maximumFractionDigits: maxFractions,
  }).format(amount);
}

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const prefersDark = false; // window.matchMedia('(prefers-color-scheme: light)').matches

export function pushWithSearchQuery(path: string, exclude?: string[]) {
  let search;
  if (exclude && exclude.length > 0) {
    search = window.location.search
      .substring(1)
      .split('&')
      .filter((query) => !exclude.includes(query.split('=')[0]))
      .join('&');
    if (search.length > 0) {
      search = '?' + search;
    }
  } else {
    search = window.location.search;
  }

  return push({
    pathname: path,
    search: search,
  });
}

export function getQueryVariable(variable: string): string | undefined {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (const pair of vars) {
    const [key, value] = pair.split('=');
    if (decodeURIComponent(key) === variable) {
      return decodeURIComponent(value);
    }
  }
  return undefined;
}

export async function sha256(message: string) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export const percentToHex = (p: number) => {
  const percent = Math.max(0, Math.min(100, p));
  const intValue = Math.round((percent / 100) * 255);
  const hexValue = intValue.toString(16);
  return typeof hexValue === 'string'
    ? hexValue.padStart(2, '0').toUpperCase()
    : '';
};

export const getOneLink = (params: Object, oneLink?: string) => {
  const link = oneLink || 'https://loona.onelink.me/Jfkp';
  const url = new URL(link);
  const currentParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(LINK_PARAMS_MAP[key], value);
  }

  Object.keys(currentParams).forEach((key) => {
    if (LINK_PARAMS_MAP[key]) {
      url.searchParams.append(LINK_PARAMS_MAP[key], currentParams[key]);
    }
  });

  return url.href;
};

export const getCookie = (name: string) => {
  const cookies = Object.assign(
    {},
    ...document.cookie.split('; ').map((cookie) => {
      const name = cookie.split('=')[0];
      const value = cookie.split('=')[1];

      return { [name]: value };
    }),
  );

  return cookies[name];
};

export async function retry<T>(
  fn: () => Promise<T>,
  retriesLeft = 3,
  interval = 1000,
): Promise<T> {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    if (retriesLeft) {
      await new Promise((resolve) => setTimeout(resolve, interval));
      return retry(fn, retriesLeft - 1, interval);
    } else throw new Error(`Max retries reached for function ${fn.name}`);
  }
}

export const isCookiesAccepted = (): boolean => {
  return localStorage.getItem('cookies-accepted') === 'true';
};

export const getDeviceInfo = () => {
  if (!firebaseId && !Amplitude.amplitudeId) {
    return undefined;
  } else {
    return {
      data: {
        idxs: {
          firebase_idx: firebaseId,
          amplitude_idx: Amplitude.amplitudeId,
        },
        user_agent: navigator.userAgent,
      },
      platform: 'web',
    };
  }
};

export const setWindowUrlSearchParams = (params: string) => {
  const location = {
    ...window.location,
    search: params,
  };

  Object.defineProperty(window, 'location', {
    value: location,
    writable: true,
  });
};

export const getLocaleDateString = (
  date: Date,
  options: Intl.DateTimeFormatOptions,
  language?: string,
) => {
  const formatter = new Intl.DateTimeFormat(
    getCurrentLanguage(language),
    options,
  );
  return formatter.format(date);
};

export const getLocaleTimeString = (time: string, language?: string) => {
  const dateFormatTime = moment(time, 'HH:mm').toDate();

  const shortTimeFormat = new Intl.DateTimeFormat(
    getCurrentLanguage(language),
    {
      timeStyle: 'short',
    },
  );

  if (!isNaN(dateFormatTime.getTime())) {
    return shortTimeFormat.format(dateFormatTime);
  }

  return '';
};

export const getLocaleIntervalTimeString = (timeInterval: string): string => {
  const [time1, time2] = timeInterval.split('-');
  if (!time1 || !time2) return '';

  return `${getLocaleTimeString(time1)}-${getLocaleTimeString(time2)}`;
};

export const isLanguageSupported = (language: string) =>
  Object.keys(resources).includes(language);

export const getCurrentLanguage = (language?: string): string => {
  const currentLanguage =
    language?.split('-')[0] || i18n.language.split('-')[0];

  return isLanguageSupported(currentLanguage) ? currentLanguage : 'en';
};

export const getAcceptLanguageHeader = (language?: string): string => {
  const languageRegionMap: Record<string, string> = {
    en: 'en-US',
    de: 'de-DE',
    es: 'es-ES',
    pt: 'pt-PT',
  };

  return languageRegionMap[getCurrentLanguage(language)];
};

export const isIos = (userAgent: string) => {
  const isIPad = !!userAgent.match(/iPad/i);
  const isIPhone = !!userAgent.match(/iPhone/i);
  const isIPod = !!userAgent.match(/iPod/i);
  const isMacIntel = navigator?.platform === 'MacIntel';
  const hasTouchPoints =
    'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
  return isIPad || isIPhone || isIPod || (isMacIntel && hasTouchPoints);
};

export const isTouchDevice = () => {
  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  );
};

export const isDevEnv = (): boolean =>
  process.env.REACT_APP_HOST_ENV === 'development';

export const hexToRgb = (hex: string): string => {
  const bigint = parseInt(hex.substring(1), 16);

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `${r},${g},${b}`;
};
