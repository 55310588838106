import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  darkTheme?: boolean;
};

const Disclaimer = ({ darkTheme }: Props) => {
  const { t } = useTranslation();

  return (
    <span
      className={`
        ${styles.disclaimer} 
        ${darkTheme ? styles.darkTheme : ''}
      `}
    >
      {t('signInDisclaimer')}
      <br />
      <a href="https://loona.app/terms">{t('termsOfUse')}</a> &{' '}
      <a href="https://loona.app/privacy">{t('privacyPolicy')}</a>
    </span>
  );
};

export default Disclaimer;
