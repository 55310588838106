import { isSkipTrialV3, isSkipTrialWithoutRefund } from 'navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Divider from 'components/divider/Divider';
import styles from './styles.module.scss';

type Props = {
  totalPrice: string;
  term: string;
  isDiscountApplied: boolean | undefined;
  defaultProductPrice: string;
};

export const Note = ({ totalPrice, defaultProductPrice, term }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        ${styles.note} 
        ${isSkipTrialWithoutRefund() ? styles.noteWithoutRefund : ''}
        ${isSkipTrialV3() ? styles.st_v3 : ''}
      `}
    >
      {isSkipTrialV3() && (
        <>
          <h3 className={styles.title}>How skip trial works?</h3>
          <Divider isDividerWithNoMargin />
        </>
      )}
      {isSkipTrialWithoutRefund() ? (
        <>
          When you click Skip trial and start plan the amount of your initial
          payment will be applied as coupon and your payment method will be
          charged {totalPrice}. Your payment method will then be charged{' '}
          {defaultProductPrice} every {term} until you cancel. You can cancel
          any time before the first day of your next subscription period to
          avoid automatic renewal.
        </>
      ) : (
        <ol>
          <li>
            <span>{t('skipTrialNoteLine1', { totalPrice })}</span>
          </li>
          <li>
            <span>{t('skipTrialNoteLine2', { totalPrice, term })}</span>
          </li>
          <li>
            <span>{t('skipTrialNoteLine3')}</span>
          </li>
        </ol>
      )}
    </div>
  );
};
