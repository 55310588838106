import React, { useMemo } from 'react';
import BearImage from 'assets/bear_v2.png';
import { usePreloadImages } from 'hooks/usePreloadImages';
import Spacer from 'components/spacer/Spacer';
import styles from './styles.module.scss';
import { useAppSelector } from 'bootstrap/hooks';
import { getTrialExpiredDate } from 'cancelsubscription/utils';

const FinishUserLost = () => {
  usePreloadImages([BearImage]);
  const { subscriptonExpiredDate } = useAppSelector((state) => state.user);

  const trialExpiredDate = useMemo(
    () => getTrialExpiredDate(subscriptonExpiredDate),
    [subscriptonExpiredDate],
  );

  return (
    <div className={styles.wrapper}>
      <img
        loading="lazy"
        src={BearImage}
        srcSet={BearImage}
        className={styles.image}
        alt=""
      />
      <Spacer />
      <div className={styles.content}>
        <div className={styles.title}>Sorry to see you go!</div>
        <div className={styles.description}>
          We've gone ahead and canceled your subscription, so you won't be
          charged again. <br /> <br />
          Don't worry, you'll still be able to use Loóna until your subscription
          ends on {trialExpiredDate}.
        </div>
      </div>
    </div>
  );
};

export default FinishUserLost;
