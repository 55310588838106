import { appAnalytics } from 'analytics';
import {
  ScreenOpened,
  SwitchPlan,
  UnfoldPayment,
  ViewSwitchPlanPrompt,
} from 'analytics/AllEvents';
import { Product } from 'bootstrap/data/product/Product';
import { Products } from 'bootstrap/data/product/Product.types';
import { useAppSelector } from 'bootstrap/hooks';
import { RefObject, useEffect, useState } from 'react';

type Props = {
  products: Products;
  paymentFormRef: RefObject<HTMLDivElement>;
};

export const usePaymentLogic = ({ products, paymentFormRef }: Props) => {
  const { authToken, hasSubscription, isTrialOfferShown, currency } =
    useAppSelector((state) => state.user);

  const [product, setProduct] = useState<Product>(
    products.default.extended_trial,
  );

  const [expanded, setExpanded] = useState(false);
  const [isShownPromoAlert, setShownPromoAlert] = useState(false);

  const showPromoAlert = (shown: boolean) => setShownPromoAlert(shown);
  const expandForm = () => setExpanded(true);
  const setNewProduct = (product: Product) => setProduct(product);

  useEffect(() => {
    appAnalytics.trackEvent(new ScreenOpened('onboardingPayment'));
  }, []);

  const isExtendedTrial = (selectedProduct: Product): boolean =>
    selectedProduct === products.promo.extended_trial ||
    selectedProduct === products.default.extended_trial;

  const handleChange = (type: string) => {
    if (type === 'standard_trial') {
      appAnalytics.trackEvent(new ViewSwitchPlanPrompt());
      showPromoAlert(true);
    } else {
      const newProduct = products.default.extended_trial;
      setNewProduct(newProduct);
      appAnalytics.trackEvent(new SwitchPlan(true, newProduct.id));
    }
  };

  const scrollToPaymentForm = () => {
    appAnalytics.trackEvent(new UnfoldPayment(isExtendedTrial(product)));
    expandForm();
    setTimeout(() => {
      if (paymentFormRef.current) {
        window.scroll({
          top: paymentFormRef.current?.offsetTop - 100,
          behavior: 'smooth',
        });
      }
    }, 1);
  };

  return {
    authToken,
    hasSubscription,
    isTrialOfferShown,
    isShownPromoAlert,
    expanded,
    product,
    currencyCode: currency,
    isExtendedTrial,
    scrollToPaymentForm,
    handleChange,
    showPromoAlert,
    expandForm,
    setNewProduct,
  };
};
