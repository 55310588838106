import React from 'react';
import CheckedIcon from 'assets/ic_check.svg';
import { percentToHex } from 'utils';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  text: string;
  color: string;
  progress: number;
};

export const ProgressBar = ({ text, color, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <div>{t(text)}</div>
        {progress < 1 ? (
          <div>{progress > 0 ? Math.floor(progress * 100) + '%' : ''}</div>
        ) : (
          <img src={CheckedIcon} alt="100%" />
        )}
      </div>
      <div
        className={styles.background}
        style={{ backgroundColor: color + percentToHex(30) }}
      >
        <div
          className={styles.progress}
          style={{ backgroundColor: color, width: `${progress * 100}%` }}
        />
      </div>
    </div>
  );
};
