import React from 'react';
import QRCode from 'react-qr-code';

type Props = {
  value: string;
  size?: number;
  bgColor?: string;
  fgColor?: string;
  level?: 'L' | 'M' | 'Q' | 'H';
};

const QrCode = (props: Props) => {
  return <QRCode {...props} />;
};

export default QrCode;
