import React from 'react';
import { Loader } from 'components/loader/Loader';
import WhiteLock from 'assets/ic_lock_white.svg';
import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  className?: string;
  isLoading: boolean;
  lock?: boolean;
  label: string;
  darkTheme?: boolean;
  onClick: () => void;
};

export const LoadingButton = ({
  disabled,
  className,
  isLoading,
  lock,
  label,
  darkTheme = false,
  onClick,
}: Props) => (
  <button
    className={`
      ${styles.loadingButton} 
      ${darkTheme ? styles.dark : ''} 
      ${className}
    `}
    disabled={disabled}
    onClick={(event) => {
      event.preventDefault();
      !isLoading && onClick();
    }}
  >
    {isLoading ? (
      <Loader />
    ) : (
      <span>
        {lock && <img src={WhiteLock} alt="" />}
        {label}
      </span>
    )}
  </button>
);
