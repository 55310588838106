import React from 'react';
import { OptionQuestion } from './OptionQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import Spacer from 'components/spacer/Spacer';
import { useOptionQuestionLogic } from './OptionQuestions.hook';
import { TextOption } from './TextOption';
import i18next from 'i18next';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<OptionQuestion>;

export const OptionQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const { t } = useTranslation();
  const {
    title,
    age,
    gender,
    activeOptions,
    containerStyle,
    optionHandler,
    nextHandler,
  } = useOptionQuestionLogic(question);

  const getOptionText = (option: TextOption) => {
    return i18next.exists(option.text) ? t(option.text) : option.text;
  };

  return (
    <div
      className={`
        ${styles.container} 
        ${styles[containerStyle]} 
        ${styles.speed} 
        maxWidth
      `}
    >
      <h3
        className={`${styles.title} ${
          question.hasStepper ? '' : styles.titleWithMargin
        }`}
      >
        {t(title, { age: t(age), gender: t(gender) })}
      </h3>
      {question.description && (
        <p className={styles.description}>{t(question.description)}</p>
      )}
      {question.options.map((option) => (
        <button
          key={option.id}
          className={`
            ${styles.option} 
            ${activeOptions.includes(option.id) ? styles.active : ''}
            ${question.multiple ? styles.multiple : ''} 
            ${option.subtext ? styles.withSubText : ''}
          `}
          onClick={optionHandler(option)}
        >
          {option.subtext ? (
            <>
              <span className={styles.text}>{getOptionText(option)}</span>
              <span className={styles.subtext}>{t(option.subtext)}</span>
            </>
          ) : (
            getOptionText(option)
          )}
        </button>
      ))}
      <Spacer />
      {question.multiple && activeOptions.length > 0 && (
        <StickyContainer>
          <button className={styles.button} onClick={nextHandler}>
            {t('Next')}
          </button>
        </StickyContainer>
      )}
    </div>
  );
};
