import { useEffect, useState } from 'react';

export const useProgressText = (
  text: string,
  duration: number,
  finalText: any = '',
) => {
  const [progressText, setProgressText] = useState(`${text}.`);

  useEffect(() => {
    const startTime = Date.now();

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;

      const reminder = Math.floor(elapsedTime / 500) % 4;
      const result = `${text}${'.'.repeat(reminder)}${' '.repeat(
        3 - reminder,
      )}`;
      setProgressText(result);

      if (elapsedTime >= duration) {
        clearInterval(timer);
        setProgressText(finalText);
      }
    }, 100);
    return () => {
      clearInterval(timer);
      setProgressText(finalText);
    };
  }, [text, duration, finalText]);
  return progressText;
};
