import React from 'react';
import { useAppDispatch } from 'bootstrap/hooks';
import { pushWithSearchQuery } from 'utils';
import {
  WithDefaultProduct,
  withDefaultProduct,
} from 'subscription/withDefaultProduct/withDefaultProduct';
import { PresalePlanPicker } from './components/planpicker/PresalePlanPicker';

interface Props extends WithDefaultProduct {
  onSubmit?: () => void;
}

export const PresalePage = withDefaultProduct(
  ({ onSubmit, products }: Props) => {
    const dispatch = useAppDispatch();

    const handlePresaleProductPicked = () => {
      dispatch(pushWithSearchQuery('/subscription'));
      onSubmit?.();
    };

    return (
      <PresalePlanPicker
        products={products}
        onSuccess={handlePresaleProductPicked}
      />
    );
  },
);
