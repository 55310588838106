import { appAnalytics } from 'analytics';
import { setEmail } from 'bootstrap/giftpurchase/actions/setEmail';
import { setQuantity as saveQuantity } from 'bootstrap/giftpurchase/actions/setQuantity';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { GiftAmountSelected, GiftScreenOpened } from 'giftcards/analytics';
import {
  getFormattedPrice,
  GiftCardProduct,
} from 'bootstrap/data/giftproduct/GiftCardProduct';
import { getGiftCardLabel } from 'giftcards/utils';
import { useEffect, useRef, useState } from 'react';
import { pushWithSearchQuery, validateEmail } from 'utils';
import { MAX_QUANTITY } from './PlanSetup.constants';

export const usePlanSetupLogic = () => {
  const dispatch = useAppDispatch();
  const { currency } = useAppSelector((state) => state.user);
  const { giftCardType, products } = useAppSelector((state) => state.purchase);

  const emailRef = useRef<HTMLInputElement>(null);
  const giftCardLabel = getGiftCardLabel(giftCardType);

  const [quantity, setQuantity] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<string>();
  const [isEmailValid, setEmailValid] = useState<boolean>(false);

  useEffect(() => {
    appAnalytics.trackEvent(new GiftScreenOpened('GiftPlanSettings'));
  }, []);

  useEffect(() => {
    if (products) {
      setTotalPrice(getTotalPrice(products[giftCardType], quantity));
    }
  }, [products, quantity, giftCardType]);

  const getTotalPrice = (product: GiftCardProduct, q: number) => {
    return getFormattedPrice(product, currency || product.currency, q);
  };

  const onContinue = () => {
    if (emailRef.current) {
      dispatch(setEmail(emailRef.current.value));
    }
    dispatch(saveQuantity(quantity));
    appAnalytics.trackEvent(new GiftAmountSelected(quantity));
    dispatch(pushWithSearchQuery('/gift/purchase'));
  };

  const increaseQuantity = () => {
    if (quantity < MAX_QUANTITY) {
      setQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const onEmailValidHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setEmailValid(validateEmail(event.target.value));
  };

  return {
    quantity,
    products,
    totalPrice,
    giftCardLabel,
    isEmailValid,
    emailRef,
    giftCardType,
    onContinue,
    increaseQuantity,
    decreaseQuantity,
    onEmailValidHandler,
  };
};
