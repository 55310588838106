import { useAppSelector } from 'bootstrap/hooks';
import { Answers } from 'bootstrap/onboarding/state';
import { useEffect, useState } from 'react';
import {
  InsomniaResultData,
  MappedInsomniaAnswersWeight,
} from './InsomniaResults.constants';
import { InsomniaResult, InsomniaTypes } from './InsomniaResults.types';

interface Props {
  insomniaTestResult: InsomniaResult;
}

export const useInsomniaTestLogic = (): Props => {
  const { answers } = useAppSelector((state) => state.onboarding);
  const [result, setResult] = useState(0);

  useEffect(() => {
    setResult(getInsomniaTestResult(answers));
  }, [answers]);

  return {
    insomniaTestResult: InsomniaResultData[getInsomniaType(result)],
  };
};

export const getInsomniaTestResult = (answers: Answers): number => {
  let result = 0;
  Object.values(answers).forEach((answer) => {
    if (typeof answer === 'string') {
      if (MappedInsomniaAnswersWeight[answer]) {
        result += MappedInsomniaAnswersWeight[answer];
      }
    }
  });
  return result;
};

export const getInsomniaType = (result: number): InsomniaTypes => {
  if (result >= 0 && result <= 4) {
    return InsomniaTypes.Minimal;
  } else if (result >= 5 && result <= 8) {
    return InsomniaTypes.Mild;
  } else if (result >= 9 && result <= 12) {
    return InsomniaTypes.Moderate;
  } else {
    return InsomniaTypes.Severe;
  }
};
