import React from 'react';
import { useModalLogic } from './hooks/useModalLogic';
import './styles.scss';

type ModalProps = {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  visible: boolean;
};

const Modal = ({ children, className, visible }: ModalProps) => {
  useModalLogic({ visible });

  return (
    <div className={visible ? 'modal-container' : 'modal-container hidden'}>
      <div className={`modal ${className}`}>{children}</div>
    </div>
  );
};

export default Modal;
