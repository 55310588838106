import React, { useRef } from 'react';
import { ImageQuestion } from './ImageQuestion';
import { QuestionComponentProps } from '../QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import { useImageQuestionLogic } from './ImageQuestion.hook';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<ImageQuestion>;

export type Option = {
  id: string;
};

const OPTIONS: Option[] = [{ id: 'no' }, { id: 'yes' }];

export const ImageQuestionComponent: React.FC<Props> = ({
  question,
}: Props) => {
  const artwork = useRef<HTMLImageElement>(null);
  const { t } = useTranslation();

  const { activeOption, containerStyle, revealImage, imageUrl, optionHandler } =
    useImageQuestionLogic(question);

  return (
    <div
      className={`
        ${styles.container} 
        ${styles.speed} 
        maxWidth
      `}
    >
      <h3 className={styles.title}>{t(question.title)}</h3>
      <p className={styles.description}>{t(question.description)}</p>
      <div className={`${styles.imageWrapper} ${styles[containerStyle]}`}>
        <img
          ref={artwork}
          className={styles.image}
          src={imageUrl}
          alt=""
          onLoad={revealImage}
        />
      </div>
      <div className={styles.optionsContainer}>
        {OPTIONS.map((option) => (
          <button
            key={option.id}
            className={`${styles.option} ${
              activeOption === option.id ? styles.active : ''
            }`}
            onClick={optionHandler(option)}
          >
            {t(option.id)}
          </button>
        ))}
      </div>
    </div>
  );
};
