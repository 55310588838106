import React from 'react';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { Product } from 'bootstrap/data/product/Product';
import { DefaultElements } from './components/elements/default/DefaultElements';
import { useRecurlyLogic } from './Recurly.hook';
import { RECURLY_KEY } from 'utils/utils.constants';
import styles from './styles.module.scss';

type Props = {
  product: Product;
};

export const Recurly = ({ product }: Props) => {
  const { currency, onPaymentError, onPaymentSuccess } = useRecurlyLogic();

  return (
    <div className={styles.container}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RecurlyProvider publicKey={RECURLY_KEY}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Elements>
          <DefaultElements
            currencyCode={currency}
            product={product}
            onPaymentSuccess={onPaymentSuccess}
            onPaymentError={onPaymentError}
          />
        </Elements>
      </RecurlyProvider>
    </div>
  );
};
