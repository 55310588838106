import { SyntheticEvent, useEffect, useState } from 'react';

type Props = {
  startText?: string;
  finishText: string;
  progress: number;
  isAutoContinue?: boolean;
  onClick: (e?: SyntheticEvent) => void;
};

export const useProgressButtonLogic = ({
  startText,
  finishText,
  progress,
  isAutoContinue,
  onClick,
}: Props) => {
  const [isUpdating, setUpdating] = useState(false);

  useEffect(() => {
    if (progress >= 1) {
      if (isAutoContinue) {
        onClick();
      }
      setUpdating(true);
      setTimeout(() => {
        setUpdating(false);
      }, 500);
    }
  }, [progress]);

  const handleButtonClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (progress >= 1) {
      onClick(e);
    }
  };

  const getButtonText = () => {
    const percent = ` ${Math.round(progress * 100)}%`;
    return `${
      progress >= 1 && !isUpdating ? finishText : `${startText} ${percent}`
    }`;
  };

  return {
    isUpdating,
    buttonText: getButtonText(),
    handleButtonClick,
  };
};
