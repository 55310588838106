import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type RadioButtonProps = {
  name: string;
  checked: boolean;
  product: Product;
  description: string;
  currencyCode?: string;
  onChange: (value: string) => void;
};

export const RadioButton = ({
  product,
  name,
  checked,
  description,
  currencyCode,
  onChange,
}: RadioButtonProps) => {
  const { t } = useTranslation();

  const handleChange = () => {
    if (!checked) {
      onChange(product.id);
    }
  };
  return (
    <div className={styles.checkbox} onClick={handleChange}>
      <input
        type="radio"
        name={name}
        value={product.id}
        onChange={handleChange}
        checked={checked}
      />
      <label className={styles.radioBtn}>
        {t(description, {
          period: product.trialLength,
          unit: product.trialUnit,
        })}
      </label>
      <span
        className={`${styles.price} ${!!product.setupFee ? styles.accent : ''}`}
      >
        {product.getFormattedInvoicePrice(currencyCode)}
      </span>
    </div>
  );
};

export default RadioButton;
