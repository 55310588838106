import { useEffect, useState } from 'react';

export const easeInOutQuad = (t: number) =>
  t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

export const useProgress = (
  duration: number,
  delay: number,
  steps: number[],
  easingFunction: (t: number) => number = easeInOutQuad,
) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const startTime = Date.now();
    let isDelaying = false;
    const stepsCount = steps.length;
    const stepDuration = duration / stepsCount;

    const timer = setInterval(() => {
      const elapsedTime = Date.now() - startTime;

      const currentStep = Math.floor(
        (elapsedTime / (duration + delay * stepsCount)) * stepsCount,
      );

      if (currentStep < stepsCount) {
        const nextStep = isDelaying ? Math.max(currentStep, 0) : currentStep;
        const currentOffset = stepDuration * nextStep + delay * nextStep;
        const currentStepDuration = elapsedTime - currentOffset;
        const isDelayPeriod =
          currentStepDuration > stepDuration &&
          currentStepDuration <= stepDuration + delay;

        if (isDelayPeriod) {
          if (!isDelaying) {
            isDelaying = true;
          }
        } else {
          isDelaying = false;
          const offset = stepDuration * currentStep + delay * currentStep;
          const stepProgress = easingFunction(
            Math.min(1, (elapsedTime - offset) / stepDuration),
          );
          const previousStep = steps[currentStep - 1] || 0;
          const nextProgress =
            previousStep + stepProgress * (steps[currentStep] - previousStep);
          setProgress(nextProgress);
        }
      } else {
        setProgress(1);
        clearInterval(timer);
      }
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, [delay, duration, steps, easingFunction]);
  return progress;
};
