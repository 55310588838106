import React, { useEffect, useState } from 'react';
import { ReactComponent as EmptyCheckmark } from 'assets/ic_option_checkmark_empty.svg';
import { ReactComponent as CheckedCheckmark } from 'assets/ic_option_checkmark_checked.svg';
import { useTranslation } from 'react-i18next';
import { isEuMember } from 'is-eu-member';
import { useAppDispatch } from 'bootstrap/hooks';
import { setRestrictMailing } from 'bootstrap/user/actions';
import styles from './styles.module.scss';

type Props = {
  countryCode?: string;
  isRestrictMailing: boolean | undefined;
  onHandleCheckboxClick: () => void;
};

const BottomInputContainer = ({
  countryCode,
  isRestrictMailing,
  onHandleCheckboxClick,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEuMemberUser = countryCode && isEuMember(countryCode);

  const [label, setLabel] = useState<string>('');
  const [isChecked, setChecked] = useState<boolean>(!!isRestrictMailing);

  useEffect(() => {
    if (!countryCode) return;

    if (isEuMemberUser) {
      setLabel(t('singInBottomContainerEULabel'));

      if (isRestrictMailing === undefined) {
        dispatch(setRestrictMailing(true));
      }
    }

    if (!isEuMemberUser) {
      setLabel(t('singInBottomContainerLabel'));

      if (isRestrictMailing === undefined) {
        dispatch(setRestrictMailing(false));
      }
    }
  }, [countryCode]);

  useEffect(() => {
    const shouldAllowMailing = isEuMemberUser
      ? !isRestrictMailing
      : !!isRestrictMailing;

    setChecked(shouldAllowMailing);
  }, [isRestrictMailing]);

  return (
    <div className={styles.bottomInputContainer}>
      <div
        className={`
            ${styles.checkbox}
            ${isChecked ? styles.active : ''}
          `}
        onClick={onHandleCheckboxClick}
      >
        {countryCode && (isChecked ? <CheckedCheckmark /> : <EmptyCheckmark />)}
      </div>
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default BottomInputContainer;
