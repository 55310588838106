import { QuestionIds } from '../constants/QuestionIds';
import { Steps } from '../constants/StepsLabels';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const EducationV1TestSteps: Map<Steps, QuestionIds[]> = new Map([
  [
    Steps.Sleep,
    [
      QuestionIds.EduQ1,
      QuestionIds.EduQ1Answer,
      QuestionIds.EduQ2,
      QuestionIds.EduQ2Answer,
      QuestionIds.EduQ3,
      QuestionIds.EduQ3Answer,
      QuestionIds.EduQ4,
      QuestionIds.EduQ4Answer,
      QuestionIds.EduQ5,
      QuestionIds.EduQ5Answer,
    ],
  ],
  [
    Steps.AboutYou,
    [
      QuestionIds.AboutYouGender,
      QuestionIds.AboutYouAge,
      QuestionIds.AboutYouRoutine,
      QuestionIds.AboutYouKids,
      QuestionIds.AboutYouActivity,
      QuestionIds.SleepAndActivityProof2,
      QuestionIds.SleepAndActivityCoffee,
      QuestionIds.SleepAndActivityDinner,
      QuestionIds.SleepAndActivityAfterMidnight,
      QuestionIds.SleepAndActivityLessThanEightHours,
      QuestionIds.SleepAndActivityExtraMotivation,
    ],
  ],
  [Steps.Personalization, PersonalizationSectionQuestionIds],
]);
