import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Steps } from 'onboarding/data/constants/StepsLabels';
import { StepsData } from 'onboarding/data/Steps';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { QUESTIONS_META } from './data/meta';
import { QuestionTypes } from './question/Question';

type Props = { questionId: QuestionIds };

export const useStepperWrapperLogic = ({ questionId }: Props) => {
  const location = useLocation();
  const [stepName, setStepName] = useState<Steps>(Steps.AboutYou);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [stepsTotal, setStepsTotal] = useState<number>(0);
  const [currentSubstep, setCurrentSubstep] = useState<number>(0);
  const [substepsTotal, setSubstepsTotal] = useState<number>(0);
  const [questionType, setQuestionType] = useState<QuestionTypes>();

  useEffect(() => {
    setQuestionType(QUESTIONS_META[questionId].type);
  }, [location]);

  useEffect(() => {
    const arrayedSteps = Array.from(StepsData.keys());
    StepsData.forEach((steps, name) => {
      if (steps.includes(questionId)) {
        setStepName(name);
        setCurrentStep(arrayedSteps.indexOf(name));
        setCurrentSubstep(steps.indexOf(questionId));
        setSubstepsTotal(steps.length);
        setStepsTotal(arrayedSteps.length);
      }
    });
  }, [questionId]);

  return {
    stepName,
    currentStep,
    stepsTotal,
    currentSubstep,
    substepsTotal,
    questionType,
  };
};
