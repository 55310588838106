import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import Divider from 'components/divider/Divider';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'bootstrap/hooks';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  presaleProduct?: Product;
  currencyCode?: string;
};

export const TotalPriceInfo = ({
  product,
  presaleProduct,
  currencyCode,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isPreventBackPaymentOfferConfirmed } = useAppSelector(
    (state) => state.user,
  );

  return (
    <>
      <div className={styles.totalHeader}>{t('paymentTotalPrice')}</div>

      <Divider isDividerWithNoMargin />

      <div className={styles.total}>
        {t('paymentTrialLength', { days: product.trialLength })}
        <div className={styles.prices}>
          {isPreventBackPaymentOfferConfirmed && (
            <span className={styles.crossed}>
              {presaleProduct?.getFormattedInvoicePrice(currencyCode)}
            </span>
          )}
          <span className={`${styles.accent} ${styles.bold}`}>
            {product.getFormattedInvoicePrice(currencyCode)}
          </span>
        </div>
      </div>

      <Divider />

      <div className={styles.trialExplanation}>
        {t('paymentTrialExplanation')}
      </div>
    </>
  );
};
