import React, { useRef } from 'react';
import { CustomQuestion } from 'onboarding/question/Question';
import { QuestionComponentProps } from 'onboarding/question/QuestionComponent.types';
import { useTranslation } from 'react-i18next';
import ManPhoto from 'assets/onboarding/man.jpg';
import WomanPhoto from 'assets/onboarding/woman.jpg';
import Spacer from 'components/spacer/Spacer';
import { useMotivationComponentLogic } from './Motivation.hook';
import { usePreloadImages } from 'hooks/usePreloadImages';
import { MotivationReview } from './components/reviews/review/Review';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import styles from './styles.module.scss';

type Props = QuestionComponentProps<CustomQuestion>;

export const Motivation: React.FC<Props> = ({ question }: Props) => {
  const { t } = useTranslation();

  const imageRef = useRef<HTMLImageElement>(null);
  const { containerStyle, gender, imageStyle, nextButtonHandler } =
    useMotivationComponentLogic({ question, imageRef });
  usePreloadImages([gender === 'male' ? ManPhoto : WomanPhoto]);

  const description = (
    <div className={styles.description}>{t('SleepAndActivityProof2Note')}</div>
  );

  return (
    <div
      className={`
        ${styles.container} 
        ${styles[containerStyle]} 
        ${styles.speed}
        maxWidth
      `}
    >
      <h3 className={styles.title}>{t('SleepAndActivityProof2Title')}</h3>
      <div className={styles.imageContainer}>
        <img
          ref={imageRef}
          className={`
            ${styles.image} 
            ${styles[imageStyle]}
            ${styles.maxHeight}
          `}
          src={gender === 'male' ? ManPhoto : WomanPhoto}
          loading="lazy"
          alt=""
        />
        <MotivationReview gender={gender} />
      </div>
      <Spacer />
      {description}
      <StickyContainer>
        <button className={styles.button} onClick={nextButtonHandler}>
          {t('gotItButton')}
        </button>
      </StickyContainer>
    </div>
  );
};
