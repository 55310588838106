import { QuestionIds } from 'onboarding/data/constants/QuestionIds';

export enum QuestionTypes {
  Option,
  Color,
  Places,
  Info,
  Custom,
  Optional,
  Statement,
  Image,
  Blitz,
  EduAnswer,
}

export abstract class Question {
  id: QuestionIds;
  variationId: QuestionIds;
  type: QuestionTypes;
  hasStepper: boolean;

  protected constructor(
    type: QuestionTypes,
    id: QuestionIds,
    hasStepper: boolean,
    variationId?: QuestionIds,
  ) {
    this.type = type;
    this.id = id;
    this.hasStepper = hasStepper;
    this.variationId = variationId ?? id;
  }
}

export class CustomQuestion extends Question {
  constructor(id: QuestionIds, hasStepper = false, variationId?: QuestionIds) {
    super(QuestionTypes.Custom, id, hasStepper, variationId);
  }
}
