import React from 'react';
import Review from 'subscription/components/review/Review';
import Emma from 'assets/avatars/emma.jpeg';
import Amelia from 'assets/avatars/amelia.jpeg';
import Zoey from 'assets/avatars/zoey.jpeg';
import { useTranslation } from 'react-i18next';

export const Reviews = () => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('reviewsTitle')}</h3>
      <div>
        <Review
          text={t('reviewText1')}
          author="Emma Patterson"
          avatar={Emma}
          rating={4.8}
        />
        <Review
          text={t('reviewText2')}
          author="Amelia Smith"
          avatar={Amelia}
          rating={5.0}
        />
        <Review
          text={t('reviewText3')}
          author="Zoey Miller"
          avatar={Zoey}
          rating={4.6}
        />
      </div>
    </>
  );
};
