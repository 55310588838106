import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from 'assets/onboarding/sticky_header_star.svg';
import { useStickyHeader } from 'hooks/useStickyHeader';
import { Product } from 'bootstrap/data/product/Product';
import i18next from 'i18next';
import './styles.scss';

type StickyHeaderProps = {
  product: Product;
  startDate: number;
  trialLength: number;
};

export const StickyHeader = ({
  product,
  startDate,
  trialLength,
}: StickyHeaderProps) => {
  const { t } = useTranslation();

  const timerBox = useRef<HTMLDivElement>(null);
  const { isSticky, time } = useStickyHeader({
    timerBox,
    startDate,
  });

  return (
    <>
      {isSticky && (
        <div className="quick-purchase-sticky-container sticky">
          <div className="active-timer">{t('stickyHeaderLine3', { time })}</div>
        </div>
      )}
      <div ref={timerBox} className="quick-purchase-sticky-container">
        <StarIcon className="left" />
        <StarIcon className="right" />
        <div className="quick-purchase-timer-box top-margin-17">
          <div className="title">
            {product.trialLength
              ? t('stickyHeaderWithTrialTitleLine1')
              : t('stickyHeaderWithoutTrialTitleLine1')}{' '}
            <span className="accentTitle">
              {product.trialLength
                ? t('stickyHeaderWithTrialTitleLine2', { trialLength })
                : t('stickyHeaderWithoutTrialTitleLine2', {
                    discountPercent: product.discount?.value || 30,
                  })}
            </span>{' '}
            {!product.trialLength &&
            !product.rampIntervals.length &&
            i18next.exists('discount')
              ? t('discount')
              : ''}
            {product.rampIntervals.length > 0 && (
              <span>
                {t('stickyHeaderWithTrialTitleLine4', {
                  term: product.getTerm(t),
                })}
              </span>
            )}
          </div>
          <div className="accent">{t('stickyHeaderLine3', { time })}</div>
          <div className="light">{t('stickyHeaderLine4')}</div>
        </div>
      </div>
    </>
  );
};
