import React from 'react';
import style from './styles.module.scss';
import { faqListData } from './data/FaqListData';
import { ListItem } from './listitem/ListItem';

export const FaqAccordion = () => {
  return (
    <div className={style.container}>
      <div className={style.title}>FAQ</div>
      {faqListData.map((props) => {
        return <ListItem key={props.id} {...props} />;
      })}
    </div>
  );
};
