import React, { SyntheticEvent } from 'react';
import styles from './styles.module.scss';
import { useProgressButtonLogic } from './ProgressButton.hook';

type ProgressButtonProps = {
  className?: string;
  progress: number;
  startText?: string;
  finishText: string;
  isAutoContinue?: boolean;
  onClick: (e?: SyntheticEvent) => void;
};

export const ProgressButton = ({
  className,
  progress,
  startText = '',
  finishText,
  isAutoContinue,
  onClick,
}: ProgressButtonProps) => {
  const { buttonText, isUpdating, handleButtonClick } = useProgressButtonLogic({
    startText,
    finishText,
    progress,
    isAutoContinue,
    onClick,
  });

  return (
    <div
      className={`
        ${styles.buttonContainer} 
        ${className} 
        ${progress >= 1 ? styles.ready : ''}
      `}
      onClick={handleButtonClick}
    >
      <div
        style={{ width: `${progress < 1 ? progress * 100 : 100}%` }}
        className={styles.progress}
      />
      <p className={`${styles.text} ${isUpdating ? styles.switching : ''}`}>
        {buttonText}
      </p>
    </div>
  );
};
