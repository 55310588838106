import React, { ChangeEvent } from 'react';
import CheckedMark from 'assets/ic_checked.svg';
import Delete from 'assets/ic_delete.svg';
import styles from './styles.module.scss';

type Props = {
  placeholder: string;
  value: string;
  applied: boolean;
  error: string;
  onChange: (value: string) => void;
  onButtonClick: () => void;
};

export const InputWithButton = ({
  placeholder,
  value,
  applied,
  error,
  onChange,
  onButtonClick,
}: Props) => {
  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  const onClear = () => {
    onChange('');
  };

  let button;

  if (error) {
    button = (
      <img
        className={styles.deleteButton}
        src={Delete}
        alt=""
        onClick={onClear}
      />
    );
  } else if (applied) {
    button = <img className={styles.checkMark} src={CheckedMark} alt="" />;
  } else {
    button = (
      <button
        className={styles.applyButton}
        disabled={value.length === 0}
        onClick={onButtonClick}
      >
        APPLY
      </button>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <input
          className={`${styles.codeInput} ${!error ? '' : styles.invalid}`}
          type="text"
          placeholder={placeholder}
          value={value}
          disabled={applied}
          onChange={onInputChange}
        />
        {button}
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  );
};
