import { Reducer } from 'redux';
import { Product } from '../data/product/Product';
import { SET_PRODUCTS } from './actions/loadProducts';
import { SET_PRESALE_PRODUCT } from './actions/setPresaleProduct';
import { ProductActionTypes } from './actions/productActions.types';
import { ProductState } from './state';

export const INITIAL_STATE: ProductState = {
  products: new Map<string, Product>(),
  presaleProductCode: undefined,
};

export const productReducer: Reducer<ProductState, ProductActionTypes> = (
  state = INITIAL_STATE,
  action: ProductActionTypes,
) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case SET_PRESALE_PRODUCT: {
      return {
        ...state,
        presaleProductCode: action.payload.productCode,
      };
    }
    default:
      return state;
  }
};
