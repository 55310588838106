import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import { Question, QuestionTypes } from '../Question';
import { PlaceOption } from './PlaceOption';

export class PlaceQuestion extends Question {
  title: string;
  description: string;
  options: PlaceOption[];

  constructor(
    id: QuestionIds,
    title: string,
    description: string,
    options: PlaceOption[],
  ) {
    super(QuestionTypes.Places, id, true);
    this.title = title;
    this.description = description;
    this.options = options;
  }

  hasOptionId = (id: string): boolean => {
    for (const option of this.options) {
      if (option.id === id) {
        return true;
      }
    }
    return false;
  };
}
