import React from 'react';
import { ReactComponent as Discount } from 'assets/discount.svg';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import Spacer from 'components/spacer/Spacer';
import { ErrorPopup } from 'components/errorpopup/ErrorPopup';
import { useOfferLogic } from './Offer.hook';
import styles from './styles.module.scss';

const Offer = () => {
  const { error, setError, cancelSubscription, changeSubscription } =
    useOfferLogic();
  return (
    <>
      <ErrorPopup
        visible={!!error}
        error={error}
        onClose={() => setError('')}
      />
      <div className={`${styles.wrapper} ${styles.maxWidth}`}>
        <div className={styles.discountBackground}>
          <Discount />
        </div>
        <div className={styles.title}>
          ONE TIME OFFER
          <br /> <span className={styles.accent}>NOW $1 FOR 3 MONTHS</span>
        </div>
        <div className={styles.note}>
          Loóna Plus: only{' '}
          <span className={styles.accent}>$1 for 3 months </span> <br />
          <br />
          Yes, you read that right.
          <br /> Our best offer yet! <br />
          <br />
          Retain access to your personalised plan to improve your sleep quality
          and productivity. Invest in your health today for just $1..
        </div>
        <Spacer />
        <StickyContainer className={styles.stickyContainer}>
          <button className={styles.button} onClick={changeSubscription}>
            KEEP LOÓNA PLUS
          </button>
          <button
            className={`${styles.button} ${styles.secondary}`}
            onClick={cancelSubscription}
          >
            Cancel Loóna Plus
          </button>
        </StickyContainer>
      </div>
    </>
  );
};

export default Offer;
