import { useAppSelector } from 'bootstrap/hooks';
import { QuestionIds } from './data/constants/QuestionsIds';
import ExtendTrial from './pages/extendtrial/ExtendTrial';
import Finish from './pages/finish/Finish';
import FinishUserLost from './pages/finishuserlost/FinishUserLost';
import Offer from './pages/offer/Offer';
import { OptionalComponent } from './components/question/options/OptionalComponent';
import { OptionQuestionComponent } from './components/question/options/OptionQuestionComponent';
import {
  CustomQuestion,
  Question,
  QuestionTypes,
} from './components/question/Question';
import { QuestionComponentProps } from './components/question/Question.types';
import WelcomeCancel from './pages/welcome/Welcome';
import { LoadingScreen } from './pages/loadingscreen/LoadingScreen';

export const getQuestionComponent = (
  question: Question,
): React.FC<QuestionComponentProps<any>> => {
  const { subscriptionStatus } = useAppSelector((state) => state.user);

  switch (question.type) {
    case QuestionTypes.Optional:
      return OptionalComponent;
    case QuestionTypes.Option:
      return OptionQuestionComponent;
    case QuestionTypes.Custom:
      switch ((question as CustomQuestion).variationId) {
        case QuestionIds.Welcome:
          return WelcomeCancel;
        case QuestionIds.FinishWinBack:
          return Finish;
        case QuestionIds.Offer:
          return Offer;
        case QuestionIds.Extend:
          return subscriptionStatus === 'trial' ? ExtendTrial : Offer;
        case QuestionIds.FinishUserLost:
          return FinishUserLost;
        case QuestionIds.Loader:
          return LoadingScreen;
        default:
          return WelcomeCancel;
      }
  }
};

export const getTrialExpiredDate = (
  subscriptonExpiredDate?: string,
  extendedTrialDays?: number,
) => {
  if (!subscriptonExpiredDate) return '';
  const date = new Date(subscriptonExpiredDate);
  const trialEndDate = date.setDate(date.getDate() + (extendedTrialDays || 0));

  const trialEndFormattedDate = Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(trialEndDate);

  return trialEndFormattedDate;
};
