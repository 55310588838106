import React from 'react';
import { ReactComponent as CheckedMark } from 'assets/ic_check_small.svg';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import styles from './styles.module.scss';

type Props = {
  label: string;
  price: string;
  selected: boolean;
  giftCardType: GiftCardTypes;
  onSelect: () => void;
};

export const PlanCard = ({
  label,
  price,
  selected,
  giftCardType,
  onSelect,
}: Props) => (
  <div
    className={`
      ${styles.planCard} ${selected ? styles.selected : ''} 
      ${styles[giftCardType]}
    `}
    onClick={onSelect}
  >
    {selected && <CheckedMark />}
    <p className={styles.title}>{label}</p>
    <p className={styles.price}>{price}</p>
  </div>
);
