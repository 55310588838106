import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';

export enum QuestionTypes {
  Option,
  Custom,
  Optional,
}

export abstract class Question {
  id: QuestionIds;
  variationId: QuestionIds;
  type: QuestionTypes;

  protected constructor(
    type: QuestionTypes,
    id: QuestionIds,
    variationId?: QuestionIds,
  ) {
    this.type = type;
    this.id = id;
    this.variationId = variationId ?? id;
  }
}

export class CustomQuestion extends Question {
  constructor(id: QuestionIds, variationId?: QuestionIds) {
    super(QuestionTypes.Custom, id, variationId);
  }
}
