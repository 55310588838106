import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import Spacer from 'components/spacer/Spacer';
import { PresaleInfo } from '../presaleinfo/PresaleInfo';
import { PricesContainer } from '../pricescontainer/PricesContainer';
import { usePresaleLogic } from 'subscription/presale/Presale.hook';
import { useTranslation } from 'react-i18next';
import { isPresalePulsingButtonFlow } from 'navigation';
import { StickyContainer } from 'components/stickycontainer/StickyContainer';
import styles from './styles.module.scss';

type Props = {
  products: Map<string, Product>;
  onSuccess: (product: Product) => void;
};

export const PresalePlanPicker = ({ products, onSuccess }: Props) => {
  const { t } = useTranslation();

  const {
    productWithMaxFee,
    productWithMinFee,
    presaleProducts,
    selectedProductCode,
    currencyCode,
    onClickContinueHandler,
    onSetProductCodeHandler,
  } = usePresaleLogic({
    products,
    onSuccess,
  });

  return (
    <div className={styles.container}>
      <PresaleInfo
        productWithMaxFee={productWithMaxFee}
        productWithMinFee={productWithMinFee}
        currencyCode={currencyCode}
      />
      <PricesContainer
        presaleProducts={presaleProducts}
        products={products}
        code={selectedProductCode}
        currencyCode={currencyCode}
        onSetProductCodeHandler={onSetProductCodeHandler}
      />
      <Spacer />
      <StickyContainer>
        <button
          className={`${styles.button} ${
            isPresalePulsingButtonFlow() ? styles.pulseEffect : ''
          }`}
          onClick={onClickContinueHandler}
        >
          {t('Continue')}
        </button>
      </StickyContainer>
    </div>
  );
};
