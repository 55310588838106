import React from 'react';
import styles from './styles.module.scss';

type Props = {
  bgColor?: string;
  leftPos: number;
};

export const ColorBox = ({ bgColor, leftPos }: Props) => {
  return (
    <div
      className={styles.colorBox}
      style={{ backgroundColor: bgColor, left: leftPos }}
    />
  );
};
