import { Product } from 'bootstrap/data/product/Product';
import { PlanSelectType } from './PlanSection.constants';
import { useAppDispatch } from 'bootstrap/hooks';
import { setPlanType } from 'bootstrap/user/actions/setPlanType';

type Props = {
  disabled?: boolean;
  product: Product;
  currencyCode: string | undefined;
};

export const usePlanSectionLogic = ({
  product,
  currencyCode,
  disabled,
}: Props) => {
  const dispatch = useAppDispatch();

  const currentDailyProductPrice = product.getAmountPerDay();

  const currentDailyRampPrice =
    product.getFirstRampIntervalAmountPerDay(currencyCode);

  const currentDailyFormattedProductPrice =
    product.getFormattedDailyPrice(currencyCode);

  const discountPercent = (
    ((currentDailyRampPrice - currentDailyProductPrice) /
      currentDailyRampPrice) *
    100
  ).toFixed(0);

  const onClickHandler = (type: PlanSelectType) => {
    if (!disabled) {
      dispatch(setPlanType(type));
    }
  };

  return {
    currentDailyFormattedProductPrice,
    discountPercent: parseFloat(discountPercent),
    onClickHandler,
  };
};
