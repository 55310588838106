import { Product } from 'bootstrap/data/product/Product';
import { useAppSelector } from 'bootstrap/hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { buildFreePaidTableSampleData } from './data/bodyData';
import styles from './styles.module.scss';

type Props = {
  product: Product;
};

export const FreePaidTable = ({ product }: Props) => {
  const { t } = useTranslation();
  const { currency } = useAppSelector((state) => state.user);

  const bodyData = useMemo(
    () => buildFreePaidTableSampleData(product, t, currency),
    [product, currency],
  );

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('freePaidTableTitle')}</div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th></th>
              <th className={styles.coach}>COACH</th>
              <th className={styles.loona}>
                <span className={styles.label}>LOÓNA</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {bodyData.map(({ id, title, coach, loona }) => (
              <tr key={id}>
                <td>{t(title)}</td>
                <td className={styles.coach}>{coach}</td>
                <td className={styles.loona}>{loona}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
