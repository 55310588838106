import React, { SyntheticEvent, useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import { BlitzAlertViewed, SaveAnswer } from 'analytics/AllEvents';
import { useAppDispatch } from 'bootstrap/hooks';
import { setAnswer } from 'bootstrap/onboarding/actions';
import { BlitzQuestion } from '../BlitzQuestion';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  visible: boolean;
  question: BlitzQuestion;
  onAnswer: () => void;
};

export const QuestionAlert = ({ visible, question, onAnswer }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (visible) {
      appAnalytics.trackEvent(new BlitzAlertViewed(question.id));
      setSelectedOption('');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [visible]);

  const handleYesClicked = (e: SyntheticEvent) => {
    e.preventDefault();
    if (selectedOption === '') {
      setSelectedOption('yes');
      appAnalytics.trackEvent(new SaveAnswer(question.id, 'yes'));
      setTimeout(() => {
        dispatch(setAnswer(question.id, 'yes', false));
        onAnswer();
      }, 200);
    }
  };

  const handleNoClicked = (e: SyntheticEvent) => {
    e.preventDefault();
    if (selectedOption === '') {
      setSelectedOption('no');
      appAnalytics.trackEvent(new SaveAnswer(question.id, 'no'));
      setTimeout(() => {
        dispatch(setAnswer(question.id, 'no', false));
        onAnswer();
      }, 200);
    }
  };

  return (
    <div className={`${styles.background} ${visible ? '' : styles.hidden}`}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t(question.title)}</h2>
        <div className={styles.buttons}>
          <button
            className={`${styles.button} ${
              selectedOption === 'no' ? styles.active : ''
            }`}
            onClick={handleNoClicked}
          >
            {t('no')}
          </button>
          <button
            className={`${styles.button} ${
              selectedOption === 'yes' ? styles.active : ''
            }`}
            onClick={handleYesClicked}
          >
            {t('yes')}
          </button>
        </div>
      </div>
    </div>
  );
};
