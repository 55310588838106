import { Reducer } from 'redux';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import { PurchaseActionTypes } from './actions/types';
import { SET_EMAIL } from './actions/setEmail';
import { SET_GIFT_CARD_TYPE } from './actions/giftCardType';
import { SET_PRODUCTS } from './actions/loadProducts';
import { SET_QUANTITY } from './actions/setQuantity';
import { SAVE_LINK } from './actions/saveLink';
import { PurchaseGiftCardState } from './state';

const INITIAL_STATE: PurchaseGiftCardState = {
  products: undefined,
  giftCardType: GiftCardTypes.Yearly,
  email: undefined,
  link: undefined,
  quantity: 1,
};

export const purchaseReducer: Reducer<
  PurchaseGiftCardState,
  PurchaseActionTypes
> = (state = INITIAL_STATE, action: PurchaseActionTypes) => {
  switch (action.type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case SET_GIFT_CARD_TYPE: {
      return {
        ...state,
        giftCardType: action.payload.type,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: action.payload.email,
      };
    }
    case SAVE_LINK: {
      return {
        ...state,
        link: action.payload.link,
      };
    }
    case SET_QUANTITY: {
      return {
        ...state,
        quantity: action.payload.quantity,
      };
    }
    default: {
      return state;
    }
  }
};
