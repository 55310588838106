import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

const DisclaimerInfo = () => {
  const { t } = useTranslation();

  return <div className={styles.disclaimerInfo}>{t('disclaimerInfo')}</div>;
};

export default DisclaimerInfo;
