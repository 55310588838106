import { Event } from 'analytics/Event';
import { Events } from 'analytics/Events';

export class ViewQPScreen extends Event {
  constructor(screenName: string, bundle?: string) {
    super(Events.ViewQPScreen, { screenName, bundle });
  }
}

export class ContinueQPOffer extends Event {
  constructor(bundle?: string) {
    super(Events.ContinueQPOffer, { bundle });
  }
}
