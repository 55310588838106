import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  currencyCode?: string;
  trialLength: number;
};

export const Note = ({ product, currencyCode, trialLength }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.note}>
      {!!trialLength ? (
        <>
          {t('paymentNoteLine1', { trialLength: product.trialLength })}{' '}
          {product.getTrialEndFormattedDate()}.{' '}
          <span className={styles.light}>
            {t('paymentNoteLine2', {
              periodAdverb: product.getRenewPeriodAdverbName(t),
              period: product.getRenewPeriodName(t),
              price: product.getFormattedPrice(currencyCode),
              trialLength: product.trialLength,
            })}
          </span>{' '}
          {t('paymentNoteLine3')}{' '}
          <span className={styles.light}>{t('paymentNoteLine4')}</span>
        </>
      ) : (
        <>
          Introductory subscription price is{' '}
          {product.getFormattedPrice(currencyCode)}. Renews automatically every{' '}
          {product.getRenewPeriodName(t)} at{' '}
          {product.getFormattedRenewsPrice(currencyCode)}.{' '}
          <span className={styles.light}>
            You will receive an email receipt each time your subscription
            renews. You can cancel your subscription anytime in your Account
            details in the app.
          </span>
        </>
      )}
    </div>
  );
};
