import React, { useCallback, useEffect } from 'react';
import CardCvv from 'assets/ic_card_cvv.svg';
import './styles.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
  isVisible: boolean;
  onClose: () => void;
};

export const Tooltip = ({ className, isVisible, onClose }: Props) => {
  const { t } = useTranslation();

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (isVisible) {
        e.stopPropagation();
        onClose();
      }
    },
    [isVisible, onClose],
  );

  useEffect(() => {
    window.addEventListener('click', clickListener);
    return () => {
      window.removeEventListener('click', clickListener);
    };
  }, [clickListener]);

  return (
    <div
      className={`
        ${className} 
        ${isVisible ? 'tooltip visible' : 'tooltip'}
      `}
    >
      <img className="tooltip-img" src={CardCvv} alt="" />
      {t('paymentCvvTooltipText')}
      <div className="tooltip-arrow" />
    </div>
  );
};
