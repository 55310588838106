import React, { ReactElement } from 'react';
import { ReactComponent as Check } from 'assets/freepaidsection/check.svg';
import { ReactComponent as PurpleCheck } from 'assets/freepaidsection/purple_check.svg';
import { ReactComponent as Dot } from 'assets/freepaidsection/dot.svg';
import { ReactComponent as Infinity } from 'assets/freepaidsection/infinity.svg';
import { Product } from 'bootstrap/data/product/Product';
import { formatPrice } from 'utils';
import styles from '../styles.module.scss';

export interface RowItem {
  id: number;
  title: string;
  coach: string | React.ReactSVGElement | ReactElement;
  loona: React.ReactSVGElement | ReactElement;
}

export const buildFreePaidTableSampleData = (
  product: Product,
  t?: any,
  currency?: string,
): RowItem[] => [
  {
    id: 1,
    title: 'freePaidTableSubtitle1',
    coach: <Check />,
    loona: <PurpleCheck />,
  },
  {
    id: 2,
    title: 'freePaidTableSubtitle2',
    coach: <Dot />,
    loona: <PurpleCheck />,
  },
  {
    id: 3,
    title: 'freePaidTableSubtitle3',
    coach: <Dot />,
    loona: <PurpleCheck />,
  },
  {
    id: 4,
    title: 'freePaidTableSubtitle4',
    coach: <Dot />,
    loona: <PurpleCheck />,
  },
  {
    id: 5,
    title: 'freePaidTableSubtitle5',
    coach: '45min',
    loona: <Infinity />,
  },
  {
    id: 6,
    title: 'freePaidTableSubtitle6',
    coach: (
      <span>
        {formatPrice(200, currency || 'USD', 0)}
        <br />
        <span className={styles.smallText}>session</span>
      </span>
    ),
    loona: (
      <div>
        {product.getFormattedPrice(currency)} <br />
        <span className={styles.smallText}>
          {product.getRenewPeriodName(t)}
        </span>
      </div>
    ),
  },
];
