import { appAnalytics } from 'analytics';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { GiftScreenOpened } from 'giftcards/analytics';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import { useEffect } from 'react';
import { pushWithSearchQuery } from 'utils';

export const usePurchaseLogic = () => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);
  const { products, giftCardType } = useAppSelector((state) => state.purchase);

  useEffect(() => {
    appAnalytics.trackEvent(new GiftScreenOpened('GiftPayment'));
  }, []);

  const onSuccess = () => {
    dispatch(pushWithSearchQuery('/gift/done'));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onError = () => {
    // TODO
  };

  const product = products && products[giftCardType];

  return {
    giftCardType,
    product,
    isLargeScreen,
    onSuccess,
    onError,
  };
};
