import { appAnalytics } from 'analytics';
import { ClickPreventGoBack, ViewPopup } from 'analytics/AllEvents';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setPreventBackPaymentOfferConfirmed } from 'bootstrap/user/actions/setPreventPaymentBack';
import { Action, Location } from 'history';
import { history } from 'index';
import {
  isGiftFlow,
  isInviteFlow,
  isPaymentPreventBack,
  isQuickPurchaseFlow,
  isRedeemFlow,
  isSubscriptionCancelFlow,
} from 'navigation';
import { useEffect, useState } from 'react';

export const useRouterPromptLogic = () => {
  const dispatch = useAppDispatch();
  const { isPreventBackPaymentOfferConfirmed, isTrialOfferShown } =
    useAppSelector((state) => state.user);

  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [isPaymentPrompt, setIsPaymentPrompt] = useState<boolean>(false);
  const [confirmedNavigation, setConfirmedNavigation] =
    useState<boolean>(false);

  useEffect(() => {
    if (isModalVisible) {
      appAnalytics.trackEvent(
        new ViewPopup(
          isPaymentPrompt ? 'preventGoBackPayment' : 'preventGoBack',
        ),
      );
    }
  }, [isModalVisible]);

  useEffect(() => {
    if (confirmedNavigation) {
      history.goBack();
    }
  }, [confirmedNavigation]);

  const closeModal = () => {
    setModalVisible(false);
    appAnalytics.trackEvent(new ClickPreventGoBack('no'));
  };

  const handleBlockNavigation = (
    nextLocation: Location,
    action: Action,
  ): boolean => {
    setConfirmedNavigation(false);

    const noPreventBackFlows = [
      isQuickPurchaseFlow(),
      isInviteFlow(),
      isGiftFlow(),
      isRedeemFlow(),
      isSubscriptionCancelFlow(),
    ];

    if (noPreventBackFlows.some((flow) => flow)) {
      return true;
    }

    if (!confirmedNavigation && action === 'POP') {
      if (history.location.pathname.includes('subscription')) {
        setIsPaymentPrompt(true);

        const shouldShowModal =
          isPaymentPreventBack() &&
          !isPreventBackPaymentOfferConfirmed &&
          !isTrialOfferShown;

        if (shouldShowModal) {
          setModalVisible(true);
          return false;
        }
        return true;
      } else {
        setIsPaymentPrompt(false);
        setModalVisible(true);
      }

      return false;
    }

    return true;
  };

  const handleConfirm = () => {
    if (isPaymentPrompt) {
      dispatch(setPreventBackPaymentOfferConfirmed(true));
      setTimeout(() => {
        window.scroll({
          top: 1070,
          behavior: 'smooth',
        });
      }, 1000);
    } else {
      setConfirmedNavigation(true);
      appAnalytics.trackEvent(new ClickPreventGoBack('yes'));
    }
    setModalVisible(false);
  };

  return {
    isPaymentPrompt,
    isModalVisible,
    closeModal,
    handleBlockNavigation,
    handleConfirm,
  };
};
