import { isSleepActivityLoaderV2 } from 'navigation';

export const getPreloadImages = (): string[] => {
  if (isSleepActivityLoaderV2()) {
    const Loader1 = require('assets/onboarding/loader/v2/1.png').default;
    const Loader2 = require('assets/onboarding/loader/v2/2.png').default;
    const Loader3 = require('assets/onboarding/loader/v2/3.png').default;
    const Loader4 = require('assets/onboarding/loader/v2/4.png').default;
    const Loader5 = require('assets/onboarding/loader/v2/5.png').default;
    const Loader6 = require('assets/onboarding/loader/v2/6.png').default;
    const Loader7 = require('assets/onboarding/loader/v2/7.png').default;
    const Loader8 = require('assets/onboarding/loader/v2/8.png').default;
    const Loader9 = require('assets/onboarding/loader/v2/9.png').default;
    const Loader10 = require('assets/onboarding/loader/v2/10.png').default;
    const Loader11 = require('assets/onboarding/loader/v2/11.png').default;

    return [
      Loader1,
      Loader2,
      Loader3,
      Loader4,
      Loader5,
      Loader6,
      Loader7,
      Loader8,
      Loader9,
      Loader10,
      Loader11,
    ];
  } else {
    const Loader1 = require('assets/onboarding/loader/1.jpg').default;
    const Loader2 = require('assets/onboarding/loader/2.jpg').default;
    const Loader3 = require('assets/onboarding/loader/3.jpg').default;
    const Loader4 = require('assets/onboarding/loader/4.jpg').default;
    const Loader5 = require('assets/onboarding/loader/5.jpg').default;
    const Loader6 = require('assets/onboarding/loader/6.jpg').default;
    const Loader7 = require('assets/onboarding/loader/7.jpg').default;
    const Loader8 = require('assets/onboarding/loader/8.jpg').default;
    const Loader9 = require('assets/onboarding/loader/9.jpg').default;
    const Loader10 = require('assets/onboarding/loader/10.jpg').default;
    const Loader11 = require('assets/onboarding/loader/11.jpg').default;

    return [
      Loader1,
      Loader2,
      Loader3,
      Loader4,
      Loader5,
      Loader6,
      Loader7,
      Loader8,
      Loader9,
      Loader10,
      Loader11,
    ];
  }
};
