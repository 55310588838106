import { Product } from 'bootstrap/data/product/Product';
import Divider from 'components/divider/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceAfterTrialInfo } from '../priceaftertrialinfo/PriceAfterTrialInfo';
import { TotalPriceInfo } from '../totalpriceinfo/TotalPriceInfo';
import { isAdhdTest } from 'navigation';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  presaleProduct?: Product;
  currencyCode?: string;
  handleGetPlanClick: () => void;
};

export const PaymentFormTemplate = ({
  product,
  presaleProduct,
  currencyCode,
  handleGetPlanClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.paymentFormTemplate}>
      {!!product.trialLength && (
        <TotalPriceInfo
          product={product}
          presaleProduct={presaleProduct}
          currencyCode={currencyCode}
        />
      )}

      <PriceAfterTrialInfo product={product} currencyCode={currencyCode} />

      {!isAdhdTest() && (
        <>
          <Divider />
          <button className={styles.submit} onClick={handleGetPlanClick}>
            {t('getPlanButton')}
          </button>
        </>
      )}
    </div>
  );
};
