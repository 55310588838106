import { QuestionIds } from '../../data/constants/QuestionIds';
import { Question, QuestionTypes } from '../Question';

export class EducationAnswer extends Question {
  correctAnswerId: string;
  title?: string;
  description?: string;

  constructor(
    id: QuestionIds,
    correctAnswerId: string,
    title?: string,
    description?: string,
  ) {
    super(QuestionTypes.EduAnswer, id, true);
    this.title = title;
    this.description = description;
    this.correctAnswerId = correctAnswerId;
  }
}
