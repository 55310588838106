import React, { useEffect, useState } from 'react';
import { fetchAndActivate, getString } from 'firebase/remote-config';
import { appAnalytics } from 'analytics';
import { Install, ViewCongrats } from 'analytics/AllEvents';
import { remoteConfig } from 'analytics/Firebase';
import { useAppSelector } from 'bootstrap/hooks';
import { SuccessTemplate } from 'onboarding/components/successtemplate/SuccessTemplate';
import { getOneLink, getQueryVariable } from 'utils';
import { useTranslation } from 'react-i18next';

export const Success = () => {
  const { t } = useTranslation();

  const { hasSubscription, oldUser } = useAppSelector((state) => state.user);
  const [background, setBackground] = useState<string | undefined>(undefined);
  const [bigBackground, setBigBackground] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    appAnalytics.trackEvent(new ViewCongrats(!hasSubscription));
  }, [hasSubscription]);

  useEffect(() => {
    const backgroundName = getQueryVariable('cov');
    if (backgroundName) {
      fetchAndActivate(remoteConfig).then(() => {
        setBackground(getString(remoteConfig, `qp_cover_${backgroundName}`));
        setBigBackground(
          getString(remoteConfig, `qp_cover_big_${backgroundName}`),
        );
      });
    }
  }, []);

  const handleButtonClick = () => {
    appAnalytics.trackEvent(new Install());
    window.location.href = getOneLink({
      webDp: `${process.env.REACT_APP_FRONTEND_URL}/install`,
      mediaSource: 'quick_purchase',
      retargeting: true,
    });
  };

  return (
    <SuccessTemplate
      title={t('successTemplateTitle')}
      description={
        <>
          {oldUser
            ? t('successTemplateDescription')
            : t('successTemplateDescription2')}
          <br />
          of Loóna <span className="plus">PLUS</span>
        </>
      }
      cover={background}
      bigCover={bigBackground}
      onButtonClick={handleButtonClick}
      button={t('successTemplateInstallButton')}
    />
  );
};
