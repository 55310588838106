import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import { initOptimize } from './analytics/GoogleAnalytics';
import { Onboarding } from './onboarding/Onboarding';
import { QuickPurchase } from './quickpurchase/QuickPurchase';
import SignIn from './signin/SignIn';
import { PresalePage } from './subscription/presale/PresalePage';
import { Subscription } from './subscription/Subscription';
import { Congrats } from './success/congrats/Congrats';
import { InvitePurchase } from './invitepurchase/InvitePurchase';
import { GiftCards } from './giftcards/GiftCards';
import { Redeem } from 'redeem/Redeem';
import { Amplitude } from './analytics/Amplitude';
import RouterPrompt from './components/routerprompt/RouterPrompt';
import Install from './install/Install';
import Welcome from 'welcome/Welcome';
import { Preplan } from 'subscription/prepaln/Preplan';
import { Workbook } from 'subscription/workbook/Workbook';
import { SubscriptionCancel } from 'cancelsubscription/SubscriptionCancel';
import './App.scss';

const App = () => {
  const initAmplitudeUserProperties = (): void => {
    const width = window.screen.width * window.devicePixelRatio;
    const height = window.screen.height * window.devicePixelRatio;

    Amplitude.setUserProperty('user_agent', window.navigator.userAgent);
    Amplitude.setUserProperty('screen_resolution', `${width}x${height}`);
  };

  useEffect(() => {
    initOptimize();
    initAmplitudeUserProperties();
  }, []);

  return (
    <div className="App">
      <div className="AppContainer">
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 1 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path="/create-account" component={SignIn} />
          <Route exact path="/plan" component={PresalePage} />
          <Route exact path="/preplan" component={Preplan} />
          <Route exact path="/subscription" component={Subscription} />
          <Route exact path="/workbook" component={Workbook} />
          <Route exact path="/done" component={Congrats} />

          <Route exact path="/onboarding/:questionId" component={Onboarding} />
          <Route exact path="/onboarding" component={Onboarding} />

          <Route path="/quick-purchase" component={QuickPurchase} />
          <Route path="/invite" component={InvitePurchase} />
          <Route path="/install" component={Install} />
          <Route path="/gift" component={GiftCards} />
          <Route path="/redeem" component={Redeem} />

          <Route
            path="/subscription-cancel/:questionId"
            component={SubscriptionCancel}
          />

          <Route component={Welcome} />
        </AnimatedSwitch>
        <RouterPrompt />
      </div>
    </div>
  );
};

export default App;
