import React from 'react';
import Logo from 'assets/logo_loona.svg';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import { PlanImage } from '../planimage/PlanImage';
import {
  LIFETIME_CARD_IMAGE,
  YEARLY_CARD_IMAGE,
} from './GiftTemplate.constants';
import { usePreloadImages } from 'hooks/usePreloadImages';
import { FogBackground } from '../fogbackground/FogBacakground';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
  bottomSection?: JSX.Element | JSX.Element[];
  giftCardType: GiftCardTypes;
};

export const GiftTemplate = ({
  children,
  giftCardType,
  bottomSection,
}: Props) => {
  usePreloadImages([LIFETIME_CARD_IMAGE, YEARLY_CARD_IMAGE]);

  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  if (isLargeScreen) {
    return (
      <FogBackground giftCardType={giftCardType}>
        <div className={styles.contentContainer}>
          <img className={styles.logo} src={Logo} alt="Loóna" />
          <div className={styles.textWrapper}>
            <img
              className={styles.cardContainer}
              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}/gift/${giftCardType}.png`}
              alt=""
            />
            {children}
          </div>
          {bottomSection || <div />}
        </div>
      </FogBackground>
    );
  } else {
    return (
      <div className={styles.contentContainer}>
        <PlanImage giftCardType={giftCardType} />
        <div className={styles.textWrapper}>
          {children}
          {bottomSection || null}
        </div>
      </div>
    );
  }
};
