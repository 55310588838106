import { SyntheticEvent, useEffect, useState } from 'react';
import { appAnalytics } from 'analytics';
import {
  ProductSelected,
  ScreenOpened,
  SwitchPresalePlanCode,
} from 'analytics/AllEvents';
import { defaultPresaleOfferConfig } from 'subscription/presale/Presale.constants';
import { Product } from 'bootstrap/data/product/Product';
import {
  getPresaleConfigPrefix,
  getPresaleOfferConfig,
} from 'bootstrap/data/product/Product.utils';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { setPresaleProduct } from 'bootstrap/product/actions';
import {
  getMaxFeeProductCode,
  getMinFeeProductCode,
  getPopularProductCode,
  getPresaleOfferProducts,
} from './Presale.utils';

type Props = {
  products: Map<string, Product>;
  onSuccess: (product: Product) => void;
};

export const usePresaleLogic = ({ products, onSuccess }: Props) => {
  const dispatch = useAppDispatch();
  const { presaleProductCode } = useAppSelector((state) => state.product);
  const { currency } = useAppSelector((state) => state.user);

  const presaleOfferConfig = defaultPresaleOfferConfig;

  const productCodeWithMaxFee = getMaxFeeProductCode(
    products,
    presaleOfferConfig,
  );
  const productCodeWithMinFee = getMinFeeProductCode(
    products,
    presaleOfferConfig,
  );

  const popularProductCode = getPopularProductCode(presaleOfferConfig);

  const defaultSelectedProductCode =
    popularProductCode || productCodeWithMaxFee;

  const [selectedProductCode, setSelectedProductCode] = useState(
    presaleProductCode || defaultSelectedProductCode,
  );

  useEffect(() => {
    appAnalytics.trackEvent(
      new ScreenOpened('onboardingPrice', {
        prefix: getPresaleConfigPrefix(),
        presaleConfigValue: JSON.stringify(
          getPresaleOfferConfig(presaleOfferConfig),
        ),
      }),
    );
  }, []);

  useEffect(() => {
    const selectedProduct = products.get(selectedProductCode);

    if (!selectedProduct) return;

    dispatch(setPresaleProduct(selectedProductCode));

    const previousSelectedProductCode =
      presaleProductCode || defaultSelectedProductCode;
    if (selectedProductCode !== previousSelectedProductCode) {
      appAnalytics.trackEvent(
        new SwitchPresalePlanCode(
          selectedProductCode,
          previousSelectedProductCode,
        ),
      );
    }
  }, [selectedProductCode]);

  const onSetProductCodeHandler = (code: string) => {
    setSelectedProductCode(code);

    setTimeout(() => {
      onClickContinueHandler(undefined, code);
    }, 500);
  };

  const onClickContinueHandler = (
    event?: SyntheticEvent,
    productCode?: string,
  ) => {
    const selectedProduct = products.get(productCode || selectedProductCode);

    if (!selectedProduct) return;

    appAnalytics.trackEvent(
      new ProductSelected(
        selectedProduct.code,
        selectedProduct.code === defaultSelectedProductCode,
        defaultSelectedProductCode,
      ),
    );
    onSuccess(selectedProduct);
  };

  return {
    selectedProductCode,
    productCodeWithMaxFee,
    presaleProducts: getPresaleOfferProducts(presaleOfferConfig),
    currencyCode: currency,
    productWithMinFee: products.get(productCodeWithMinFee),
    productWithMaxFee: products.get(productCodeWithMaxFee),
    onSetProductCodeHandler,
    onClickContinueHandler,
  };
};
