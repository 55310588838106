import { PresaleOfferConfig } from './Presale.types';

export const defaultPresaleOfferConfig: PresaleOfferConfig[] = [
  {
    productCode: 'co-loona-web-ar-q-t7-59-pt0099',
  },
  {
    productCode: 'co-loona-web-ar-q-t7-59-pt0500',
  },
  {
    productCode: 'co-loona-web-ar-q-t7-59-pt1000',
    label: 'popular',
    isActive: true,
  },
  {
    productCode: 'co-loona-web-ar-q-t7-59-pt1741',
    label: 'teamCost',
  },
];
