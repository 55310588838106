import { UserActionTypes } from './userActions.types';

export const SET_PREVENT_BACK_PAYMENT_OFFER_CONFIRMED =
  'SET_PREVENT_BACK_PAYMENT_OFFER_CONFIRMED';

export function setPreventBackPaymentOfferConfirmed(
  shown: boolean,
): UserActionTypes {
  return {
    type: SET_PREVENT_BACK_PAYMENT_OFFER_CONFIRMED,
    payload: {
      isPreventBackPaymentOfferConfirmed: shown,
    },
  };
}
