import { pushWithSearchQuery } from 'utils';
import { AppThunk } from 'bootstrap/types';
import { Answers } from '../state';
import { SubscriptionCancelActionTypes } from './types';
import { AppDispatch } from 'index';
import { QuestionIds } from 'cancelsubscription/data/constants/QuestionsIds';
import { OptionalQuestion } from 'cancelsubscription/components/question/options/OptionQuestion';
import { QuestionsOrder } from 'cancelsubscription/data/constants/QuestionOrder';
import { QUESTIONS_META } from 'cancelsubscription/data/meta';
import { QuestionTypes } from 'cancelsubscription/components/question/Question';

export const SET_ANSWER = 'SET_SUBSCRIPTION_CANCEL_ANSWER';
export const SET_CURRENT_QUESTION = 'SET_SUBSCRIPTION_CANCEL_CURRENT_QUESTION';

export const setAnswer =
  (
    question: QuestionIds,
    answer: string | string[],
    navigateNext = true,
  ): AppThunk<SubscriptionCancelActionTypes> =>
  (dispatch: AppDispatch, _) => {
    dispatch({
      type: SET_ANSWER,
      payload: {
        question,
        answer,
      },
    });
    navigateNext && dispatch(goToNextQuestion());
  };

export const hasOption = (
  answers: Answers,
  question: OptionalQuestion,
): boolean => {
  const answer = answers[question.sourceQuestionId]?.[0];
  return typeof answer === 'string' && !!question.options[answer];
};

export const goToNextQuestion =
  (): AppThunk<SubscriptionCancelActionTypes> =>
  (dispatch: AppDispatch, getState) => {
    const currentQuestionId = window.location.pathname.replace(
      '/subscription-cancel/',
      '',
    );
    let currentQuestionIndex = QuestionsOrder.indexOf(
      currentQuestionId as QuestionIds,
    );
    const { answers } = getState().subscriptionCancel;

    const nextQuestionMeta =
      QUESTIONS_META[QuestionsOrder[currentQuestionIndex + 1]];
    if (nextQuestionMeta && nextQuestionMeta.type === QuestionTypes.Optional) {
      if (!hasOption(answers, nextQuestionMeta as OptionalQuestion)) {
        currentQuestionIndex += 1;
      }
    }

    if (QuestionsOrder.length - 1 > currentQuestionIndex) {
      const nextQuestion = QuestionsOrder[currentQuestionIndex + 1];

      dispatch({
        type: SET_CURRENT_QUESTION,
        question: QuestionsOrder[currentQuestionIndex + 1],
      });
      dispatch(pushWithSearchQuery(`/subscription-cancel/${nextQuestion}`));
    } else {
      dispatch(pushWithSearchQuery('/subscription-cancel/Welcome'));
    }
  };
