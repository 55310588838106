import React from 'react';
import { Logo } from 'components/logo/Logo';
import CardsBackground from 'assets/bg_cards_small.png';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element[] | JSX.Element;
};

export const RedeemTemplate = ({ children }: Props) => {
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  if (isLargeScreen) {
    return (
      <div className={styles.contentContainer}>
        <Logo className={styles.logo} />
        <div className={styles.bigBackgroundContainer}>
          <div className={styles.topGradient} />
          <div className={styles.overlayContainer}>
            <div className={styles.textWrapper}>{children}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.contentContainer}>
        <Logo className={styles.logo} />
        <div className={styles.background}>
          <img
            className={styles.backgroundImage}
            src={CardsBackground}
            alt=""
          />
          <div className={styles.gradient} />
        </div>
        <div className={styles.textWrapper}>{children}</div>
      </div>
    );
  }
};
