import { QuestionIds } from '../constants/QuestionIds';
import { Steps } from '../constants/StepsLabels';
import { PersonalizationSectionQuestionIds } from '../PersonalizationQuestionIds';

export const GadTestSteps: Map<Steps, QuestionIds[]> = new Map([
  [
    Steps.Anxiety,
    [
      QuestionIds.GadQ1,
      QuestionIds.GadQ2,
      QuestionIds.GadQ3,
      QuestionIds.GadQ4,
      QuestionIds.GadQ5,
      QuestionIds.GadQ6,
      QuestionIds.GadQ7,
      QuestionIds.AboutYouProof1,
    ],
  ],
  [
    Steps.AboutYou,
    [
      QuestionIds.AboutYouGender,
      QuestionIds.AboutYouAge,
      QuestionIds.AboutYouRoutine,
      QuestionIds.AboutYouKids,
      QuestionIds.AboutYouActivity,
      QuestionIds.SleepAndActivityProof2,
      QuestionIds.SleepAndActivityCoffee,
      QuestionIds.SleepAndActivityDinner,
      QuestionIds.SleepAndActivityAfterMidnight,
      QuestionIds.SleepAndActivityLessThanEightHours,
      QuestionIds.SleepAndActivityExtraMotivation,
    ],
  ],
  [Steps.Personalization, PersonalizationSectionQuestionIds],
]);
