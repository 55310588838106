import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  productWithMaxFee: Product | undefined;
  productWithMinFee: Product | undefined;
  currencyCode?: string;
};

export const PresaleInfo = ({
  productWithMaxFee,
  productWithMinFee,
  currencyCode,
}: Props) => {
  const { t } = useTranslation();
  const productWithMinPrice =
    productWithMinFee?.getFormattedInvoicePrice(currencyCode);
  const productWithMaxPrice =
    productWithMaxFee?.getFormattedInvoicePrice(currencyCode);

  return (
    <>
      <p className={styles.line}>{t('presaleInfoLine1')}</p>
      <p className={`${styles.line} ${styles.bold} ${styles.top22}`}>
        {t('presaleInfoLine2', {
          price: productWithMinPrice,
        })}
      </p>

      {productWithMaxFee && (
        <p className={`${styles.line} ${styles.accent} ${styles.bold}`}>
          {t('presaleInfoLine3', {
            price: productWithMaxPrice,
          })}
        </p>
      )}

      <p className={`${styles.line} ${styles.small}`}>
        {t('presaleInfoLine4')}
      </p>
    </>
  );
};
