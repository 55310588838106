import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useAppDispatch, useAppSelector } from 'bootstrap/hooks';
import { loadProducts } from 'bootstrap/product/actions';
import { RootState } from 'bootstrap/types';
import { defaultProductCode } from 'bootstrap/data/product/Product.utils';

type Products = {
  product?: Product;
  products?: Map<string, Product>;
  isLoading: boolean;
  error: boolean;
};

const selectProducts = (state: RootState) => state.product.products;
const selectPresaleProductCode = (state: RootState) =>
  state.product.presaleProductCode;

const selectProductsInfo = createSelector(
  [selectProducts, selectPresaleProductCode],
  (products, presaleProductCode) => {
    let productCode = '';

    if (presaleProductCode) {
      productCode = presaleProductCode;
    } else {
      productCode = defaultProductCode;
    }

    return {
      products,
      productCode,
    };
  },
);

export const useProduct = (): Products => {
  const dispatch = useAppDispatch();

  const { products, productCode } = useAppSelector(selectProductsInfo);

  const [isLoading, setIsLoading] = useState(
    !products || !products.size || products.size === 0,
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!products || !products.size || products.size === 0) {
      loadAllProducts();
    }
  }, []);

  const loadAllProducts = () => {
    dispatch(loadProducts())
      .catch((_) => {
        setError(true);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (products && products.size > 0) {
      setError(false);
    }
  }, [products]);

  if (!products || !products.size || products.size === 0) {
    return {
      isLoading,
      error,
    };
  }

  return {
    product:
      (products?.get && products?.get(productCode)) ||
      products?.values().next().value,
    products: products,
    error: false,
    isLoading: false,
  };
};
