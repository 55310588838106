import React from 'react';
import { CardElement } from '@recurly/react-recurly';
import { getCardElementStyle } from './elementStyles';
import styles from './styles.module.scss';

type Props = {
  className?: string;
  validationError?: string;
  label?: string;
  onFocusHandler?: () => void;
  onChangeHandler?: (event: any) => void;
};

export const CardElementsField = ({
  className,
  label,
  validationError,
  onFocusHandler,
  onChangeHandler,
}: Props) => {
  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>
      <CardElement
        style={getCardElementStyle()}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
      />
      <div className={styles.formError}>{validationError}</div>
    </div>
  );
};
