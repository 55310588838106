import React from 'react';
import Cards from 'assets/payments/cards.png';
import styles from './styles.module.scss';
import Modal from 'components/modal/Modal';

type Props = {
  isModalVisible: boolean;
  handleConfirm: () => void;
};

const PaymentPromptModal = ({ isModalVisible, handleConfirm }: Props) => {
  return (
    <Modal visible={isModalVisible} className={styles.modal}>
      <div className={styles.prompt}>
        <img src={Cards} alt="" />
        <div className={styles.title}>Not ready to commit yet?</div>
        <div className={styles.description}>
          Because we're so confident you'll notice the difference once you
          start, we are going to{' '}
          <span className={styles.accent}>
            let you try Loóna for 7 days free!
          </span>
        </div>
        <button className={styles.outlined} onClick={handleConfirm}>
          Continue
        </button>
      </div>
    </Modal>
  );
};
export default PaymentPromptModal;
