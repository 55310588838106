import { ThreeDSecureAction } from '@recurly/react-recurly';
import React, { useEffect } from 'react';

type Props = {
  token: string;
  onSuccess: (token: string) => void;
  onError: (error: string) => void;
};

export const ThreeDSecure = ({ token, onSuccess, onError }: Props) => {
  useEffect(() => {
    if (token !== '') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  });

  return token !== '' ? (
    <div className="alert-container">
      <ThreeDSecureAction
        actionTokenId={token}
        onToken={(token) => {
          onSuccess(token.id);
        }}
        onError={(error) => {
          onError(error.message);
        }}
        className="alert recurly-3ds"
      />
    </div>
  ) : null;
};
