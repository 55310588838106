import React, { useEffect } from 'react';
import Modal from 'components/modal/Modal';
import { ReactComponent as Discount } from 'assets/discount.svg';
import styles from './styles.module.scss';
import { ViewSkipTrialPopup } from 'analytics/AllEvents';
import { appAnalytics } from 'analytics';
import { useTranslation } from 'react-i18next';

type Props = {
  discountPercent?: number;
  handleContinueClick: () => void;
};

export const DiscountPopup = ({
  handleContinueClick,
  discountPercent,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    appAnalytics.trackEvent(new ViewSkipTrialPopup());
  }, []);

  return (
    <Modal visible={true} className={styles.modal}>
      <div className={styles.contentWrapper}>
        <div className={styles.discountBackground}>
          <Discount />
        </div>
        <div className={styles.offer}>
          <p>{t('discountPopupLine1')}</p>
          <p>{t('discountPopupLine2')}</p>
          <p>
            {t('discountPopupLine3')}
            <span className={styles.accent}>
              {' '}
              {t('discountPopupLine4', { discountPercent })}
            </span>
          </p>
        </div>
        <button onClick={handleContinueClick}>{t('Continue')}</button>
      </div>
    </Modal>
  );
};
