import React from 'react';
import Logo from 'assets/logo_loona.svg';
import { FogBackground } from '../fogbackground/FogBackground';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import MonthlySubscriptionCard from 'assets/monthly_subscription.png';
import { PlanImage } from '../planimage/PlanImage';
import styles from './styles.module.scss';

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const WelcomeTemplate = ({ children }: Props) => {
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  if (isLargeScreen) {
    return (
      <FogBackground>
        <div className={styles.contentContainer}>
          <img className={styles.logo} src={Logo} alt="Loóna" />
          <div className={styles.textWrapper}>
            <img
              className={styles.cardContainer}
              src={MonthlySubscriptionCard}
              alt=""
            />
            {children}
          </div>
        </div>
      </FogBackground>
    );
  } else {
    return (
      <PlanImage>
        <div className={styles.textWrapper}>{children}</div>
      </PlanImage>
    );
  }
};
