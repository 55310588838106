export enum AnxietyTypes {
  Minimal = 'Minimal',
  Mild = 'Mild',
  Moderate = 'Moderate',
  Severe = 'Severe',
}

export interface GadResult {
  title: string;
  description: string;
  imageSrc: string;
}
