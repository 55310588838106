import { convertFromServer } from 'bootstrap/data/giftproduct/GiftCardProduct';
import { GiftCardProducts } from 'bootstrap/data/giftproduct/GiftCardProducts';
import { GiftCardTypes } from 'bootstrap/data/giftproduct/GiftCard.types';
import {
  LIFETIME_PRODUCT_CODE,
  ServerGiftCardProducts,
  YEARLY_PRODUCT_CODE,
} from './giftcard.types';

export const convertToGiftCards = (
  serverGiftCards: ServerGiftCardProducts,
  currency?: string,
): GiftCardProducts => {
  const yearlyGiftCard = serverGiftCards[YEARLY_PRODUCT_CODE];
  const lifetimeGiftCard = serverGiftCards[LIFETIME_PRODUCT_CODE];

  if (!yearlyGiftCard || !lifetimeGiftCard) {
    throw Error('Unable to load gift card plans');
  }

  return {
    [GiftCardTypes.Yearly]: convertFromServer(yearlyGiftCard, currency),
    [GiftCardTypes.Lifetime]: convertFromServer(lifetimeGiftCard, currency),
  };
};
