export const LINK_PARAMS_MAP: Record<string, string> = {
  mediaSource: 'pid',
  webDp: 'af_web_dp',
  campaign: 'c',
  campaignId: 'af_c_id',
  retargeting: 'is_retargeting',
  iosUrl: 'af_ios_url',
  androidUrl: 'af_android_url',
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  deepLink: 'deep_link_value',
  qr: 'af_qr',
  utm_source: 'c',
  utm_medium: 'af_adset',
  utm_campaign: 'af_ad',
  experience: 'af_xp',
};

export const EMAIL_VALIDATION_ERROR = 'Please enter a valid email';
export const PASSWORD_VALIDATION_ERROR = 'Please enter a valid password';

export const RECURLY_KEY = process.env.REACT_APP_RECURLY_KEY || '';
