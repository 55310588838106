import React, { SyntheticEvent, useEffect } from 'react';
import { Logo } from 'components/logo/Logo';
import { MOBILE_QUERY, useMediaQuery } from 'hooks/useMediaQuery';
import PhoneBackground from 'assets/onboarding/bg_phone_small.jpg';
import BigPhoneBackground from 'assets/onboarding/bg_phone.jpg';
import styles from './styles.module.scss';

type Props = {
  title: string;
  subTitle?: string;
  description: string | JSX.Element;
  button: string | JSX.Element;
  note?: string;
  cover?: string;
  bigCover?: string;
  onButtonClick?: () => void;
};

export const SuccessTemplate = ({
  title,
  subTitle,
  description,
  button,
  note,
  cover = PhoneBackground,
  bigCover = BigPhoneBackground,
  onButtonClick,
}: Props) => {
  const isLargeScreen = useMediaQuery(MOBILE_QUERY);

  useEffect(() => {
    document.body.classList.add(styles.bodyBackground);
    return () => {
      document.body.classList.remove(styles.bodyBackground);
    };
  }, []);

  const handleButtonClick = (e?: SyntheticEvent) => {
    e?.preventDefault();
    onButtonClick?.();
  };

  return (
    <div className={styles.contentContainer}>
      <div
        className={styles.phoneBackgroundContainer}
        style={{
          backgroundImage: isLargeScreen
            ? `url(${bigCover || BigPhoneBackground})`
            : 'none',
        }}
      >
        <div
          className={styles.phoneBackground}
          style={{
            backgroundImage: isLargeScreen
              ? 'none'
              : `url(${cover || PhoneBackground})`,
          }}
        />
        <Logo className={styles.logo} />
        <div className={styles.gradient} />
      </div>
      <div className={styles.textWrapper}>
        <div className={styles.topSpace} />
        <div className={styles.title}>{title}</div>
        <div className={styles.subTitle}>{subTitle}</div>
        <div className={styles.description}>{description}</div>
        {typeof button === 'string' ? (
          <button className={styles.button} onClick={handleButtonClick}>
            {button}
          </button>
        ) : (
          button
        )}
        <div className={styles.note}>{note}</div>
      </div>
    </div>
  );
};
