import { useState } from 'react';

export const useSubmit = (callback: (args?: any) => void) => {
  const [called, setCalled] = useState(false);

  return (args?: any) => {
    if (!called) {
      setCalled(true);
      callback(args);
    }
  };
};
