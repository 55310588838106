import React from 'react';
import { Product } from 'bootstrap/data/product/Product';
import { useTranslation } from 'react-i18next';
import { usePlanSectionLogic } from './usePlanSection.hook';
import { PlanSelectType } from './PlanSection.constants';
import styles from './styles.module.scss';

type Props = {
  product: Product;
  currencyCode?: string;
  label?: string;
  isSelected: boolean;
  type: PlanSelectType;
  disabled?: boolean;
  isRenewPriceVisible?: boolean;
  isManual?: boolean;
  onClickHandler?: () => void;
};

export const PlanSection: React.FC<Props> = ({
  product,
  currencyCode,
  label,
  isSelected,
  type,
  disabled,
  isRenewPriceVisible,
  isManual,
}) => {
  const { t } = useTranslation();
  const { currentDailyFormattedProductPrice, onClickHandler } =
    usePlanSectionLogic({
      disabled,
      product,
      currencyCode,
    });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currencySign, firstPriceSymbol, point, ...otherPriceSymbols] =
    currentDailyFormattedProductPrice?.split('') || [];

  const renderLabel = () =>
    label && <div className={styles.label}>{label.toUpperCase()}</div>;

  const renderDiscount = () => (
    <div className={styles.discount}>
      {isRenewPriceVisible && (
        <span className={`${styles.old} ${isSelected ? styles.selected : ''}`}>
          <del>{product.getFormattedRenewsPrice(currencyCode)}</del>
        </span>
      )}
      <span className={`${styles.new} ${isSelected ? styles.selected : ''}`}>
        {isManual ? '$5.99' : product.getFormattedPrice(currencyCode)}
      </span>
    </div>
  );

  const renderPrice = () => (
    <div className={styles.price}>
      <div className={styles.currency}>
        <span className={styles.currencySymbol}>{currencySign}</span>
      </div>
      <span className={styles.firstSymbol}>
        {isManual ? '0' : firstPriceSymbol}
      </span>
      <div className={styles.otherPrice}>
        <span className={styles.otherPriceSymbols}>
          {isManual ? '86' : otherPriceSymbols.join('')}
        </span>
        <span
          className={`${styles.priceLabel} ${
            isSelected ? styles.selected : ''
          }`}
        >
          PER DAY
        </span>
      </div>
    </div>
  );

  const renderTerm = () => (
    <div className={styles.term}>
      {isManual
        ? '1-Week Plan'
        : t('paymentPlanDuration', {
            duration: product.getPlanSectionTerm(t, false),
          })}
      {renderDiscount()}
    </div>
  );

  return (
    <div
      className={`
        ${styles.container}
         ${isSelected ? styles.selected : ''} 
         ${styles.multiple}
      `}
      onClick={() => onClickHandler(type)}
    >
      {renderLabel()}
      <div className={styles.content}>
        {renderTerm()}
        {renderPrice()}
      </div>
    </div>
  );
};
