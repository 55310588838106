import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  changeSubscriptionWithCredit,
  checkSubscription,
  refundSkipTrialSubscription,
  skipTrialWithCreditSubscription,
  cancelSubscription,
  prolongSubscription,
  changeSkipTrialSubscription,
} from 'bootstrap/net/subscription';
import { RootState } from 'bootstrap/types';
import { getErrorMessage } from 'bootstrap/net/commonnet';
import { SET_SUBSCRIPTION } from './setSubscriptionActive';
import {
  buyWorkbook,
  changeSubscription,
} from 'bootstrap/net/subscription/subscription';
import { Workbook } from 'bootstrap/net/subscription/subscription.types';

export const refundSkipTrialSubscriptionPlan =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async () => {
    return refundSkipTrialSubscription();
  };

export const skipTrialWithCreditSubscriptionPlan =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async () => {
    return skipTrialWithCreditSubscription();
  };

export const cancelSubscriptionPlan =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => async () => {
    return cancelSubscription();
  };

export const prolongSubscriptionPlan =
  (days: number): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async () => {
    return prolongSubscription(days);
  };

export const buyWorkbookPlan =
  ({
    currency,
    planCode,
  }: Workbook): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async () => {
    return buyWorkbook({
      currency,
      planCode,
    });
  };

export const changeSubscriptionPlan =
  (
    planCode?: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async () => {
    return changeSubscription(planCode);
  };

export const changeSkipTrialSubscriptionPlan =
  (
    planCode?: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async () => {
    return changeSkipTrialSubscription(planCode);
  };

export const changeSubscriptionWithCreditPlan =
  (
    planCode?: string,
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async () => {
    return changeSubscriptionWithCredit(planCode);
  };

export const checkActiveSubscription =
  (): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    return checkSubscription()
      .then((isActive) => {
        dispatch({
          type: SET_SUBSCRIPTION,
          payload: {
            hasSubscription: isActive,
            oldUser: isActive,
          },
        });
        return Promise.resolve(isActive);
      })
      .catch((error) => {
        return Promise.reject(getErrorMessage(error));
      });
  };
