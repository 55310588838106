import { StepperItem } from '../../StepperItem';

export enum LineState {
  Filled,
  Active,
  Empty,
}

export abstract class BaseLine implements StepperItem {
  state: LineState;

  protected constructor(state: LineState) {
    this.state = state;
  }
}

export class FilledLine extends BaseLine {
  constructor() {
    super(LineState.Filled);
  }
}

export class EmptyLine extends BaseLine {
  constructor() {
    super(LineState.Empty);
  }
}

export class ActiveLine extends BaseLine {
  label: string;
  progress: number;

  constructor(label: string, progress: number) {
    super(LineState.Active);
    this.label = label;
    this.progress = progress;
  }
}

export { LineComponent } from './LineComponent';
