import { appAnalytics } from 'analytics';
import { useAppDispatch } from 'bootstrap/hooks';
import { useEffect } from 'react';
import { RedeemScreenOpened, RedeemWelcomeContinue } from 'redeem/analytics';
import { pushWithSearchQuery } from 'utils';

export const useStartRedeemLogic = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    appAnalytics.trackEvent(new RedeemScreenOpened('redeemWelcome'));
  }, []);

  const onRedeemClick = () => {
    appAnalytics.trackEvent(new RedeemWelcomeContinue());
    dispatch(pushWithSearchQuery('/redeem/account'));
  };

  return {
    onRedeemClick,
  };
};
