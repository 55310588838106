import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'bootstrap/hooks';
import { goToNextQuestion } from 'bootstrap/onboarding/actions';
import { easeInOutQuad } from 'hooks/useProgress';
import { QUESTIONS_META } from 'onboarding/data/meta';
import { QuestionTypes } from 'onboarding/question/Question';
import { QuestionAlert } from './alert/QuestionAlert';
import { BlitzQuestion } from './BlitzQuestion';
import { ProgressBar } from './progressbar/ProgressBar';
import { ReviewCarousel } from './reviewcarousel/ReviewCarousel';
import { useTranslation } from 'react-i18next';
import { isBlitzV2Flow, isPersonalizationLoaderEstimateFlow } from 'navigation';
import { QuestionIds } from 'onboarding/data/constants/QuestionIds';
import styles from './styles.module.scss';

const ALERTS: BlitzQuestion[] = Object.values(QUESTIONS_META)
  .filter((value) => value.type === QuestionTypes.Blitz)
  .filter((question) =>
    isBlitzV2Flow()
      ? question.variationId !== QuestionIds.BlitzDaydream &&
        question.variationId !== QuestionIds.BlitzRead
      : question.variationId !== QuestionIds.BlitzShiftWork &&
        question.variationId !== QuestionIds.BlitzADHD,
  )
  .map((it) => it as BlitzQuestion);

const ALERT_PROGRESS = [0.75, 0.9, 0.55, 0.85];

const STEP_DURATION = 5000;

export const AnalysisLoader = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(0);
  const [alertShown, setAlertShown] = useState(false);
  const [progressPaused, setProgressPaused] = useState(false);

  useEffect(() => {
    if (!progressPaused) {
      const startTime = Date.now();
      const pausePercent = ALERT_PROGRESS[step];
      const currentLineInPercent =
        progress < pausePercent ? pausePercent : 1 - pausePercent;
      const stepDuration = STEP_DURATION * currentLineInPercent;

      const timer = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const nextProgress = easeInOutQuad(
          Math.min(1, elapsedTime / stepDuration),
        );
        if (progress < pausePercent && nextProgress >= 1) {
          setProgress(nextProgress * currentLineInPercent);
          setAlertShown(true);
          setProgressPaused(true);
          clearInterval(timer);
        } else {
          const prefix = progress >= pausePercent ? pausePercent : 0;
          const nextValue = prefix + nextProgress * currentLineInPercent;
          if (nextValue >= 1) {
            if (step < ALERTS.length - 1) {
              setProgress(0);
              setStep(step + 1);
              clearInterval(timer);
            } else {
              setProgress(1);
              setTimeout(() => {
                dispatch(goToNextQuestion());
              }, 500);
              clearInterval(timer);
            }
          } else {
            setProgress(nextValue);
          }
        }
      }, 100);
      return () => {
        clearInterval(timer);
      };
    }
  }, [progressPaused, step]);

  const getProgressValue = (p: number) => {
    if (step < p) {
      return 0;
    } else if (step === p) {
      return progress;
    } else {
      return 1;
    }
  };

  const handleAnswer = () => {
    if (step < ALERTS.length) {
      setAlertShown(false);
      setTimeout(() => {
        setProgressPaused(false);
      }, 250);
    } else {
      setAlertShown(false);
    }
  };

  return (
    <div className={`${styles.container} maxWidth`}>
      <QuestionAlert
        visible={alertShown}
        question={ALERTS[step]}
        onAnswer={handleAnswer}
      />
      <h2 className={styles.title}>{t('PersonalizationLoaderTitle')}</h2>
      {isPersonalizationLoaderEstimateFlow() && (
        <h4 className={styles.subtitle}>
          {t('PersonalizationLoaderSubtitle')}
        </h4>
      )}
      <ProgressBar
        text="PersonalizationLoaderStep1"
        color="#F3BC5B"
        progress={getProgressValue(0)}
      />
      <ProgressBar
        text="PersonalizationLoaderStep2"
        color="#80CBC4"
        progress={getProgressValue(1)}
      />
      <ProgressBar
        text="PersonalizationLoaderStep3"
        color="#FF79AF"
        progress={getProgressValue(2)}
      />
      <ProgressBar
        text="PersonalizationLoaderStep4"
        color="#498CFF"
        progress={getProgressValue(3)}
      />
      <h2 className={styles.title}>{t('PersonalizationLoaderTitle2')}</h2>
      <ReviewCarousel />
    </div>
  );
};
