import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { onboardingReducer } from './onboarding/reducer';
import { productReducer } from './product/reducer';
import { userReducer } from './user/reducer';
import { RootState } from './types';
import { purchaseReducer } from './giftpurchase/reducer';
import { subscriptionCancelReducer } from './subscriptioncancel/reducer';

export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    user: userReducer,
    onboarding: onboardingReducer,
    subscriptionCancel: subscriptionCancelReducer,
    product: productReducer,
    purchase: purchaseReducer,
  });
