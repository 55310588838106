export const PersonalizationBgColorsMap: Record<string, string> = {
  sky_blue: '#7986CB',
  loona_lavender: '#7E57C2',
  venice_rose: '#F088AB',
  coral_red: '#EF5350',
  navy_blue: '#49599A',
  spring_green: '#64BB6A',
  tangerine: '#EC8847',
  sunflower_yellow: '#FFD863',
};
