export class TextOption {
  id: string;
  text: string;
  excluding: boolean;

  constructor(id: string, text: string, excluding = false) {
    this.id = id;
    this.text = text;
    this.excluding = excluding;
  }
}
