import React, { useRef } from 'react';
import { useAlertLogic } from './Alert.hook';
import './styles.scss';

type AlertProps = {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  visible: boolean;
  onSuccess: () => void;
};

const Alert = ({ children, className, visible, onSuccess }: AlertProps) => {
  const alert = useRef<HTMLDivElement>(null);
  const { close } = useAlertLogic({ alert, visible, onSuccess });

  return (
    <div
      className={visible ? 'alert-container' : 'alert-container hidden'}
      onClick={close}
    >
      <div ref={alert} className={`alert ${className}`}>
        {children}
      </div>
    </div>
  );
};

export default Alert;
