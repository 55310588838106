import React, { useRef } from 'react';
import Divider from 'components/divider/Divider';
import { Header } from 'subscription/components/header/Header';
import { Information } from 'components/information/Information';
import { ReasonToUseTemplate } from 'subscription/components/reasontemplate/ReasonToUseTemplate';
import { Reviews } from 'subscription/components/reviews/Reviews';
import { StickyHeader } from '../components/stickyheader/StickyHeader';
import { usePaymentLogic } from './Payment.hook';
import { Recurly } from 'subscription/recurly/Recurly';
import { Error } from 'subscription/components/error/Error';
import { PaymentFormTemplate } from 'subscription/components/paymentformtemplate/PaymentFormTemplate';
import { PaymentChecklist } from 'subscription/components/paymentchecklist/PaymentChecklist';
import { LoadingScreen } from 'components/loadingscreen/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/footer/Footer';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styles from './styles.module.scss';

export const Payment = () => {
  const startDate = Date.now();
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { error, product, currency, handleRetry, handleGetPlanClick } =
    usePaymentLogic(paymentFormRef);

  if (error) {
    return <Error onRetry={handleRetry} />;
  }

  if (!product) {
    return (
      <LoadingScreen
        isVisible={true}
        title={t('loadingScreenTitle')}
        description={
          <>
            {t('loadingScreenDescriptionLine1')}
            <br />
            {t('loadingScreenDescriptionLine2')}
          </>
        }
      />
    );
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ''}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <div className={styles.payment}>
        <StickyHeader
          product={product}
          startDate={startDate}
          trialLength={product.trialLength}
        />

        <div className={styles.paymentContainer}>
          <Header />

          <PaymentFormTemplate
            product={product}
            currencyCode={currency}
            handleGetPlanClick={handleGetPlanClick}
          />

          <ReasonToUseTemplate />

          <Divider isDividerWithMargin />

          <Reviews />

          <Divider />

          <div ref={paymentFormRef}>
            <Recurly product={product} />
            <PaymentChecklist product={product} currencyCode={currency} />
            <Divider />
          </div>

          <Information />

          <Footer className={styles.footer} />
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};
